import React, { useEffect, useContext, useRef } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useLocation, withRouter } from 'react-router';
import { Menu, CheckoutPublic, CheckoutKiosk, OrderConfirmation, OrderConfirmationKiosk, CartMobile } from 'pages';
import { GeneralContext } from 'contexts';
import { NavigationBar } from 'components/_shared/NavigationBar';
import { useCartSidebar, CartSidebar } from 'components/CartSidebar';
import { useScreenType } from 'utils/useScreenType';
import styled from 'styled-components';
import { socket } from '../../socket';
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import { Footer } from 'components/Footer';
import { useVisibilityChange } from 'use-visibility-change';
import { useWindowFocus } from 'utils/useWindowFocus';

export function PublicLayout(props) {
  const sidebarProps = useCartSidebar();
  const { handleOpenCartSidebar, isOpen } = sidebarProps;
  const screen = useScreenType();
  const location = useLocation();
  const { pathname } = location;
  const cookiesHandle = new Cookies();
  const cookieNameModifier = window.location.host.split('.').includes('dev') ? 'dev' : 'prod';
  const cookieName = `device-is-kiosk-${cookieNameModifier}`;
  const kioskCookie = cookiesHandle.get(cookieName) || null;
  const splashScreenImageRef = useRef(null);
  const [generalState, generalDispatch] = useContext(GeneralContext);
  const { storeOpen, windowFocusInCallback } = generalState;

  useVisibilityChange({ onShow: () => windowFocusInCallback() });
  useWindowFocus({ focusIn: () => windowFocusInCallback() });

  const vww = Math.round(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
  const isIPhoneSE = vww <= 375;

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: 'smooth' });
  }, [location]);

  const ExtraToastTyles = styled.div`
    ${(props) =>
      props?.screen == 'mobile' &&
      `
    .Toastify__toast{
      width: calc(100% - 30px);
      margin: 15px auto;
    }
    `}
  `;

  const CartSidebarWithRouter = withRouter(CartSidebar);
  const NavigationBarWithRouter = withRouter(NavigationBar);

  const history = useHistory();

  useEffect(() => {
    if (storeOpen == null) {
      socket.emit('store/are_we_open');
    }
  }, []);

  useEffect(() => {
    const handler = (resp) => generalDispatch({ type: 'RECEIVED_STORE_OPENSTATUS', newStoreOpen: resp.open });

    socket.on('store/store_open_status_updated', handler);
    return () => {
      socket.off('store/store_open_status_updated', handler);
    };
  }, []);

  let widthStyle = { marginLeft: 0, width: '100%' };
  if (screen != 'mobile' && screen != 'tablet' && isOpen) {
    widthStyle = { marginLeft: 0, width: 'calc(100% - 450px)' };
  }

  let welcomeMessageFontSize = '3.5rem';
  welcomeMessageFontSize = screen == 'mobile' ? '1.7rem' : welcomeMessageFontSize;
  welcomeMessageFontSize = isIPhoneSE ? '1.5rem' : welcomeMessageFontSize;

  let welcomeMessagePadding = '25px';
  welcomeMessagePadding = screen == 'mobile' ? '15px' : welcomeMessagePadding;
  welcomeMessagePadding = isIPhoneSE ? '9px' : welcomeMessagePadding;

  return (
    <>
      <NavigationBarWithRouter handleOpenCartSidebar={handleOpenCartSidebar} sidebarProps={sidebarProps} />
      <section
        className="section"
        style={{
          position: 'relative',
          padding: 0,
          margin: 0,
        }}
      >
        <div
          style={{
            padding: '20px',
            paddingTop: '40px',
            paddingBottom: '50px',
            minWidth: '100%',
            minHeight: '100vh',
            backgroundColor: '#ffffff',
            backgroundSize: '500px 500px',
            backgroundImage: 'url("/images/appBackgroundTexture2.jpg")',
          }}
        >
          {pathname.includes('/menu') && (
            <>
              <div
                style={{
                  margin: '-20px',
                  marginTop: '-40px',
                  marginBottom: 0,
                  position: 'relative',
                  background: '#333',
                }}
              >
                <img
                  src="/images/SplashScreen.jpg"
                  alt=" "
                  height={1400} //update me to proper dimensions if you change SplashScreen.jpg (height and width make it so that proper space is occupied while image is loading)
                  width={2100} //update me to proper dimensions if you change SplashScreen.jpg (height and width make it so that proper space is occupied while image is loading)
                  style={{
                    display: 'block',
                    maxHeight: '900px',
                    objectFit: 'cover',
                    ...widthStyle,
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.style.opacity = '0';
                  }}
                />
                <div
                  ref={splashScreenImageRef}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    color: 'white',
                    height: '100%',
                    ...widthStyle,
                  }}
                >
                  <div style={{ display: 'inline-block', height: '100%', verticalAlign: 'middle' }}></div>
                  <div
                    style={{
                      position: 'relative',
                      color: 'white',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      padding: welcomeMessagePadding,
                      fontSize: welcomeMessageFontSize,
                      fontWeight: isIPhoneSE ? '200' : 'bold',
                      textTransform: 'uppercase',
                      fontFamily: screen == 'mobile' ? 'Bebas Neue' : 'Bebas Neue',
                      lineHeight: 1,
                      maxWidth: '1000px',
                      textShadow: 'black 3px 3px 20px',
                    }}
                  >
                    Outpost eats creates flavorful scratch made asian inspired dishes served in the shadow of galbraith mountain
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="container is-fluid" style={{ ...widthStyle, padding: 0 }}>
            <ExtraToastTyles screen={screen}>
              <ToastContainer
                enableMultiContainer
                position="top-center"
                newestOnTop={true}
                closeOnClick={true}
                containerId="top-center"
              />
            </ExtraToastTyles>
            {screen != 'mobile' && screen != 'tablet' ? <CartSidebarWithRouter {...sidebarProps} /> : null}
            <Switch>
              <Route key="cart" path="/cart">
                <CartMobile />
              </Route>
              <Route key="menu" path="/menu">
                <Menu splashScreenImageRef={splashScreenImageRef} />
              </Route>
              <Route key="checkout" path="/checkout">
                {kioskCookie == 1 ? <CheckoutKiosk /> : <CheckoutPublic />}
              </Route>
              <Route key="order-confirmation" path="/order-confirmation">
                {kioskCookie == 1 ? <OrderConfirmationKiosk /> : <OrderConfirmation />}
              </Route>
              <Route key="rest" path="*">
                <Redirect to={{ pathname: '/menu', state: { ...history.location.state } }} />
              </Route>
            </Switch>
          </div>
        </div>
      </section>
      <Footer widthStyle={widthStyle} />
    </>
  );
}
