import React, { useEffect, useState, useContext, useRef } from 'react';
import { socket } from 'socket';
import { GeneralContext } from 'contexts';
import Cookies from 'universal-cookie';

function Footer({ widthStyle }) {
  const [generalState, generalDispatch] = useContext(GeneralContext);
  const cookiesHandle = new Cookies();
  const cookieNameModifier = window.location.host.split('.').includes('dev') ? 'dev' : 'prod';
  const cookieName = `device-is-kiosk-${cookieNameModifier}`;
  const kioskCookie = cookiesHandle.get(cookieName) || null;

  useEffect(() => {
    const handler = (resp) => generalDispatch({ type: 'RECEIVED_STORE_SETTINGS', newSettings: resp.settings });

    socket.on('store/store_settings_updated', handler);
    return () => {
      socket.off('store/store_settings_updated', handler);
    };
  }, []);

  return (
    <section
      className="section"
      style={{
        position: 'relative',
        padding: 0,
        margin: 0,
        minHeight: '140px',
        background: '#1c1912',
        color: 'white',
        zoom: kioskCookie == 1 ? '1.25' : '1',
      }}
    >
      <div className="container is-fluid" style={{ ...widthStyle, padding: '10px', height: '100%', display: 'flex' }}>
        <div className="columns" style={{ margin: '0 auto', width: '100%' }}>
          <div className="column" style={{ textAlign: 'center' }}>
            <div style={{ display: 'inline-block' }}>
              <a
                style={(kioskCookie == 1 && { pointerEvents: 'none', cursor: 'default' }) || {}}
                href="https://www.instagram.com/outpost_eats/"
                target="_blank"
              >
                <i className="fab fa-instagram" style={{ color: 'white', fontSize: '40px', marginRight: '10px' }}></i>
                <span
                  style={{
                    color: 'white',
                    fontSize: '1.3rem',
                    fontFamily: 'Bebas Neue',
                    fontWeight: 'bold',
                    verticalAlign: 'super',
                  }}
                >
                  @outpost_eats
                </span>
              </a>
            </div>
            <div
              style={{
                fontSize: '1.3rem',
                fontFamily: 'Bebas Neue',
                fontWeight: 'bold',
                lineHeight: '1.1em',
                marginTop: '5px',
                whiteSpace: 'pre',
              }}
            >
              <div>{generalState?.settings?.Settings_HoursText}</div>
              <div>info@outpost-eats.com</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export { Footer };
