import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { ShoppingCartContext } from 'contexts';
import { OrderTracker } from 'components/OrderTracker';
import { socket } from '../socket';
import { useWindowFocus } from 'utils/useWindowFocus';

export function OrderConfirmation() {
  const { state } = useLocation();
  const [order, setOrder] = useState(state?.order);

  const history = useHistory();
  const [cartState, cartDispatch] = useContext(ShoppingCartContext);
  /* prettier-ignore */
  function windowFocusInCallback(){
    const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream;
    if(isIOS){
      socket.emit('orders/query_order_detail', order); //this line is only here so that page stays accurate if you refresh it
    }
  }
  useWindowFocus({ focusIn: windowFocusInCallback });

  useEffect(async () => {
    if (order && cartState.itemsInCart?.length) {
      cartDispatch({ type: 'EMPTY_CART' });
    }
  }, []);

  const handleOrderDataResponse = (o) => {
    if (o.id == order.id) {
      setOrder(o);
    }
  };

  useEffect(() => {
    socket.on('order/order_data', handleOrderDataResponse);
    if (order) {
      socket.emit('orders/query_order_detail', order); //this line is only here so that page stays accurate if you refresh it
    }
    return () => {
      socket.off('order/order_data', handleOrderDataResponse);
    };
  }, []);

  if (!state) {
    history.replace('/');
    return null;
  }
  if (!order) {
    history.replace('/');
    return null;
  }

  return <OrderTracker order={order} />;
}
