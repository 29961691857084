import createContext from '../utils/createContext';

const initialState = {
  menu: null,
  options_updating_soldout: [],
  options_updating_active: [],
  //  items_updating_soldout: [],
  items_updating_active: [],
};

const reducer = (state, { type, menu, itemid, optionid }) => {
  let newArray = [];
  switch (type) {
    case 'SET_MENU':
      const sorted = { ...menu };
      sorted.items = sorted.items.sort((a, b) => (a.Item_SortIndex || 999) - (b.Item_SortIndex || 999));
      sorted.item_options = sorted.item_options.sort((a, b) => a.id - b.id);
      return { ...state, menu: sorted };
    case 'OPTIONS_UPDATING_SOLDOUT_ADD_ID':
      newArray = state.options_updating_soldout.slice();
      if (optionid) {
        newArray.push(optionid);
      }
      return { ...state, options_updating_soldout: newArray };
    case 'OPTIONS_UPDATING_SOLDOUT_REMOVE_ID':
      newArray = state.options_updating_soldout.slice().filter((id) => id != optionid);
      return { ...state, options_updating_soldout: newArray };

    case 'OPTIONS_UPDATING_ACTIVE_ADD_ID':
      newArray = state.options_updating_active.slice();
      if (optionid) {
        newArray.push(optionid);
      }
      return { ...state, options_updating_active: newArray };
    case 'OPTIONS_UPDATING_ACTIVE_REMOVE_ID':
      newArray = state.options_updating_active.slice().filter((id) => id != optionid);
      return { ...state, options_updating_active: newArray };

    // case 'ITEMS_UPDATING_SOLDOUT_ADD_ID':
    //   newArray = state.items_updating_soldout.slice();
    //   if (itemid) {
    //     newArray.push(itemid);
    //   }
    //   return { ...state, items_updating_soldout: newArray };
    // case 'ITEMS_UPDATING_SOLDOUT_REMOVE_ID':
    //   newArray = state.items_updating_soldout.slice().filter((id) => id != itemid);
    //   return { ...state, items_updating_soldout: newArray };

    case 'ITEMS_UPDATING_ACTIVE_ADD_ID':
      newArray = state.items_updating_active.slice();
      if (itemid) {
        newArray.push(itemid);
      }
      return { ...state, items_updating_active: newArray };
    case 'ITEMS_UPDATING_ACTIVE_REMOVE_ID':
      newArray = state.items_updating_active.slice().filter((id) => id != itemid);
      return { ...state, items_updating_active: newArray };

    default:
      return state;
  }
};

const [MenuContext, MenuProvider] = createContext({
  initialState,
  reducer,
});

export { MenuContext, MenuProvider };
