import http from '../utils/http';
import { toBase64 } from 'utils/general';

const getMenu = async () => {
  const resp = await http.get(`${process.env.REACT_APP_POS_SERVICE_URL}/administration/menu/get-menu`);
  return resp?.data;
};

const createItem = async ({ item }) => {
  delete item.file; //dont need this. we're using filesystem_path and image_src_base64 instead
  const resp = await http.post(`${process.env.REACT_APP_POS_SERVICE_URL}/administration/menu/create-item`, {
    item,
  });
  return resp;
};

const editItem = async ({ item }) => {
  delete item.file; //dont need this. we're using filesystem_path and image_src_base64 instead
  const resp = await http.post(`${process.env.REACT_APP_POS_SERVICE_URL}/administration/menu/edit-item`, {
    item,
  });
  return resp;
};

const deleteItem = async ({ item }) => {
  const resp = await http.post(`${process.env.REACT_APP_POS_SERVICE_URL}/administration/menu/delete-item`, {
    item,
  });
  return resp;
};
const deleteItemOption = async ({ itemOption }) => {
  const resp = await http.post(
    `${process.env.REACT_APP_POS_SERVICE_URL}/administration/menu/delete-item-option`,
    {
      itemOption,
    },
  );
  return resp;
};
export { getMenu, createItem, editItem, deleteItem, deleteItemOption };
