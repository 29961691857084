import React from 'react';
import { ORDER_STATUS } from 'utils/constants';
import styled from 'styled-components';
import { Level } from 'react-bulma-components';
import { useScreenType } from 'utils/useScreenType';
import { ArrowStepper } from 'components/_shared/ArrowStepper';
import { useHistory } from 'react-router';

const ThankYou = styled.div`
  ${(props) => {
    if (props.screen == 'mobile') return 'font-size: 30px;';
    else if (props.screen == 'tablet') return 'font-size: 40px;';
    else if (props.screen == 'desktop') return 'font-size: 55px;';
    else return 'font-size: 70px;';
  }}
  text-align: center;
  color: white;
  font-family: OPEats;
  text-transform: uppercase;
  margin-bottom: 12px;
`;
const Container = styled.div`
  color: white;
  font-weight: bold;
`;

function OrderTracker({ order, kioskVersion }) {
  const screen = useScreenType();
  const history = useHistory();
  const status = order.order_status;

  const date = new Date(order.inserted_at || Date.now());
  const total = Number(order.total).toFixed(2);
  const id = order.id;
  const idDisplay = id;
  const orderName = order?.Order_CustName;
  const tip = Number(order.tip_amount).toFixed(2);
  const items = order.items;
  const tax = Number(order.sales_tax).toFixed(2);
  const preTax = Number(order.pre_tax).toFixed(2);

  let textcolor = status == ORDER_STATUS.CANCELLED ? 'red' : 'blue';
  textcolor = status == ORDER_STATUS.DONE ? 'green' : textcolor;

  if (kioskVersion == 1) {
    return (
      <Container className="order-confirmation">
        <div className="columns is-centered" style={{ margin: 0 }}>
          <div
            className="column"
            style={{
              border: '1px solid #b5b5b5',
              color: 'white',
              background: '#1c1912',
              borderRadius: '5px',
              padding: '20px',
              maxWidth: '700px',
              margin: '0 auto',
            }}
          >
            <ThankYou screen={screen}>Thank You</ThankYou>
            <div style={{ fontFamily: 'Bebas Neue', lineHeight: 1, margin: '5px 0 0 0', fontSize: '19px' }}>
              We'll text you when your order is ready! <br />
              When it's ready, pick it up at the foodtruck outside and just let the chefs know that you're
              picking up for
              <span style={{ fontStyle: 'italic' }}> {orderName}</span>
              <br />
              <br />
            </div>
            <button
              style={{
                background: 'white',
                color: 'black',
                border: 'none',
                maxWidth: '200px',
                height: '50px',
                margin: '10px auto',
                fontWeight: 'bold',
              }}
              onClick={() => history.push('/menu', { scrollToMenu: true })}
            >
              Start New Order
            </button>
          </div>
        </div>
      </Container>
    );
  } else {
    return (
      <Container className="order-confirmation">
        <div className="columns is-centered" style={{ margin: 0 }}>
          <div
            className="column"
            style={{
              border: '1px solid #b5b5b5',
              color: 'white',
              background: '#1c1912',
              borderRadius: '5px',
              padding: '20px',
              maxWidth: '700px',
              margin: '0 auto',
            }}
          >
            <ThankYou screen={screen}>Thank you</ThankYou>
            <div style={{ fontFamily: 'Bebas Neue', lineHeight: 1, margin: '5px 0 0 0', fontSize: '19px' }}>
              We'll text you when your order is ready! <br />
              When it's ready, pick it up at the foodtruck outside and just let the chefs know that you're
              picking up for
              <span style={{ fontStyle: 'italic' }}> {orderName}</span>
              <br />
              <br />
            </div>
            <ArrowStepper screen={screen} order={order} />
            <br />
          </div>
        </div>
      </Container>
    );
  }
}

export { OrderTracker };
