import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useScreenType } from 'utils/useScreenType';

const Styles = styled.div`
  .navbar {
    padding: 20px;
    background: #fafafa;
    border-bottom: 1px solid #ddd;
    width: 100%;
    left: 0;
    right: 20px;
  }
  .navbar-item {
    margin-right: 10px;
    height: 70px;
  }
  a.navbar-item:hover {
    color: unset;
  }
  .navbar-item.active {
    background: #f2f2f2;
    border: 1px solid #999;
  }
  .navbar-item:not(.logo-item) {
    padding: 12px 20px;
  }
  .navbar-item.last {
    margin-right: unset;
  }
`;

export const NavigationBar = () => {
  const burgerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  const screen = useScreenType();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  function handleClickOutside(event) {
    const clickIsInDropdown = dropdownRef.current && dropdownRef.current.contains(event.target);
    const clickIsInBurger = burgerRef.current && burgerRef.current.contains(event.target);
    if (!clickIsInDropdown && !clickIsInBurger) {
      setIsMobileDropdownOpen(false);
    }
  }

  const MenuStyles =
    screen == 'tablet' || screen == 'mobile'
      ? styled.div`
          ${screen == 'tablet' ? 'max-width: 400px;' : null}
          width: 100%;
          position: fixed;
          padding: 0;
          top: 110px;
          right: 0;
          .button:hover {
            border: 1px solid #ddd;
            border-bottom: 0px;
          }
          .button.active {
            border-radius: 0;
            border: 1px solid #ddd;
            border-bottom: 0px;
          }
          .button {
            margin: 0 5px;
            border-radius: 0;
            border: 1px solid #ddd;
            border-bottom: 0px;
          }
          .button.last {
            margin-right: 5px;
            border-bottom: 1px solid #ddd;
          }
        `
      : styled.div``;

  return (
    <Styles>
      <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item logo-item" href="https://transitionbikes.com">
            <img style={{ maxHeight: '70px' }} src="/images/tr-logo.png" />
          </a>

          <a
            role="button"
            ref={burgerRef}
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={() => setIsMobileDropdownOpen(!isMobileDropdownOpen)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <MenuStyles
          className={`${isMobileDropdownOpen ? 'is-active' : ''} navbar-menu is-shadowless`}
          style={isMobileDropdownOpen ? { backgroundColor: 'transparent' } : null}
          ref={dropdownRef}
        >
          <div className="navbar-start"></div>
          <div className="navbar-end"></div>
        </MenuStyles>
      </nav>
    </Styles>
  );
};

export const NavigationBarShim = () => <div style={{ height: '100px' }} />;
