import Product from 'components/_shared/Product';
import React, { useContext, useState, useEffect } from 'react';
import { ShoppingCartContext } from 'contexts';
import { Modal, Section } from 'react-bulma-components';
import { getMenu } from 'api/menuAPI';
import { MenuContext, AuthContext, GeneralContext } from 'contexts';
import { socket } from '../socket';
import _ from 'lodash';
import styled from 'styled-components';
import { useScreenType } from 'utils/useScreenType';
import { SmallInlineSpinner } from 'components/_shared/PageLoadSpinner';
import { EstimatedWaitTime } from 'components/_shared/EstimatedWaitTime';
import { getItemPrice } from 'utils/general';
import { useLocation } from 'react-router';

export function Menu({ splashScreenImageRef }) {
  const { state } = useLocation();
  const [scrollToMenu, setScrollToMenu] = useState(state?.scrollToMenu);
  const [selectedItem, setSelectedItem] = useState(null);
  const screenType = useScreenType();
  const [cartState, cartDispatch] = useContext(ShoppingCartContext);
  const [itemNotes, setItemNotes] = useState(null);
  const [tempHighlight, setTempHighlight] = useState(0);
  const [isLoadingMenu, setIsLoadingMenu] = useState(true);
  const [menuState, menuDispatch] = useContext(MenuContext);
  const [authState, authDispatch] = useContext(AuthContext);
  const menu = menuState.menu;

  const [generalState, generalDispatch] = useContext(GeneralContext);
  const { storeOpen } = generalState;

  useEffect(() => {
    if (storeOpen == null) {
      socket.emit('store/are_we_open');
    }
  }, []);

  useEffect(() => {
    const handler = (resp) => generalDispatch({ type: 'RECEIVED_STORE_OPENSTATUS', newStoreOpen: resp.open });

    socket.on('store/store_open_status_updated', handler);
    return () => {
      socket.off('store/store_open_status_updated', handler);
    };
  }, []);

  useEffect(() => {
    if (scrollToMenu && splashScreenImageRef?.current) {
      setTimeout(() => {
        window.scrollTo({
          top: splashScreenImageRef.current.offsetHeight,
          left: 0,
          behavior: 'smooth',
        });
      }, 300);
    }
  }, [splashScreenImageRef, scrollToMenu]);

  const estWait = EstimatedWaitTime({});

  function handleItemOptionClick(io) {
    let newOptionsArray = selectedItem?.chosen_options?.slice() || [];
    if (io?.is_protein) {
      if (!newOptionsArray.includes(io)) {
        newOptionsArray = newOptionsArray?.filter((io) => !io.is_protein);
        newOptionsArray.push(io);
      }
    } else {
      if (newOptionsArray.includes(io)) {
        newOptionsArray = newOptionsArray.filter((nio) => !_.isEqual(nio, io));
      } else {
        newOptionsArray.push(io);
      }
    }
    newOptionsArray.sort((a, b) => a.id - b.id);
    setSelectedItem({ ...selectedItem, chosen_options: newOptionsArray });
  }

  function handleOnlyOptionClick(io) {
    const itemToAddToCart = { ...selectedItem, OrderItem_ChosenOptions: [io] };
    cartDispatch({ type: 'ADD_ITEM_TO_CART', item: itemToAddToCart });
    setSelectedItem(null);
  }

  function handleAddItemToCart() {
    const itemToAddToCart = { ...selectedItem, OrderItem_ChosenOptions: selectedItem.chosen_options || [] };
    cartDispatch({ type: 'ADD_ITEM_TO_CART', item: itemToAddToCart });
    setSelectedItem(null);
  }

  function handleMenuItemClick(i) {
    const newItemAvailableOptions = menu?.item_options
      ?.filter((io) => io.active)
      ?.filter((io) => !io.sold_out)
      ?.filter((io) => i?.available_options?.includes(io.id));

    if (!newItemAvailableOptions.length) {
      setTimeout(() => setSelectedItem(i), 80); //timeout allows browser to render the .panel-block:active styles better
    } else {
      const newItemDefaultOptions = menu?.item_options
        ?.filter((io) => io.active)
        ?.filter((io) => !io.sold_out)
        ?.filter((io) => i?.default_options?.includes(io.id));
      setTimeout(() => setSelectedItem({ ...i, chosen_options: newItemDefaultOptions?.slice() }), 80); //timeout allows browser to render the .panel-block:active styles better
    }
  }

  useEffect(() => {
    if (selectedItem?.id) {
      const updatedItem = { ...selectedItem, OrderItem_Notes: itemNotes };
      setSelectedItem(updatedItem);
    }
  }, [itemNotes]);

  useEffect(() => {
    if (!selectedItem) {
      setItemNotes(null);
    }
  }, [selectedItem]);

  useEffect(() => {
    const asyncContainerFunc = async function () {
      if (!menu) {
        const menuObj = await getMenu();
        menuDispatch({ type: 'SET_MENU', menu: menuObj });
        setIsLoadingMenu(false);
      } else {
        setIsLoadingMenu(false);
      }
    };
    asyncContainerFunc();
  }, []);

  const HelperButton = styled.button`
    ${(props) => (props?.screenType == 'mobile' ? 'font-size: 1.2rem;' : 'font-size: 1.5rem;')}
    line-height: 1;
    padding: 5px 10px;
    text-align: center;
    color: white;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    background: #a3712a;
    border-radius: 2px;
    font-family: Bebas Neue;
    width: auto;
    &:hover {
      background: #ba791d;
    }
  `;
  const MenuHeader = function () {
    return (
      <div className="menuHeader">
        <div
          style={{
            lineHeight: 1,
            padding: '20px 10px 0 10px',
            textAlign: 'center',
            fontSize: screenType == 'mobile' ? '2.2rem' : '3rem',
            fontFamily: 'Bebas Neue',
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
        >
          ONLINE ORDERING MENU
        </div>

        <HelperButton
          onClick={() => {
            setScrollToMenu(true);
            setTempHighlight(1);
            setTimeout(() => {
              setScrollToMenu(false);
              setTempHighlight(0);
            }, 3000);
          }}
          screenType={screenType}
        >
          CLICK ON THE ITEMS BELOW TO ORDER
        </HelperButton>
      </div>
    );
  };

  const MenuFooter = function () {
    return (
      <div
        className="menuFooter"
        style={{
          lineHeight: 1,
          padding: '30px 10px',
          textAlign: 'center',
          fontSize: '1.3rem',
          fontFamily: 'Bebas Neue',
          fontWeight: 'bold',
          marginBottom: '-22px',
        }}
      >
        <span>5090 Samish Way, Bellingham WA 98229</span>
        <br />
        <span>Thursday - Sunday | 11A - 6P</span>
        <br />
        <span>info@outpost-eats.com</span>
      </div>
    );
  };

  const WaitTimeStyles = styled.div`
    background: #eee;
    width: 100%;
    font-size: 1.2em;
    padding: 10px;
    color: #363636;
    font-weight: bold;
    border-bottom: 1px solid #666;
  `;

  const CheckBoxStyles = styled.div`
    input.checkbox {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      vertical-align: middle;
      margin-bottom: 6px;
    }
  `;
  const RadioButtonStyles = styled.div`
    input.radioBtn {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      vertical-align: middle;
      margin-bottom: 6px;
    }
  `;

  const MenuItemStyles = styled.div`
    ${(props) => props?.screenType != 'mobile' && 'padding: 40px; padding-top: 0;'}
    background-color: white;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: -50px;
    a.panel-block:active {
      filter: brightness(0.95);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
    }
    .panel-block.sold-out {
      //pointer-events: none;
      //opacity: 0.25;
    }
    .item-name-piece {
      display: inline-block;
    }
    .item-name-piece:not(:last-of-type) {
      margin-right: 0.5rem;
    }
    .panel-block {
      background: white;
      border-left: 0;
      border-right: 0;
    }
    .panel-block:not(:last-of-type) {
      //margin-bottom: 5px;
    }
    .temp-highlight:first-of-type {
      animation: backgroundTempColor 2.5s ease-in-out;
    }
    .panel-block:first-of-type {
      border-radius: 0;
    }
    .panel-block:last-of-type {
      border-radius: 0;
    }
    .panel-block:not(:first-of-type),
    .panel-tabs:not(:first-of-type) {
      border-top: 1px solid #ddd;
    }
    .outpost-font-header {
      font-family: OPEats;
      text-transform: uppercase;
      font-size: 12px;
    }
    img {
      cursor: pointer;
      vertical-align: middle;
    }
    figure {
      border: 2px solid black;
      align-self: start;
    }
    .helper {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    .level {
      cursor: pointer;
    }
    ${(props) =>
      props?.screenType == 'mobile' &&
      `
      border: 0;
      border-radius: 0;
      margin: 0;
      margin-bottom: -50px;
    `}
  `;

  const CloseButtonContainer = styled.div`
    position: sticky;
    top: 15px;
    left: 100%;
    height: 0;
    button.delete {
      padding: 25px;
      position: sticky;
      top: 15px;
      left: 100%;
    }
    button.delete:active {
      filter: brightness(0.8);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
    }
  `;

  if (isLoadingMenu) {
    return <SmallInlineSpinner width="100%" height="200px" />;
  }

  if (estWait == null) {
    return <SmallInlineSpinner width="100%" height="200px" />;
  }

  if (!menu) {
    return 'No menu defined.';
  }

  const selectedItemAvailableOptions = menu?.item_options
    ?.filter((io) => io.active)
    ?.filter((io) => selectedItem?.available_options?.includes(io.id))
    ?.filter((io) => !io.is_protein);

  const selectedItemAvailableProteins = menu?.item_options
    ?.filter((io) => io.active)
    ?.filter((io) => selectedItem?.available_options?.includes(io.id))
    ?.filter((io) => !!io.is_protein);

  selectedItemAvailableOptions?.sort((a, b) => (a.price < b.price ? -1 : 1));
  selectedItemAvailableProteins?.sort((a, b) => (a.price < b.price ? -1 : 1));

  const modalStylesMobile = {
    position: 'absolute',
    width: '90%',
    maxHeight: 'calc(90vh)',
    margin: 'auto',
    marginBottom: '1px',
    fontSize: '0.85rem',
  };
  const modalStyles = {
    maxWidth: '550px',
    maxHeight: 'calc(90vh)',
  };

  let modalInnerStyle =
    screenType == 'mobile' ? { padding: '10px', borderRadius: '0' } : { padding: '25px', borderRadius: '4px' };
  let modalStyle = screenType == 'mobile' ? { ...modalStylesMobile } : { ...modalStyles };

  function handleImageClick() {
    //just a hummy func for now
  }

  function MenuGroup({ group, firstGroupIndex, mgIndex }) {
    const groupItems = menu.items?.filter((i) => i.active)?.filter((i) => i.menu_grouping == group?.name && group != null);

    return (
      <div
        style={
          firstGroupIndex && screenType != 'mobile'
            ? {
                borderRadius: 0,
                border: 0,
              }
            : null
        }
      >
        {groupItems?.length ? (
          <div
            style={{
              fontFamily: 'Bebas Neue',
              fontWeight: 'bold',
              fontSize: '2rem',
              padding: screenType == 'mobile' ? '20px 0 0 10px' : '20px 0 0 20px',
              textAlign: 'left',
            }}
          >
            {group.name}
          </div>
        ) : null}
        {groupItems?.map((item, index) => {
          const VegetarianIcon = item.vegetarian ? (
            <span className="tag is-success" style={{ marginLeft: '3px' }}>
              V
            </span>
          ) : null;

          const VeganIcon = item.vegan ? (
            <span className="tag is-warning" style={{ marginLeft: '3px' }}>
              VE
            </span>
          ) : null;

          const GlutenFreeIcon = item.gluten_free ? (
            <span className="tag is-info" style={{ marginLeft: '3px' }}>
              GF
            </span>
          ) : null;

          const priceDisplay = item.price == null ? 'Price Varies' : `$${Number(item.price).toFixed(2)}`;
          const menuItemClass = item.Item_QtyAvailable <= 0 ? 'panel-block sold-out' : 'panel-block';
          const extraClass = tempHighlight == 1 && firstGroupIndex == mgIndex && index == 0 ? 'temp-highlight' : '';

          return (
            <div
              key={index}
              style={{
                background: 'white',
                borderTop: '2px solid #ddd',
                borderRadius: '0',
              }}
            >
              <a
                className={`${menuItemClass} ${extraClass}`}
                key={item.id}
                style={{
                  padding: screenType == 'mobile' ? '10px' : '20px',
                  alignItems: 'start',
                  position: 'relative',
                  overflowWrap: 'anywhere',
                }}
                onClick={() => handleMenuItemClick(item)}
              >
                {item.filesystem_path && (
                  <figure
                    className="image"
                    style={{
                      marginRight: screenType == 'mobile' ? '10px' : '20px',
                      overflow: 'hidden',
                      aspectRatio: screenType == 'mobile' ? '3 / 2' : '16 / 9',
                      width: screenType == 'mobile' ? '120px' : '200px',
                      minWidth: screenType == 'mobile' ? '120px' : '200px',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      loading="lazy"
                      src={`${process.env.REACT_APP_POS_SERVICE_URL}/public/images/menu/${item.filesystem_path}?thumbnail=1`}
                    />
                  </figure>
                )}
                <div style={{ textAlign: 'left', display: 'inline-block' }}>
                  <p className="has-text-weight-semibold" style={{ lineHeight: '1em' }}>
                    <span className="outpost-font-header">
                      {item.name?.split(' ')?.map((piece, index) => (
                        <span className="item-name-piece" key={index}>
                          {piece}
                        </span>
                      ))}
                    </span>
                  </p>
                  <p style={{ marginBottom: '4px' }}>
                    {priceDisplay}
                    {VegetarianIcon}
                    {VeganIcon}
                    {GlutenFreeIcon}
                  </p>
                  <p>{item.description}</p>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    );
  }

  const selectedItemBasePrice = selectedItem?.price;
  const extraImgStyles = screenType == 'mobile' ? { height: '240px' } : { height: '300px' };
  const containerStyles = { userSelect: 'none', MozUserSelect: '-moz-none', WebkitUserSelect: 'none' };
  if (screenType == 'mobile') {
    containerStyles.fontSize = '0.85rem';
  }

  const VegetarianIcon = selectedItem?.vegetarian ? (
    <span className="tag is-success" style={{ marginLeft: '3px' }} title="Vegetarian">
      V
    </span>
  ) : null;

  const VeganIcon = selectedItem?.vegan ? (
    <span className="tag is-warning" style={{ marginLeft: '3px' }} title="Vegan">
      VE
    </span>
  ) : null;

  const GlutenFreeIcon = selectedItem?.gluten_free ? (
    <span className="tag is-info" style={{ marginLeft: '3px' }} title="Gluten Free">
      GF
    </span>
  ) : null;

  let firstGroupIndex = null;
  return (
    <div style={containerStyles}>
      <MenuItemStyles screenType={screenType}>
        {authState?.isAuthenticated || !storeOpen ? null : <MenuHeader />}
        <div className="menuBody" style={{ paddingBottom: '20px' }}>
          {menu?.menu_groups?.map((group, index) => {
            const groupItems = menu.items
              ?.filter((i) => i.active)
              ?.filter((i) => i.menu_grouping == group?.name && group != null);
            if (firstGroupIndex == null && groupItems?.length) {
              firstGroupIndex = index;
            }
            return <MenuGroup group={group} key={group.name} firstGroupIndex={firstGroupIndex} mgIndex={index} />;
          })}
        </div>
        <div style={{ padding: '0 15px 20px' }}>
          ** Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of foodborne illness,
          especially if you have certain medical conditions
        </div>
        {/* {authState?.isAuthenticated ? null : <MenuFooter />} */}
      </MenuItemStyles>

      <Modal
        className="modal"
        show={!!selectedItem}
        closeOnEsc={true}
        closeOnBlur={true}
        showClose={false}
        onClose={() => setSelectedItem(null)}
      >
        <Modal.Content style={modalStyle}>
          <div style={{ backgroundColor: 'white', ...modalInnerStyle, border: '5px solid black' }}>
            <CloseButtonContainer>
              <button className="delete" onClick={() => setTimeout(setSelectedItem(null), 80)} />
            </CloseButtonContainer>
            <div className="level is-mobile" style={{ marginBottom: '5px' }}>
              <div className="level-left" style={{ display: 'block', maxWidth: '100%' }}>
                <div className="is-size-4" style={{ lineHeight: 1 }}>
                  {selectedItem?.name}
                </div>
                <div style={{ margin: 0, fontSize: '1.1em', fontWeight: '500' }}>${Number(selectedItemBasePrice).toFixed(2)}</div>
                {VegetarianIcon}
                {VeganIcon}
                {GlutenFreeIcon}
              </div>
              <div className="level-right" style={{ alignSelf: 'start' }}></div>
            </div>
            {selectedItem?.filesystem_path && (
              <img
                loading="lazy"
                src={
                  selectedItem && process.env.REACT_APP_POS_SERVICE_URL + '/public/images/menu/' + selectedItem?.filesystem_path
                }
                style={{
                  width: '100%',
                  display: 'block',
                  objectFit: 'cover',
                  margin: '0 auto',
                  border: '3px solid black',
                  ...extraImgStyles,
                }}
              />
            )}
            <Section
              style={{
                padding: selectedItem?.option_logic == 1 ? '0 50px' : '10px 0',
                backgroundColor: 'white',
                flexWrap: 'wrap',
                alignContent: 'space-around',
                justifyContent: 'center',
              }}
            >
              <div style={{ marginBottom: '8px' }}>{selectedItem?.description}</div>
              {!storeOpen ? (
                <>
                  <br />
                  <button
                    className="button is-dark disabled"
                    disabled
                    style={{ maxWidth: '260px', margin: 'auto', display: 'block' }}
                  >
                    Sorry, we're closed right now
                  </button>
                </>
              ) : null}
              {storeOpen && (
                <>
                  {selectedItemAvailableProteins?.length > 0 && (
                    <span style={{ fontWeight: 'bold', marginBottom: '3px', display: 'block' }}>
                      Select a Protein:
                      <br />
                    </span>
                  )}
                  <div style={{ marginBottom: '5px' }}>
                    {selectedItemAvailableProteins?.map((io) => {
                      const ioLabel = io?.price > 0 ? io.name + ' (+$' + Number(io.price).toFixed(2) + ')' : io.name;
                      const checked = !io.sold_out && selectedItem.chosen_options?.filter((co) => co.id == io.id)?.length;
                      {
                        return (
                          <div key={io.id}>
                            <RadioButtonStyles>
                              <label className="radioLabel">
                                <input
                                  name="proteinsRadio"
                                  className="radioBtn"
                                  type="radio"
                                  disabled={selectedItem?.Item_QtyAvailable <= 0 || io.sold_out}
                                  defaultChecked={checked}
                                  onClick={() => handleItemOptionClick(io)}
                                />
                                {ioLabel}
                              </label>
                              <span
                                style={{
                                  display: 'block',
                                  float: 'right',
                                  fontSize: '0.8em',
                                  fontStyle: 'italic',
                                  color: '#777',
                                }}
                              >
                                {io.sold_out ? '**sold out' : null}
                              </span>
                            </RadioButtonStyles>
                          </div>
                        );
                      }
                    })}
                  </div>
                </>
              )}
              {storeOpen && (
                <>
                  {selectedItemAvailableProteins?.length > 0 && (
                    <span style={{ fontWeight: 'bold', marginBottom: '3px', display: 'block' }}>
                      Other options:
                      <br />
                    </span>
                  )}
                  <div>
                    {selectedItemAvailableOptions?.map((io) => {
                      const ioLabel = io?.price > 0 ? io.name + ' (+$' + Number(io.price).toFixed(2) + ')' : io.name;
                      const checked = !io.sold_out && selectedItem.chosen_options?.filter((co) => co.id == io.id)?.length;
                      {
                        return selectedItem?.option_logic == 1 ? (
                          <Product key={io.id} option={io} parentItem={selectedItem} onClick={() => handleOnlyOptionClick(io)} />
                        ) : (
                          <div key={io.id}>
                            <CheckBoxStyles>
                              <label className="checkbox">
                                <input
                                  className="checkbox"
                                  type="checkbox"
                                  disabled={selectedItem?.Item_QtyAvailable <= 0 || io.sold_out}
                                  defaultChecked={checked}
                                  onClick={() => handleItemOptionClick(io)}
                                />
                                {ioLabel}
                              </label>
                              <span
                                style={{
                                  display: 'block',
                                  float: 'right',
                                  fontSize: '0.8em',
                                  fontStyle: 'italic',
                                  color: '#777',
                                }}
                              >
                                {io.sold_out ? '**sold out' : null}
                              </span>
                            </CheckBoxStyles>
                          </div>
                        );
                      }
                    })}
                  </div>
                </>
              )}
              {storeOpen && (
                <textarea
                  onBlur={(e) => setItemNotes(e.target.value || null)}
                  className="textarea"
                  placeholder="Notes for the kitchen?"
                  disabled={selectedItem?.Item_QtyAvailable <= 0}
                  style={{
                    minHeight: '60px',
                    borderColor: '#ccc',
                    marginTop: '4px',
                    padding: '2px 8px',
                  }}
                  defaultValue={itemNotes || null}
                ></textarea>
              )}
            </Section>
            {storeOpen && (
              <>
                <div style={{ padding: '0 5px 15px', color: 'darkred', fontSize: '12px' }}>
                  ** If you have a serious food allergy, please speak to someone in the food truck before or after ordering
                </div>
                <div style={{ padding: '0 5px 15px', color: 'darkred', fontSize: '12px' }}>
                  ** Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of foodborne
                  illness, especially if you have certain medical conditions
                </div>
                {selectedItem?.Item_QtyAvailable > 0 && selectedItem?.option_logic == 'N' ? (
                  <button className="button is-dark" onClick={() => handleAddItemToCart()}>
                    Add to Cart
                  </button>
                ) : null}
                {selectedItem?.Item_QtyAvailable <= 0 && selectedItem?.option_logic == 'N' ? (
                  <button className="button is-dark disabled" disabled>
                    Sold Out
                  </button>
                ) : null}
              </>
            )}
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}
