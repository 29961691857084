import React, { useContext } from 'react';
import { ShoppingCartContext } from 'contexts';
import CartProduct from 'components/CartProduct';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { getCartPrice } from 'utils/general';
import Cookies from 'universal-cookie';

const CartMobile = () => {
  const [{ itemsInCart }] = useContext(ShoppingCartContext);
  const history = useHistory();

  const cookiesHandle = new Cookies();
  const cookieNameModifier = window.location.host.split('.').includes('dev') ? 'dev' : 'prod';
  const cookieName = `device-is-kiosk-${cookieNameModifier}`;
  const kioskCookie = cookiesHandle.get(cookieName) || null;

  const goToCheckout = () => {
    history.push('/checkout');
  };

  const totalPrice = getCartPrice(itemsInCart);

  return (
    <div
      style={{
        background: 'white',
        border: '2px solid black',
        borderRadius: '5px',
        padding: '8px',
        maxWidth: '500px',
        margin: '0 auto',
        marginBottom: '20px',
      }}
    >
      {itemsInCart.length ? (
        <>
          <div className="level is-mobile">
            <div className="level-left"></div>
            <div className="level-right">
              <CartProduct.Headers />
            </div>
          </div>
          <div>
            {itemsInCart?.map((item) => {
              return <CartProduct key={item.uniqueIdent} item={item} />;
            })}
          </div>
          <div className="level is-mobile">
            <div className="level-left"></div>
            <div className="level-right">
              <CartProduct.TotalsLine qty={itemsInCart?.length} price={totalPrice} />
            </div>
          </div>
          <button className="button is-dark" onClick={goToCheckout}>
            Check Out
          </button>
        </>
      ) : (
        <>
          <p style={{ marginTop: '20px' }}>Cart is Empty</p>
        </>
      )}
    </div>
  );
};

export { CartMobile };
