import React from 'react';

export const CurrencyInput = ({ className, placeholder, value, setValueCallback, ...rest }) => (
  <input
    className={className}
    placeholder={placeholder}
    value={value}
    onKeyDown={(e) => {
      const { key, keyCode } = e;
      var regex1 = new RegExp(/^[0-9]{1}$/);
      var regex2 = new RegExp(/^[\.]$/);
      var allowedKeyCodes = [8, 37, 38, 39, 40];
      if (!allowedKeyCodes.includes(keyCode) && !regex1.test(key) && !regex2.test(key)) {
        e.preventDefault();
        return;
      }
    }}
    onBlur={(e) => {
      let val = e.target.value;
      if (!val.slice(-3).startsWith('.')) {
        setValueCallback(Number(val).toFixed(2));
      }
    }}
    onChange={(e) => {
      let newVal = e.target.value;
      if (newVal.includes('.') && !newVal.slice(-2).includes('.')) {
        newVal = Number(newVal).toFixed(2);
      }
      setValueCallback(newVal);
    }}
    {...rest}
  />
);
