import React, { useContext, useEffect, useState } from 'react';
import { ShoppingCartContext, GeneralContext } from 'contexts';
import CartProduct from 'components/CartProduct';
import { CloseCartIcon } from './CartIconWithBadge';
import styles from './CartSidebar.scss';
import { Button } from 'react-bulma-components';
import { useHistory } from 'react-router';
import { AuthContext } from 'contexts';
import styled from 'styled-components';
import _ from 'lodash';
import { getCartPrice } from 'utils/general';

const useCartSidebar = () => {
  const [authState] = useContext(AuthContext);
  const [generalState, generalDispatch] = useContext(GeneralContext);
  const { storeOpen } = generalState;
  const startValue = !authState?.isAuthenticated;
  const [isOpen, setIsOpen] = useState(startValue && !!storeOpen);

  useEffect(() => {
    if (!storeOpen) {
      setIsOpen(false);
    }
  }, [storeOpen]);

  const handleOpenCartSidebar = () => setIsOpen(true);
  const handleCloseCartSidebar = () => setIsOpen(false);
  return {
    isOpen,
    setIsOpen,
    handleOpenCartSidebar,
    handleCloseCartSidebar,
  };
};

const CartSidebar = (props) => {
  const [{ itemsInCart }, cartDispatch] = useContext(ShoppingCartContext);
  const history = useHistory();

  const { isOpen, handleOpenCartSidebar, handleCloseCartSidebar, location } = {
    ...useCartSidebar(),
    ...props,
  };

  const isForCheckout = location.pathname.includes('/checkout');

  if (!isOpen) {
    return null;
  }

  const goToCheckout = () => {
    handleCloseCartSidebar();
    history.push('/checkout');
  };

  const classes = `sidebar-cart ${isOpen ? ' sidebar-cart--open' : null}`;
  const totalPrice = getCartPrice(itemsInCart);

  return (
    <div className={`${classes}`}>
      <CloseCartIcon handleClickCallback={() => handleCloseCartSidebar()} />
      {itemsInCart.length ? (
        <>
          <div className="level is-mobile">
            <div className="level-left"></div>
            <div className="level-right">
              <CartProduct.Headers />
            </div>
          </div>
          <div>
            {itemsInCart?.map((item) => {
              return <CartProduct key={item.uniqueIdent} item={item} />;
            })}
          </div>
          <div className="level is-mobile">
            <div className="level-left"></div>
            <div className="level-right">
              <CartProduct.TotalsLine qty={itemsInCart?.length} price={totalPrice} />
            </div>
          </div>
          {!isForCheckout ? (
            <Button
              onClick={goToCheckout}
              style={{
                background: 'black',
                //color: '#b66d0c',
                color: 'white',
                border: 'none',
              }}
            >
              Check Out
            </Button>
          ) : null}
        </>
      ) : (
        <>
          <p style={{ marginTop: '20px' }}>Cart is Empty</p>
        </>
      )}
    </div>
  );
};

const TopSpacer = styled.div`
  height: 41px;
`;

export { CartSidebar, useCartSidebar };
