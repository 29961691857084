function getMappedItemsForDisplay(items) {
  console.log('aaa');
  const ids = [
    ...new Set(items?.map((i) => i.OrderItem_ItemID + '-' + JSON.stringify(i.OrderItem_ChosenOptions) + '-' + i.OrderItem_Notes)),
  ];
  const itemsWithQty = [];
  console.log(ids);
  console.log('bbb');

  for (var id of ids) {
    const qty = items.filter(
      (i) => i.OrderItem_ItemID + '-' + JSON.stringify(i.OrderItem_ChosenOptions) + '-' + i.OrderItem_Notes == id,
    ).length;
    const item = items.find(
      (i) => i.OrderItem_ItemID + '-' + JSON.stringify(i.OrderItem_ChosenOptions) + '-' + i.OrderItem_Notes == id,
    );

    const price = item.OrderItem_Price;
    itemsWithQty.push({ ...item, price, qty });
  }

  return itemsWithQty;
}

function getCartPrice(items) {
  let dollarSum = 0;
  items.forEach((item) => {
    const itemPrice = Number(
      Number(item.promo_price != null ? item.promo_price || 0 : item.price || 0) +
        Number(item.OrderItem_Price || 0) +
        Number(
          item.OrderItem_ChosenOptions?.reduce(
            (a, b) => a + Number(b.promo_price != null ? b.promo_price || 0 : b.price || 0),
            0,
          ) || 0,
        ),
    );
    dollarSum += item.OrderItem_NoCharge ? 0 : itemPrice;
  });
  return dollarSum;
}

function getCartPricePreDiscount(items) {
  let dollarSum = 0;
  items.forEach((item) => {
    const itemPrice = Number(
      Number(item.price || 0) +
        Number(item.OrderItem_Price || 0) +
        Number(item.OrderItem_ChosenOptions?.reduce((a, b) => a + Number(b.price || 0), 0) || 0),
    );
    dollarSum += itemPrice;
  });
  return dollarSum;
}

function getItemPrice(item) {
  let dollarSum =
    Number(item.promo_price != null ? item.promo_price || 0 : item.price || 0) +
    Number(item.OrderItem_Price || 0) +
    Number(
      item.OrderItem_ChosenOptions?.reduce((a, b) => a + Number(b.promo_price != null ? b.promo_price || 0 : b.price || 0), 0) ||
        0,
    );
  return item.OrderItem_NoCharge ? 0 : dollarSum;
}

function getItemPricePreDiscount(item) {
  let dollarSum =
    Number(item.price || 0) +
    Number(item.OrderItem_Price || 0) +
    Number(item.OrderItem_ChosenOptions?.reduce((a, b) => a + Number(b.price || 0), 0) || 0);
  return dollarSum;
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const arrayGroupBy = function (arr, key) {
  return arr.reduce(function (acc, i) {
    (acc[i[key]] = acc[i[key]] || []).push(i);
    return acc;
  }, {});
};

export {
  getMappedItemsForDisplay,
  toBase64,
  getCartPrice,
  getCartPricePreDiscount,
  getItemPrice,
  getItemPricePreDiscount,
  arrayGroupBy,
};
