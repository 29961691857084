import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { getMenu } from 'api/menuAPI';
import { MenuContext } from 'contexts';
import { EstimatedWaitTime } from 'components/_shared/EstimatedWaitTime';
import styled from 'styled-components';
import { socket } from '../../socket';

const StepperStyles = styled.div`
  .stepper {
    ${({ screen }) => {
      if (screen == 'mobile' || screen == 'tablet') {
        return 'display: block; max-width: 400px;';
      } else {
        return 'display: flex;';
      }
    }}
    margin: auto;
    padding: 0;
    width: 80%;
    list-style: none;
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      content: '';
      width: calc(80% - 20px);
    }
  }

  @-webkit-keyframes slideRight {
    0% {
      left: calc(100% - 35px);
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      left: calc(100% - 20px);
    }
  }
  @keyframes slideRight {
    0% {
      left: calc(100% - 35px);
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      left: calc(100% - 20px);
    }
  }
  .stepper__item,
  stepper__item * {
    user-select: none;
  }

  .stepper__item {
    color: white;
    position: relative;
    overflow: hidden;
    opacity: 0.3;
    flex: 100%;
    padding: 20px;
    background: white;
    ${({ screen }) => {
      if (screen == 'mobile' || screen == 'tablet') {
        return 'margin: 0 0 8px 0;';
      } else {
        return 'margin: 0 0 0 -10px;';
      }
    }}
    -webkit-clip-path: polygon(
      22px 50%,
      0% 0%,
      calc(100% - 22px) 0%,
      100% 50%,
      calc(100% - 22px) 100%,
      0% 100%
    );

    &.marked {
      background: white;
      opacity: 1;
    }

    &:first-child {
      padding: 20px;
      margin-left: 0;
    }

    &.marked.active {
      color: white;
      opacity: 1;
      overflow: initial;
      .arrow-label {
        white-space: normal;
      }
    }
    &.marked.done {
      color: white;
      opacity: 1;
      overflow: initial;
      .arrow-label {
        white-space: normal;
      }
    }
    .sliding-arrow {
      display: none;
    }

    .arrow-label {
      white-space: nowrap;
      padding: 0 5px;
    }

    .inner-background {
      display: block;
      position: absolute;
      z-index: -1;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background: #b66d0c;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      -webkit-clip-path: polygon(
        24px 50%,
        4px 1px,
        calc(100% - 22px) 1px,
        calc(100% - 2px) 50%,
        calc(100% - 22px) calc(100% - 1px),
        4px calc(100% - 1px)
      );
    }

    &.marked.active:not(.done) > .sliding-arrow {
      display: block;
      position: absolute;
      z-index: 0;
      top: 3px;
      height: calc(100% - 6px);
      width: 32px;
      background: #000000;
      -webkit-animation: slideRight 1.5s ease-in infinite;
      animation: slideRight 1.5s ease-in infinite;
      -webkit-clip-path: polygon(
        20px 50%,
        0% 0%,
        calc(100% - 20px) 0%,
        100% 50%,
        calc(100% - 20px) 100%,
        0% 100%
      );
    }

    .extra-border-right {
      display: block;
      position: absolute;
      z-index: 5;
      left: calc(100% - 24px);
      top: 3px;
      height: calc(100% - 6px);
      width: 24px;
      background: white;
      /* animation: 1.5s ease-in 0s infinite normal none running slideRight; */
      clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    }
  }
`;

const ArrowStepper = ({ screen, order }) => {
  const estWait = EstimatedWaitTime({ orderId: order.id });

  const isStatus1 = order?.order_status == 'NEW';
  const isStatus2 = order?.order_status == 'IN PROGRESS';
  const isStatus3 = order?.order_status == 'DONE';
  const isStatus4 = order?.order_status == 'CANCELLED';
  let ix = 1;
  if (isStatus2) ix = 2;
  if (isStatus3 || isStatus4) ix = 3;

  const mobileMaxWidthStyle = screen == 'mobile' || screen == 'tablet' ? { maxWidth: '300px' } : {};
  const stepperContent = (
    <ul className="stepper" style={{ maxWidth: '900px', ...mobileMaxWidthStyle }}>
      <li className={`stepper__item new ${isStatus1 && 'active'} ${ix >= 1 && 'marked'}`}>
        <span className="arrow-label">Order received</span>
        <span className="inner-background"></span>
        <span className="sliding-arrow"></span>
        <span className="extra-border-right"></span>
      </li>
      <li className={`stepper__item in-progress ${isStatus2 && 'active'}  ${ix >= 2 && 'marked'}`}>
        <span className="arrow-label">In Progress</span>
        <span className="inner-background"></span>
        <span className="sliding-arrow"></span>
        <span className="extra-border-right"></span>
      </li>
      <li className={`stepper__item done ${(isStatus3 || isStatus4) && 'active'}  ${ix >= 3 && 'marked'}`}>
        <span className="arrow-label">{isStatus4 ? 'Cancelled :(' : 'Done!'}</span>
        <span className="inner-background"></span>
        <span className="sliding-arrow"></span>
        <span className="extra-border-right"></span>
      </li>
    </ul>
  );

  if (estWait == null) {
    return null;
  }

  return <StepperStyles screen={screen}>{stepperContent}</StepperStyles>;
};

export { ArrowStepper };
