import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';
import { MasterProvider } from 'contexts';
import http from './utils/http';
import './index.scss';
import 'bulma/css/bulma.min.css';

http.init();
const history = createBrowserHistory();

//a test comment

ReactDOM.render(
  <MasterProvider>
    {/* <IntlProvider locale={language} messages={messagesForLanguage}> */}
    <Router history={history}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Router>
    {/* </IntlProvider> */}
  </MasterProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
