import React, { useEffect, useContext } from 'react';
import { deleteItemOption, getMenu } from 'api/menuAPI';
import { MenuContext } from 'contexts';
import styled from 'styled-components';
import { socket } from '../socket';

function MenuItemOptionManager() {
  const [menuState, menuDispatch] = useContext(MenuContext);
  const { menu, options_updating_soldout, options_updating_active } = menuState;

  useEffect(() => {
    const asyncContainerFunc = async function () {
      if (!menu) {
        const menuObj = await getMenu();
        menuDispatch({ type: 'SET_MENU', menu: menuObj });
      }
    };
    asyncContainerFunc();
  }, []);

  const TableStyles = styled.div`
    tr.inactive td {
      background-color: hsl(348, 10%, 94%);
    }
    tr.soldout td {
      background-color: hsl(348, 100%, 94%);
    }
    table {
      margin-left: 30px;
    }
    .button.is-outlined {
      background-color: #fff;
    }
  `;

  if (!menu) {
    return null;
  }

  function markOptionInactive(option) {
    menuDispatch({ type: 'OPTIONS_UPDATING_ACTIVE_ADD_ID', optionid: option.id });
    const updated = { ...option, active: false };
    socket.emit('items/update_option', updated);
  }

  function markOptionActive(option) {
    menuDispatch({ type: 'OPTIONS_UPDATING_ACTIVE_ADD_ID', optionid: option.id });
    const updated = { ...option, active: true };
    socket.emit('items/update_option', updated);
  }

  function markOptionSoldOut(option) {
    menuDispatch({ type: 'OPTIONS_UPDATING_SOLDOUT_ADD_ID', optionid: option.id });
    const updated = { ...option, sold_out: true };
    socket.emit('items/update_option', updated);
  }
  function markOptionAvailable(option) {
    menuDispatch({ type: 'OPTIONS_UPDATING_SOLDOUT_ADD_ID', optionid: option.id });
    const updated = { ...option, sold_out: false };
    socket.emit('items/update_option', updated);
  }

  function ToggleActiveButton({ option }) {
    const text = option.active ? 'Inactivate' : 'Activate';
    const handler = option.active ? markOptionInactive : markOptionActive;
    let className = options_updating_active.includes(option.id) ? 'button is-loading' : 'button';
    return (
      <button className={className} onClick={() => handler(option)} style={{ marginTop: 0 }}>
        {text}
      </button>
    );
  }

  async function handleClickDeleteButton(itemOption) {
    const result = await deleteItemOption({ itemOption });
  }

  function ToggleSoldoutButton({ option }) {
    const text = option.sold_out ? 'Mark Available' : 'Mark Sold Out';
    const handler = option.sold_out ? markOptionAvailable : markOptionSoldOut;
    let className = !option.sold_out ? 'button is-outlined is-danger' : 'button is-outlined is-primary';
    className = options_updating_soldout.includes(option.id) ? className + ' is-loading' : className;
    return (
      <button className={className} onClick={() => handler(option)} style={{ marginTop: 0 }}>
        {text}
      </button>
    );
  }

  function DeleteButton({ itemOption }) {
    return (
      <button className="button" onClick={() => handleClickDeleteButton(itemOption)} style={{ marginTop: 0 }}>
        <i className="fas fa-trash"></i>
      </button>
    );
  }

  return (
    <div style={{ padding: '0 40px' }}>
      <TableStyles>
        <table className="table is-hoverable">
          <thead>
            <tr>
              <th colSpan="100%">Options</th>
            </tr>
          </thead>
          <tbody>
            {menu?.item_options.map((io) => {
              let rowClassName = '';
              rowClassName = io.sold_out ? 'soldout' : rowClassName;
              rowClassName = !io.active ? 'inactive' : rowClassName;
              return (
                <tr key={io.id} className={rowClassName}>
                  <td style={{ cursor: 'pointer', minWidth: '200px' }}>{io.name}</td>
                  <td style={{ cursor: 'pointer' }}>{io.active && <ToggleSoldoutButton option={io} />}</td>
                  <td style={{ cursor: 'pointer' }}>
                    <ToggleActiveButton option={io} />
                  </td>
                  <td>
                    <DeleteButton itemOption={io} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableStyles>
    </div>
  );
}

export { MenuItemOptionManager };
