import './App.css';
import React from 'react';
import { Layout } from 'layout/Layout';
import { SocketSetup } from './socket.js';

function App() {
  return (
    <div className="App">
      <SocketSetup />
      <Layout />
    </div>
  );
}

export default App;
