import React, { useEffect, useContext } from 'react';
import { GeneralContext } from 'contexts';
import { useScreenType } from 'utils/useScreenType';
import { socket } from '../socket';
import { useLocation } from 'react-router';

function PublicStatusBanner({ sidebarProps }) {
  const location = useLocation();
  const { pathname } = location;
  const screenType = useScreenType();
  const [generalState, generalDispatch] = useContext(GeneralContext);
  const { storeOpen } = generalState;

  useEffect(() => {
    if (storeOpen == null) {
      socket.emit('store/are_we_open');
    }
  }, []);

  useEffect(() => {
    const handler = (resp) => {
      console.log('hmmmm');
      console.log(resp);
      generalDispatch({ type: 'RECEIVED_STORE_OPENSTATUS', newStoreOpen: resp.open });
    };

    socket.on('store/store_open_status_updated', handler);
    return () => {
      socket.off('store/store_open_status_updated', handler);
    };
  }, []);

  return !storeOpen
    ? pathname.includes('/menu') && (
        <div
          className="hero is-danger"
          style={{
            width: sidebarProps.isOpen && !['mobile', 'tablet'].includes(screenType) ? 'calc(100% - 450px + 40px)' : '100vw',
            height: '30px',
            position: 'relative',
            verticalAlign: 'center',
            backgroundColor: '#A3712A',
            lineHeight: '30px',
            zIndex: 0,
            minHeight: 'unset',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          We're closed right now, sorry!
        </div>
      )
    : pathname.includes('/menu') && (
        <div
          className="hero is-success"
          style={{
            width: sidebarProps.isOpen && !['mobile', 'tablet'].includes(screenType) ? 'calc(100% - 450px + 40px)' : '100vw',
            height: '30px',
            position: 'relative',
            verticalAlign: 'center',
            backgroundColor: '#48C78E',
            lineHeight: '30px',
            zIndex: 0,
            minHeight: 'unset',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          ↓↓ We're open, order below ↓↓
        </div>
      );
}

function PublicStatusBannerShim() {
  return <div style={{ height: '40px' }} />;
}

export { PublicStatusBanner, PublicStatusBannerShim };
