import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { socket } from 'socket';

const CheckBoxStyles = styled.div`
  input.checkbox {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .label {
    display: inline-block;
  }
`;

const ErrorStyles = styled.div`
  .has-text-danger {
    white-space: nowrap;
    display: block;
  }
`;

const initialOption = {
  name: null,
  //description: null,
  price: null,
  active: true,
  sold_out: false,
  key: null,
};

function AddNewItemOptions() {
  const [errors, setErrors] = useState({});
  const [rowDataArray, setRowDataArray] = useState([{ ...initialOption, key: `${Date.now()}--0` }]);

  function handleFieldChange(fieldname, value, rData, index) {
    if (fieldname == 'price' && value.startsWith('$')) {
      value = value.slice(1);
    }
    if (fieldname == 'price') {
      value = Number(value).toString();
    }
    const newArray = [...rowDataArray];
    const updatedArrayObject = { ...rData, [fieldname]: value };
    newArray.splice(index, 1, updatedArrayObject);
    setRowDataArray(newArray);
  }

  function handleNewRow() {
    setRowDataArray([...rowDataArray, { ...initialOption, key: `${Date.now()}--${rowDataArray.length}` }]);
  }

  function handleDeleteRow(index) {
    const newArray = [...rowDataArray];
    newArray.splice(index, 1);
    setRowDataArray(newArray);
  }

  //if there are errors on the screen and our data array changes, recalculate errors
  useEffect(() => {
    if (Object.keys(errors).length) {
      testForErrors();
    }
  }, [rowDataArray]);

  function testForErrors() {
    const newErrorsObj = {};
    if (rowDataArray.filter((d) => !d.name)?.length) {
      newErrorsObj.name = 'Name is required';
    }
    if (rowDataArray.filter((d) => d.price && isNaN(d.price?.trim()))?.length) {
      newErrorsObj.price = 'Price must be numeric';
    }
    setErrors(newErrorsObj);
    if (Object.keys(newErrorsObj).length) {
      return true;
    }
    return false;
  }

  function submitForm() {
    const hasErrors = testForErrors();
    if (hasErrors) {
      return;
    } else {
      const mappedArrayForSubmit = rowDataArray.map((d) => {
        const obj = {
          ...d,
          price: d.price > 0 ? Number(d.price).toFixed(2) : null,
        };
        delete obj.key;
        return obj;
      });

      socket.emit('items/create_item_options', mappedArrayForSubmit);
    }
  }

  return (
    <ErrorStyles>
      <CheckBoxStyles>
        <div style={{ paddingLeft: '80px', minWidth: '900px' }}>
          <table className="table">
            <thead>
              <tr>
                <th>
                  <label className="label">Name</label>
                  {errors.name && <span className="has-text-danger is-size-7">{errors.name}</span>}
                </th>
                {/* <th>
                  <label className="label">Description</label>
                  {errors.description && (
                    <span className="has-text-danger is-size-7">{errors.description}</span>
                  )}
                </th> */}
                <th>
                  <label className="label">Price</label>
                  {errors.price && <span className="has-text-danger is-size-7">{errors.price}</span>}
                </th>
                <th>
                  <label className="label">Is Active?</label>
                  {errors.active && <span className="has-text-danger is-size-7">{errors.active}</span>}
                </th>
                <th>
                  <label className="label">Is Sold Out?</label>
                  {errors.sold_out && <span className="has-text-danger is-size-7">{errors.sold_out}</span>}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {rowDataArray.map((rData, index) => {
                return (
                  <tr key={rData.key}>
                    <td>
                      <input
                        placeholder={`New option ${index + 1}`}
                        className="input"
                        onChange={(e) => handleFieldChange('name', e.target.value, rData, index)}
                      />
                    </td>
                    {/* <td>
                      <textarea
                        style={{ verticalAlign: 'top', width: '300px', height: '80px', minHeight: '80px' }}
                        placeholder="A sweet and savory koren beef dish"
                        className="textarea"
                        onChange={(e) => handleFieldChange('description', e.target.value, rData, index)}
                      />
                    </td> */}
                    <td>
                      <input
                        style={{ width: '100px' }}
                        className="input"
                        onChange={(e) => handleFieldChange('price', e.target.value, rData, index)}
                      />
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={rData.active}
                        onChange={() => handleFieldChange('active', !rData.active, rData, index)}
                      />
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={rData.sold_out}
                        onChange={() => handleFieldChange('sold_out', !rData.sold_out, rData, index)}
                      />
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {index > 0 && (
                        <button
                          style={{ padding: '16px', margin: '0' }}
                          className="delete"
                          onClick={() => handleDeleteRow(index)}
                        ></button>
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>
                  <button
                    style={{ float: 'left', width: '40px', height: '40px', padding: '10px', color: '#555' }}
                    className="button"
                    onClick={() => handleNewRow()}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <br />
                  <br />
                  <button className="button" onClick={submitForm}>
                    Submit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CheckBoxStyles>
    </ErrorStyles>
  );
}

export { AddNewItemOptions };
