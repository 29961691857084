import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Login } from 'pages';
import { NavigationBar, NavigationBarShim } from 'components/_shared/NavigationBarForLoginPage';

export function KitchenLoginPage() {
  return (
    <section className="section">
      <div className="container">
        <NavigationBar />
        <NavigationBarShim />
        <Switch>
          <Route key="rest" path="*">
            <Login />
          </Route>
        </Switch>
      </div>
    </section>
  );
}
