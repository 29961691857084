import React from 'react';

function ErrorBlock(props) {
  const { message, isVisible, className, children } = props;
  return isVisible ? (
    <div className={`${className} notification is-danger is-light`} style={{ marginTop: '20px' }}>
      {message}
      {children}
    </div>
  ) : null;
}
export default ErrorBlock;
