import { useRef, useEffect } from 'react';
const MapSearchBox = ({ currentAddress, handleGeoLocateSuccess, isGeolocating, getPosition }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    // restrict your search to a specific type of result
    // types: ['geocode', 'address', 'establishment', '(regions)', '(cities)'],
    // restrict your search to a specific country, or an array of countries
    // componentRestrictions: { country: ['gb', 'us'] },
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
    autoCompleteRef.current.addListener('place_changed', async function () {
      const place = await autoCompleteRef.current.getPlace();
      const position = {
        coords: { latitude: place?.geometry?.location?.lat(), longitude: place?.geometry?.location?.lng() },
      };
      const formattedAddress =
        autoCompleteRef?.current?.gm_accessors_?.place?.nj?.formattedPrediction || place?.formatted_address;
      await handleGeoLocateSuccess(position, formattedAddress);
    });
  }, [currentAddress]);

  return (
    <div key={currentAddress}>
      <input
        className="input"
        ref={inputRef}
        placeholder="Enter truck location..."
        defaultValue={currentAddress}
        style={{ width: 'calc(100% - 33px)', border: '1px solid #dbdbdb', borderRadius: 0 }}
      />
      <button
        className={isGeolocating ? 'button is-loading' : 'button'}
        style={{
          width: '33px',
          padding: '4px',
          borderRadius: 0,
          boxShadow: 'inset 0 0.0625em 0.125em rgb(10 10 10 / 5%)',
          border: '1px solid #dbdbdb',
          borderLeft: 0,
          filter: 'brightness(1)',
          '&:hover': {
            backgroundColor: '#efefef',
          },
        }}
      >
        <i
          disabled={isGeolocating}
          onClick={getPosition}
          className="fa fa-crosshairs"
          aria-hidden="true"
          style={{ width: '100%', fontSize: '25px' }}
        />
      </button>
    </div>
  );
};
export { MapSearchBox };
