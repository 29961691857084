import React, { useEffect, useState, useContext, useRef } from 'react';
import { ORDER_STATUS } from 'utils/constants';
import { getMappedItemsForDisplay } from 'utils/general';
import styled from 'styled-components';
import { socket } from '../socket';
import { printReceipt } from 'api/receiptsAPI';
import { Level, Button } from 'react-bulma-components';
import { getMenu } from 'api/menuAPI';
import moment from 'moment';
import { MenuContext } from 'contexts';

const Styles = styled.div`
  padding: 20px;
  padding-top: 0;
  * {
    color: black !important;
  }
`;

function OrderTicket({ orderInitial, noEditButtons }) {
  const [order, setOrder] = useState(orderInitial);
  const date = order.inserted_at?.slice(0, -1);
  const id = order.id;
  const idDisplay = id;
  const items = order.items;
  const status = order.order_status;
  const orderItemsWithQty = getMappedItemsForDisplay(items);
  const printHtmlElementId = `printreceipt_${order.stripe_paymentintent_id}`;
  const [isPrinting, setIsPrinting] = useState(false);
  const [showConfirmDone, setShowConfirmDone] = useState(0);
  const [showConfirmCancel, setShowConfirmCancel] = useState(0);

  const handleOrderDataResponse = (o) => {
    if (o.id === orderInitial.id) {
      setOrder(o);
    }
  };

  useEffect(() => {
    socket.on('order/order_data', handleOrderDataResponse);
    return () => {
      socket.off('order/order_data', handleOrderDataResponse);
    };
  }, []);

  const inProgress = status == ORDER_STATUS.IN_PROGRESS;

  async function handleReceiptPrint() {
    if (isPrinting) {
      return;
    }
    setIsPrinting(true);
    try {
      const element = document.getElementById(printHtmlElementId);
      await printReceipt(element);
    } finally {
      setIsPrinting(false);
    }
  }

  function markOrderDone() {
    const updated = { ...order, order_status: ORDER_STATUS.DONE };
    socket.emit('orders/mark_order_done', updated);
  }

  function markOrderCancelled() {
    const updated = { ...order, order_status: ORDER_STATUS.CANCELLED };
    socket.emit('orders/update_order', updated);
  }

  function markOrderInProgress() {
    const updated = { ...order, order_status: ORDER_STATUS.IN_PROGRESS };
    socket.emit('orders/update_order', updated);
  }

  function markOrderNew() {
    const updated = { ...order, order_status: ORDER_STATUS.NEW };
    socket.emit('orders/update_order', updated);
  }

  let textcolor = status == ORDER_STATUS.CANCELLED ? 'red' : 'blue';
  textcolor = status == ORDER_STATUS.DONE ? 'green' : textcolor;
  const backgroundcolor = inProgress ? '#e0f5e6' : '#eee';

  const ProgressButton = inProgress ? (
    <Button onClick={markOrderNew} style={{ marginTop: 0 }}>
      Unmark in progress
    </Button>
  ) : (
    <Button onClick={markOrderInProgress} style={{ marginTop: 0 }}>
      Mark In Progress
    </Button>
  );

  const buttonLoadingStyle = isPrinting ? 'is-loading' : '';

  function formatPhoneNumber(phoneNumberString) {
    let result = phoneNumberString;
    try {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        //const intlCode = match[1] ? '+1 ' : '';
        result = match[2] + '-' + match[3] + '-' + match[4];
      }
    } catch (e) {}
    return result;
  }

  return (
    <>
      <div className="order-confirmation">
        <div className="columns is-centered" style={{ margin: '0 !important' }}>
          <div
            className="column"
            style={{
              width: '400px',
              maxWidth: '400px',
              border: '1px solid #b5b5b5',
              borderRadius: '5px',
              background: backgroundcolor,
              padding: '20px',
            }}
          >
            <Level style={{ marginBottom: '10px' }}>
              <Level.Side align="left">
                <Button
                  className={`is-outlined ${buttonLoadingStyle}`}
                  onClick={handleReceiptPrint}
                  style={{ transitionDuration: 0, marginTop: 0, display: 'none' }}
                >
                  Print Receipt
                </Button>
              </Level.Side>
              <Level.Side align="right"></Level.Side>
            </Level>
            {!noEditButtons ? (
              <Level style={{ marginBottom: 0 }}>
                <Level.Side align="left">{ProgressButton}</Level.Side>
                <Level.Side align="right">{order?.Order_PromoCode}</Level.Side>
              </Level>
            ) : null}
            <br />
            <Styles id={printHtmlElementId}>
              <div className="is-size-4" style={{ textAlign: 'left' }}>
                #{idDisplay}
              </div>
              <div style={{ textAlign: 'left' }}>{order?.Order_CustName}</div>
              <div style={{ textAlign: 'left' }}>{formatPhoneNumber(order?.Order_PhoneNumber)}</div>
              <div style={{ textAlign: 'left' }}>
                <span>Time in: </span>
                <span style={{ paddingLeft: '22px' }}>{moment(new Date(date)).format('h:mm A')} </span>
              </div>
              <br />
              {orderItemsWithQty.map((i, idx) => {
                return <ReceiptLineItem key={idx} item={i} />;
              })}
              {order?.notes && (
                <>
                  <br />
                  <div
                    className="has-text-left"
                    style={{ fontSize: '0.9rem', textAlign: 'left', fontStyle: 'italic', marginTop: '8px' }}
                  >
                    **{order.notes}
                  </div>
                </>
              )}
            </Styles>
            {!noEditButtons ? (
              <Level style={{ marginTop: '20px', marginBottom: 0 }}>
                <Level.Side align="left" style={{ display: 'block' }}>
                  <Button
                    className="is-danger is-outlined"
                    onClick={() => setShowConfirmCancel(+!showConfirmCancel)}
                    style={{
                      width: '90px',
                      marginBottom: showConfirmCancel || showConfirmDone == showConfirmCancel ? '0px' : '40px',
                    }}
                  >
                    Cancel
                  </Button>
                  <br />
                  <Button
                    className={`is-danger is-outlined`}
                    onClick={markOrderCancelled}
                    style={{ width: '90px', display: showConfirmCancel ? 'initial' : 'none' }}
                  >
                    Confirm
                  </Button>
                </Level.Side>
                <Level.Side align="right" style={{ display: 'block' }}>
                  <Button
                    className="is-info is-outlined"
                    onClick={() => setShowConfirmDone(+!showConfirmDone)}
                    style={{
                      width: '90px',
                      marginBottom: showConfirmDone || showConfirmDone == showConfirmCancel ? '0px' : '40px',
                    }}
                  >
                    Done
                  </Button>
                  <br />
                  <Button
                    className={`is-info is-outlined`}
                    onClick={markOrderDone}
                    style={{ width: '90px', display: showConfirmDone ? 'initial' : 'none' }}
                  >
                    Confirm
                  </Button>
                </Level.Side>
              </Level>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

function ReceiptLineItem({ item }) {
  const itemPrice =
    Number(item?.OrderItem_Price || 0) +
    Number(item.OrderItem_ChosenOptions?.reduce((acc, co) => acc + Number(co.price), 0) || 0);
  const total = (Number(itemPrice) * item.qty).toFixed(2);
  const qty = item.qty;

  const [menuState, menuDispatch] = useContext(MenuContext);
  const menu = menuState.menu;

  useEffect(() => {
    const asyncContainerFunc = async function () {
      if (!menu) {
        const menuObj = await getMenu();
        menuDispatch({ type: 'SET_MENU', menu: menuObj });
      }
    };
    asyncContainerFunc();
  }, []);

  if (!menu) {
    return null;
  }

  const defaultItemOptions = menu?.item_options?.filter((mi) => item.OrderItem_DefaultOptions?.includes(mi.id));

  const chosenItemIds = item.OrderItem_ChosenOptions?.sort((a, b) =>
    `${a.is_protein} ${a.name}` > `${b.is_protein} ${b.name}` ? -1 : 1,
  ).map((ci) => ci.id);
  const defaultItemIds = defaultItemOptions?.map((di) => di.id);

  let unchosenItems = defaultItemIds?.filter((id) => !chosenItemIds?.includes(id));
  let unchosenObjects = defaultItemOptions
    ?.filter((obj) => obj.active)
    //?.filter((obj) => !obj.sold_out) //uncomment for --> dont count soldout things as "unchosen"
    ?.filter((obj) => unchosenItems?.includes(obj.id));

  let addedItems = chosenItemIds?.filter((id) => !defaultItemIds?.includes(id));
  let addedObjects = item.OrderItem_ChosenOptions?.filter((obj) => addedItems?.includes(obj.id));

  let options = (
    <div>
      {addedObjects?.map((o) => (
        <p key={o.id} style={{ marginLeft: '20px' }}>
          {o?.is_protein ? `PROTEIN - ${o.name}` : `ADD - ${o.name}`}
        </p>
      ))}
      {unchosenObjects?.map((o) => (
        <p key={o.id} style={{ marginLeft: '20px' }}>
          NO - {o.name}
        </p>
      ))}
    </div>
  );

  let name = item.OrderItem_Name;
  if (item?.OrderItem_OptionLogic == 1) {
    name = item?.OrderItem_ChosenOptions?.[0].name || name;
    options = null;
  }

  if (!addedItems?.length && !unchosenObjects?.length) {
    options = null;
  }

  return (
    <>
      <div style={{ textAlign: 'left', marginBottom: 0 }}>
        <div style={{ marginTop: '10px' }}>
          {qty} x {name}
        </div>
        {item?.OrderItem_Notes && (
          <div
            className="has-text-left"
            style={{ fontSize: '0.9rem', textAlign: 'left', fontStyle: 'italic', marginLeft: '20px' }}
          >
            **{item.OrderItem_Notes}
          </div>
        )}
        {options}
      </div>
    </>
  );
}

export { OrderTicket };
