import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useLocation, withRouter } from 'react-router';
import {
  Menu,
  CheckoutPrivate,
  OrderConfirmation,
  OrdersActiveQueue,
  OrdersCompletedAndCancelled,
  CartMobile,
} from 'pages';
import { NavigationBar } from 'components/_shared/NavigationBar';
import { useCartSidebar, CartSidebar } from 'components/CartSidebar';
import { socket } from '../../socket';
import { ToastContainer, Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/toastOverrides.css';
import { useScreenType } from 'utils/useScreenType';
import { AdminMenu } from 'pages/AdminMenu';
import styled from 'styled-components';

export function PrivateLayout() {
  const boopMP3 = new Audio('/sounds/boop.mp3');

  const sidebarProps = useCartSidebar();
  const { handleOpenCartSidebar } = sidebarProps;
  const screen = useScreenType();

  const CartSidebarWithRouter = withRouter(CartSidebar);
  const NavigationBarWithRouter = withRouter(NavigationBar);

  const handleNewOrderIn = (o) => {
    boopMP3.load();
    boopMP3.play();
    toast.info('New Order In', { containerId: 'top-right' });
  };

  useEffect(() => {
    socket.on('order/notify_kitchen_of_new_order', handleNewOrderIn);
    return () => {
      socket.off('order/notify_kitchen_of_new_order', handleNewOrderIn);
    };
  }, []);

  let widthStyle = { marginLeft: 0, width: '100%' };
  // if (screen != 'mobile' && screen != 'tablet' && isOpen) {
  //   widthStyle = { marginLeft: 0, width: 'calc(100% - 450px)' };
  // }

  const mobilePaddingStyle = screen == 'mobile' ? { paddingLeft: 0, paddingRight: 0 } : {};

  return (
    <>
      <NavigationBarWithRouter handleOpenCartSidebar={handleOpenCartSidebar} />
      <section className="section" style={{ overflowX: 'auto', maxWidth: '2000px', margin: 'auto' }}>
        <div className="container is-fluid" style={{ ...widthStyle, ...mobilePaddingStyle }}>
          <ToastContainer
            enableMultiContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick={true}
            transition={Slide}
            containerId="top-right"
          />
          <ToastContainer
            enableMultiContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick={true}
            transition={Slide}
            containerId="top-center"
          />
          <CartSidebarWithRouter {...sidebarProps} />
          <Switch>
            <Route key="cart" path="/cart">
              <CartMobile />
            </Route>
            <Route key="menu" path="/menu">
              <Menu />
            </Route>
            <Route key="checkout" path="/checkout">
              <CheckoutPrivate />
            </Route>
            <Route key="order-confirmation" path="/order-confirmation">
              <OrderConfirmation />
            </Route>
            <Route key="order-queue" path="/order-queue">
              <OrdersActiveQueue />
            </Route>
            <Route key="order-history" path="/order-history">
              <OrdersCompletedAndCancelled />
            </Route>
            <Route key="manage-menu" path="/admin">
              <AdminMenu />
            </Route>
            <Route key="rest" path="*">
              <Redirect to="/menu" />
            </Route>
          </Switch>
        </div>
      </section>
    </>
  );
}
