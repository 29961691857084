import React from 'react';
import { GeneralContext, GeneralProvider } from './generalContext';
import { ShoppingCartContext, ShoppingCartProvider } from './shoppingCartContext';
import { OrdersContext, OrdersProvider } from './ordersContext';
import { AuthContext, AuthProvider } from './authContext';
import { MenuContext, MenuProvider } from './menuContext';

const MasterProvider = ({ children }) => (
  <>
    <AuthProvider>
      <GeneralProvider>
        <MenuProvider>
          <OrdersProvider>
            <ShoppingCartProvider>{children}</ShoppingCartProvider>
          </OrdersProvider>
        </MenuProvider>
      </GeneralProvider>
    </AuthProvider>
  </>
);

export { MasterProvider, AuthContext, GeneralContext, ShoppingCartContext, OrdersContext, MenuContext };
