import http from '../utils/http';

async function login(loginDetails) {
  //need to tell socket to AUTH somehow...
  //need to tell socket to AUTH somehow...
  //need to tell socket to AUTH somehow...
  const authResponse = await http.post(`${process.env.REACT_APP_POS_SERVICE_URL}/auth/login`, loginDetails);
  const token = authResponse.data.data.token?.slice(7);
  http.setAuthToken(token);
  return authResponse.data;
}

async function logout() {
  //need to tell socket to UNauth somehow...
  //need to tell socket to UNauth somehow...
  //need to tell socket to UNauth somehow...
  //const result = await http.post(`${process.env.REACT_APP_POS_SERVICE_URL}/auth/logout`);
  http.clearAuthToken();
}

const checkAuth = async () => {
  const authResponse = await http.post(`${process.env.REACT_APP_POS_SERVICE_URL}/auth/check-auth`);
  return authResponse?.data;
};

export { login, logout, checkAuth };
