import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { MapSearchBox } from './MapSearchBox';
import { getTruckDatabaseLocation, setTruckDatabaseLocation } from 'api/adminAPI';
import styled from 'styled-components';
import _ from 'lodash';
import styles from './Map.scss';

const PinWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: ${(props) => (_.isEqual(props.savedLoc, props.loc) ? '#17a900;' : '#000;')}
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
`;
const MapPin = ({ text, onClick, savedLoc, loc }) => (
  <PinWrapper alt={text || null} onClick={onClick || null} savedLoc={savedLoc} loc={loc} />
);

const Map = () => {
  const defaultLoc = {
    center: { lat: 48.6910324, lng: -122.4065632, address: '5090 Samish Way, Bellingham, WA 98229' },
    zoom: 14,
  };
  const defaultTaxRate = 0.086;
  const [loc, setLoc] = useState(defaultLoc);
  const [geocodeData, setGeocodeData] = useState(null);
  const [savedLoc, setSavedLoc] = useState(defaultLoc);

  const [isGeolocating, setIsGeolocating] = useState(false);
  const [isSettingLoc, setIsSettingLoc] = useState(false);

  useEffect(() => {
    const fetchAndLoadData = async function () {
      const currentLoc = await getTruckDatabaseLocation();
      const mappedLoc = {
        center: {
          lat: Number(currentLoc?.Settings_TruckLatitude),
          lng: Number(currentLoc?.Settings_TruckLongitude),
          address: currentLoc?.Settings_TruckAddress,
        },
        zoom: Number(currentLoc?.Settings_MapZoom),
      };
      setLoc(mappedLoc);
      setSavedLoc(mappedLoc);
    };
    fetchAndLoadData();
  }, []);

  async function handleClickSetLocation() {
    setIsSettingLoc(true);

    const streetNum = geocodeData?.results
      ?.filter((r) => r.types?.includes('street_address'))?.[0]
      ?.address_components.filter((c) => c.types?.includes('street_number'))?.[0]?.long_name;
    const streetRoute = geocodeData?.results
      ?.filter((r) => r.types?.includes('street_address'))?.[0]
      ?.address_components.filter((c) => c.types?.includes('route'))?.[0]?.long_name;
    const city = geocodeData?.results
      ?.filter((r) => r.types?.includes('street_address'))?.[0]
      ?.address_components.filter((c) => c.types?.includes('locality'))?.[0]?.long_name;
    const zip = geocodeData?.results
      ?.filter((r) => r.types?.includes('street_address'))?.[0]
      ?.address_components.filter((c) => c.types?.includes('postal_code'))?.[0]?.long_name;
    const streetAddr = (streetNum + ' ' + streetRoute)?.trim();

    let taxRate = process.env.REACT_APP_SALES_TAX_RATE;
    try {
      const taxResp = await fetch(
        `https://webgis.dor.wa.gov/webapi/AddressRates.aspx?output=json&addr=${streetAddr}&city=${city?.trim()}&zip=${zip?.trim()}`,
      );
      const taxData = await taxResp.json();
      taxRate = taxData?.Rate;
    } catch (e) {
      console.log(
        'Error getting taxRate for selected location. Using default taxRate of ' +
          process.env.REACT_APP_SALES_TAX_RATE +
          ' as fallback',
      );
    }

    const locForSubmit = {
      lat: loc?.center?.lat,
      lng: loc?.center?.lng,
      address: loc?.center?.address || '',
      taxRate: taxRate || process.env.REACT_APP_SALES_TAX_RATE,
      zoom: loc?.zoom,
    };
    console.log(locForSubmit);
    try {
      const result = await setTruckDatabaseLocation({ location: locForSubmit });
      setSavedLoc(loc);
    } finally {
      setIsSettingLoc(false);
    }
  }

  // Geolocation success callback fn
  async function handleGeoLocateSuccess(position, selectedAddress) {
    const lat = position.coords.latitude; // You have obtained latitude coordinate!
    const lng = position.coords.longitude; // You have obtained longitude coordinate!
    const resp = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GMAPS_GEOCODING_KEY}`,
    );
    const geocodeJSON = await resp.json();
    const formattedAddress = selectedAddress
      ? selectedAddress
      : geocodeJSON?.results?.filter((r) => r.types?.includes('street_address'))?.[0]?.formatted_address;

    setLoc({ ...loc, center: { lat: lat, lng: lng, address: formattedAddress } });
    setGeocodeData(geocodeJSON);
    setIsGeolocating(false);
  }

  // Geolocation error callback fn
  function handleGeoLocateError() {
    setIsGeolocating(false);
    alert('error geolocating, please enter address instead ->');
  }

  function handleMapClick(props) {
    if (props?.event?.shiftKey) {
      const position = {
        coords: { latitude: props.lat, longitude: props.lng },
      };
      handleGeoLocateSuccess(position);
    }
  }

  // If browser supports navigator.geolocation, generate Lat/Lng else let user know there is an error
  const getPosition = () => {
    setIsGeolocating(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleGeoLocateSuccess, handleGeoLocateError); // Passing in a success callback and an error callback fn
    } else {
      alert('Sorry, Geolocation is not supported by this browser, please enter address instead ->'); // Alert is browser does not support geolocation
    }
  };

  return (
    <div className="map">
      {window?.google?.maps?.places?.Autocomplete && (
        <>
          <MapSearchBox
            currentAddress={loc?.center?.address}
            handleGeoLocateSuccess={handleGeoLocateSuccess}
            isGeolocating={isGeolocating}
            getPosition={getPosition}
          />
        </>
      )}
      <div className="google-map" style={{ width: '100%', height: '300px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GMAPS_KEY, libraries: ['places', 'geometry'] }}
          center={loc.center}
          zoom={loc.zoom}
          onClick={handleMapClick}
        >
          <MapPin
            lat={loc.center.lat}
            lng={loc.center.lng}
            text={loc.center.address}
            savedLoc={savedLoc}
            loc={loc}
          />
        </GoogleMapReact>
      </div>
      <button
        className={`${isSettingLoc ? 'button is-loading' : 'button'}`}
        disabled={isSettingLoc || _.isEqual(savedLoc, loc)}
        onClick={handleClickSetLocation}
      >
        Set Location
      </button>
    </div>
  );
};

export { Map };
