import React, { useContext, useEffect } from 'react';
import Select from 'react-select';
import { getMenu } from 'api/menuAPI';
import { MenuContext } from 'contexts';
import styled from 'styled-components';
import { socket } from '../../socket';

const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const SelectStyles = styled.div`
  input {
    height: auto;
    box-shadow: none !important;
  }
  .react-select__control {
    padding: 1px;
  }
`;

const ItemOptionSelect = ({ handleChange, value, classNamePrefix }) => {
  const [menuState, menuDispatch] = useContext(MenuContext);
  const menu = menuState.menu;

  useEffect(() => {
    const asyncContainerFunc = async function () {
      if (!menu) {
        const menuObj = await getMenu();
        menuDispatch({ type: 'SET_MENU', menu: menuObj });
      }
    };
    asyncContainerFunc();
  }, []);

  if (!menu) {
    return null;
  }

  return (
    <SelectStyles>
      <Select
        classNamePrefix={classNamePrefix || 'react-select'}
        closeMenuOnSelect={true}
        value={value}
        isMulti
        options={menu?.item_options.map((io) => {
          const label = io.price > 0 ? io.name + ' (+$' + Number(io.price).toFixed(2) + ')' : io.name;
          return { value: io.id, label: label };
        })}
        styles={styles}
        onChange={handleChange}
      />
    </SelectStyles>
  );
};

export { ItemOptionSelect };
