import http from '../utils/http';

const { REACT_APP_POS_SERVICE_URL } = process.env;

function ensurePromoPricingBeforeSubmit(order) {
  for (var i of order.items) {
    i.price = i.promo_price || i.price;
    delete i.promo_price;
    for (var o of i.OrderItem_ChosenOptions) {
      o.price = o.promo_price || o.price;
      delete o.promo_price;
    }
  }
}

async function submitOrderCardPresent({ order }) {
  if (!order) {
    console.error('`order` not passed to submitOrderCardPresent API method');
    return;
  }
  ensurePromoPricingBeforeSubmit(order);
  const resp = await http.post(`${process.env.REACT_APP_POS_SERVICE_URL}/orders/submit-cardpresent-order`, {
    order,
  });
  return resp;
}

async function submitOrderNoCharge({ order }) {
  if (!order) {
    console.error('`order` not passed to submitOrderNoCharge API method');
    return;
  }
  ensurePromoPricingBeforeSubmit(order);
  const resp = await http.post(`${process.env.REACT_APP_POS_SERVICE_URL}/orders/submit-nocharge-order`, {
    order,
  });
  return resp;
}

async function submitOrderEcomm({
  elavonAMT,
  cardNum,
  cardExp,
  cardCVV,
  cardZip,
  itemsInCart,
  tip,
  order,
  tax,
  taxRate,
  preTax,
}) {
  ensurePromoPricingBeforeSubmit(order);
  const payload = {
    order,
    items: itemsInCart,
    tip,
    taxRate,
    preTax,
    currency: 'USD',
    isCardPresent: false,
    ssl_amount: Math.round(elavonAMT * 100) / 100,
    ssl_salestax: Math.round(tax * 100) / 100,
    ssl_card_number: cardNum,
    ssl_exp_date: cardExp,
    ssl_cvv2cvc2: cardCVV,
    ssl_avs_zip: cardZip,
    ssl_invoice_number: Date.now() + '-ecomm-' + Math.floor(Math.random() * 1000),
  };
  const orderResp = await http.post(`${REACT_APP_POS_SERVICE_URL}/orders/submit-ecomm-order`, payload);
  return orderResp;
}

function sortOrders(orders) {
  orders.sort(function (a, b) {
    return a.created_at - b.created_at;
  });
}

export { sortOrders, submitOrderNoCharge, submitOrderEcomm, submitOrderCardPresent };
