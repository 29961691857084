import React, { useState, useEffect } from 'react';
import { Level, Button } from 'react-bulma-components';
import { Receipt } from 'components/Receipt';
import { RefundDialog } from './RefundDialog';
import { socket } from '../socket';
import { OrderTicket } from './OrderTicket';

const OrderDetail = ({ orderInitial }) => {
  const [order, setOrder] = useState(orderInitial);
  const [showRefundDialog, setShowRefundDialog] = useState(false);
  const { items, tip } = order;

  const handleOrderDataResponse = (o) => {
    if (o.id === orderInitial.id) {
      setOrder(o);
    }
  };

  useEffect(() => {
    socket.on('order/order_data', handleOrderDataResponse);
    socket.emit('orders/query_order_detail', order);
    return () => {
      socket.off('order/order_data', handleOrderDataResponse);
    };
  }, [showRefundDialog]);

  const refundable =
    items?.filter((i) => !i.OrderItem_Refunded && !i.OrderItem_NoCharge)?.length > 0 ||
    (!order.tip_refunded && order.tip_amount > 0);
  const refundText = refundable ? 'Refund' : 'Already Refunded';
  const refundDisabled = refundable ? false : true;

  if (showRefundDialog) {
    return <RefundDialog orderInitial={order} closeDialogCallback={() => setShowRefundDialog(false)} />;
  } else {
    return (
      <>
        <div style={{ width: '100%', display: 'block', textAlign: 'center', marginBottom: '25px' }}>
          <Button style={{ display: 'none' }} onClick={() => alert('add print receipt function')}>
            Print Receipt
          </Button>
          <Button
            disabled={refundDisabled}
            onClick={() => setShowRefundDialog(true)}
            style={refundDisabled ? { pointerEvents: 'none', width: 'auto' } : { width: 'auto' }}
          >
            {refundText}
          </Button>
        </div>
        <OrderTicket orderInitial={order} noEditButtons={true} />
      </>
    );
  }
};

export { OrderDetail };
