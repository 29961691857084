import React from 'react';
import { NavLink, Switch, Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AddNewItemOptions } from 'components/AddNewItemOptions';
import { ManageCouponCodes } from 'components/ManageCouponCodes';
import { MenuItemManager } from 'components/MenuItemManager';
import { MenuItemOptionManager } from 'components/MenuItemOptionManager';
import { EditItem } from 'components/EditItem';
import { AddNewItem } from 'components/AddNewItem';
import { OpenCloseStore } from 'components/OpenCloseStore';
import { DeviceConfig } from 'components/DeviceConfig';
import { useScreenType } from 'utils/useScreenType';

export function AdminMenu() {
  const { pathname, state } = useLocation();
  const screenType = useScreenType();
  const item = state?.item || null;

  const MenuStyles = styled.div`
    display: inline-block;
    float: left;
    .button {
      border: none;
      text-align: left;
    }
  `;

  return (
    <>
      <MenuStyles>
        <aside className="menu" style={{ maxWidth: '350px', textAlign: 'left' }}>
          <p className="menu-label">Operations</p>
          <ul className="menu-list">
            <li>
              <NavLink
                to="/admin/store-operations-update"
                className="button"
                activeClassName="is-info"
                isActive={() => ['/admin', '/admin/store-operations-update'].includes(pathname)}
              >
                Update Store Operations
              </NavLink>
            </li>
          </ul>
          <p className="menu-label">General Management</p>
          <ul className="menu-list">
            <li>
              <NavLink to="/admin/manage-existing-items" className="button" activeClassName="is-info">
                Manage Existing Items
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-new-items" className="button" activeClassName="is-info">
                Add Item
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-new-item-options" className="button" activeClassName="is-info">
                Add Item Options
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/device-options-config"
                className="button"
                activeClassName="is-info"
                isActive={() => ['/admin/device-options-config'].includes(pathname)}
              >
                Set Device Options
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/manage-discount-codes" className="button" activeClassName="is-info">
                Discount Codes
              </NavLink>
            </li>
          </ul>
        </aside>
      </MenuStyles>
      <div>
        <Switch>
          <Route key="store-operations-update" path="/admin/store-operations-update">
            <div style={{ display: 'flex', minWidth: screenType == 'mobile' ? '250px' : '600px' }}>
              <OpenCloseStore />
            </div>
          </Route>
          <Route key="manage-discount-codes" path="/admin/manage-discount-codes">
            <div style={{ display: 'flex', minWidth: screenType == 'mobile' ? '250px' : '600px' }}>
              <ManageCouponCodes />
            </div>
          </Route>
          <Route key="device-options-config" path="/admin/device-options-config">
            <div style={{ display: 'flex', minWidth: screenType == 'mobile' ? '250px' : '600px' }}>
              <DeviceConfig />
            </div>
          </Route>
          <Route key="edit-menu-items" path="/admin/manage-existing-items/edit-item">
            <div style={{ display: 'flex' }}>
              <EditItem itemId={item?.id} />
            </div>
          </Route>
          <Route key="edit-menu-items" path="/admin/manage-existing-items">
            <div style={{ display: 'flex' }}>
              <MenuItemManager />
              <MenuItemOptionManager />
            </div>
          </Route>
          <Route key="add-new-menu-item-options" path="/admin/add-new-item-options">
            <div style={{ display: 'flex' }}>
              <AddNewItemOptions />
            </div>
          </Route>
          <Route key="add-new-menu-items" path="/admin/add-new-items">
            <div style={{ display: 'flex' }}>
              <AddNewItem />
            </div>
          </Route>
          <Route key="rest" path="*">
            <div style={{ display: '-webkit-box' }}>
              <OpenCloseStore />
            </div>
          </Route>
        </Switch>
      </div>
    </>
  );
}
