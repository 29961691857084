import React, { useEffect, useContext } from 'react';
import { GeneralContext } from 'contexts';
import styled from 'styled-components';
import { useScreenType } from 'utils/useScreenType';
import { socket } from '../socket';

const Styles = styled.section`
  width: 100vw;
  height: 40px;
  position: relative;
  vertical-align: center;
  line-height: 40px;
  z-index: 0;
`;

function StoreStatusBanner() {
  const screenType = useScreenType();
  const [generalState, generalDispatch] = useContext(GeneralContext);
  const { storeOpen } = generalState;

  useEffect(() => {
    if (storeOpen == null) {
      socket.emit('store/are_we_open');
    }
  }, []);

  useEffect(() => {
    const handler = (resp) => generalDispatch({ type: 'RECEIVED_STORE_OPENSTATUS', newStoreOpen: resp.open });

    socket.on('store/store_open_status_updated', handler);
    return () => {
      socket.off('store/store_open_status_updated', handler);
    };
  }, []);

  return !storeOpen ? (
    <Styles screenType={screenType} className="hero is-danger">
      Store is Closed
    </Styles>
  ) : (
    <Styles screenType={screenType} className="hero is-success">
      Store is Open
    </Styles>
  );
}

function StatusBannerShim() {
  return <div style={{ height: '40px' }} />;
}

export { StoreStatusBanner, StatusBannerShim };
