import React, { useState, useEffect } from 'react';
import { getMappedItemsForDisplay } from 'utils/general';
import { Modal, Section } from 'react-bulma-components';
import { OrderDetail } from 'components/OrderDetail';
import { ORDER_STATUS } from 'utils/constants';
import { socket } from '../socket';
import styled from 'styled-components';
import _ from 'lodash';
import { PageLoadSpinner } from 'components/_shared/PageLoadSpinner';

const TableStyles = styled.div`
  .done {
    color: hsl(204, 86%, 53%);
  }
  .cancelled {
    color: hsl(348, 100%, 61%);
  }
  table {
    margin: auto;
  }
`;

export const OrdersCompletedAndCancelled = () => {
  const [modalContent, setModalContent] = useState(null);
  const [orders, setOrders] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const getData = (newOrdersArray) => {
    newOrdersArray = newOrdersArray?.sort(function (a, b) {
      return b.id - a.id;
    });
    // filter to only show done and cancelled orders
    // newOrdersArray = newOrdersArray.filter(
    //   (o) => o.order_status == ORDER_STATUS.DONE || o.order_status == ORDER_STATUS.CANCELLED,
    // );
    setIsFirstLoad(false);
    setOrders(newOrdersArray);
  };

  const requeryInitialData = () => {
    socket.emit('orders/initial_data');
  };

  useEffect(() => {
    socket.on('orders/get_data', getData);
    socket.on('orders/requery_initial_data', requeryInitialData);
    socket.emit('orders/initial_data');
    return () => {
      socket.off('orders/get_data', getData);
      socket.off('orders/requery_initial_data', requeryInitialData);
    };
  }, []);

  if (isFirstLoad) {
    return <PageLoadSpinner />;
  }

  if (!orders?.length) {
    return <p>No orders found.</p>;
  }

  function handleRowClick(order) {
    setModalContent({ ...order });
  }

  return (
    <>
      <div className="table-container">
        <TableStyles>
          <table className="table is-hoverable">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Total</th>
                <th>Sales Tax</th>
                <th>Status</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((o) => {
                console.log(o.inserted_at);
                console.log(new Date(o.inserted_at).toLocaleTimeString());
                const items = getMappedItemsForDisplay(o.items);
                const totalDisplay = o.total > 0 ? `$${o.total.toFixed(2)}` : 'No charges';
                const taxDisplay = o.sales_tax > 0 ? `$${o.sales_tax.toFixed(2)}` : '-';
                const orderStatus = _.startCase(_.toLower(o.order_status || ''));
                let orderCellClassName = '';
                orderCellClassName = o.order_status == ORDER_STATUS.DONE ? ' done ' : orderCellClassName;
                orderCellClassName =
                  o.order_status == ORDER_STATUS.CANCELLED ? ' cancelled ' : orderCellClassName;
                return (
                  <tr key={o.id}>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(o)}>
                      {o.id}
                    </td>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(o)}>
                      {o?.Order_CustName}
                    </td>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(o)}>
                      {o?.Order_PhoneNumber}
                    </td>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(o)}>
                      {totalDisplay}
                    </td>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(o)}>
                      {taxDisplay}
                    </td>
                    <td
                      className={orderCellClassName}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRowClick(o)}
                    >
                      {orderStatus}
                    </td>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(o)}>
                      {new Date(o.inserted_at.replace(/Z$/, '')).toLocaleTimeString(Date, {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td style={{ borderTop: '2px solid black', background: '#eee' }}>
                  No. Orders: {orders.filter((o) => o.order_status != ORDER_STATUS.CANCELLED).length}
                </td>
                <td style={{ borderTop: '2px solid black', background: '#eee' }}></td>
                <td style={{ borderTop: '2px solid black', background: '#eee' }}></td>
                <td style={{ borderTop: '2px solid black', background: '#eee' }}>
                  {`$${orders
                    .filter((o) => o.order_status != ORDER_STATUS.CANCELLED)
                    .reduce((sum, o) => sum + (o.total || 0), 0)
                    .toFixed(2)}`}
                </td>
                <td style={{ borderTop: '2px solid black', background: '#eee' }}>
                  {`$${orders
                    .filter((o) => o.order_status != ORDER_STATUS.CANCELLED)
                    .reduce((sum, o) => sum + (o.sales_tax || 0), 0)
                    .toFixed(2)}`}
                </td>
                <td style={{ borderTop: '2px solid black', background: '#eee' }}></td>
                <td style={{ borderTop: '2px solid black', background: '#eee' }}></td>
              </tr>
            </tbody>
          </table>
        </TableStyles>
      </div>
      <Modal
        show={modalContent != null}
        closeOnEsc={true}
        closeOnBlur={true}
        showClose={true}
        onClose={() => setModalContent(null)}
      >
        <Modal.Content>
          <Section
            style={{
              backgroundColor: 'white',
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'space-around',
              justifyContent: 'center',
            }}
          >
            <OrderDetail orderInitial={modalContent} />
          </Section>
        </Modal.Content>
      </Modal>
    </>
  );
};
