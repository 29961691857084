import React, { useContext } from 'react';
import { ShoppingCartContext } from 'contexts';
import styled from 'styled-components';
import { useScreenType } from 'utils/useScreenType';

const CartIconWithBadge = ({ handleClickCallback, isHiddenOnMobileDevice }) => {
  const [cartState, cartDispatch] = useContext(ShoppingCartContext);
  const screen = useScreenType();

  const classes = isHiddenOnMobileDevice ? 'fas fa-shopping-cart is-hidden-touch' : 'fas fa-shopping-cart';

  return (
    <CartIcon
      className={classes}
      onClick={handleClickCallback}
      screen={screen}
      style={{ color: 'white', margin: '10px 5px 10px 0' }}
    >
      {cartState?.itemsInCart?.length > 0 ? (
        <NumberOfItemsBadge>{cartState?.itemsInCart?.length}</NumberOfItemsBadge>
      ) : null}
    </CartIcon>
  );
};

const CloseCartIcon = ({ handleClickCallback }) => {
  return <CloseIcon className="fas fa-window-close" onClick={handleClickCallback} />;
};

const CloseIcon = styled.i`
  font-size: 44px;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */
`;

const CartIcon = styled.i`
  font-size: 44px;
  bottom: -5px;
  cursor: pointer;
  user-select: none;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
`;
const NumberOfItemsBadge = styled.div`
  width: 28px;
  height: 28px;
  line-height: 24px;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  border-radius: 1em;
  font-size: 0.4em;
  text-align: center;
  margin: auto;
  border: 2px solid white;
  background-color: rgb(74, 74, 74);
  color: white;
  position: absolute;
  top: -10px;
  right: 5px;
`;

export { CloseCartIcon, CartIconWithBadge };
