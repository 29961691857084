import React from 'react';
import { useState } from 'react';

import styles from './ToggleButtonGroup.module.scss';

function ToggleButtonGroup(props) {
  const { children, onSelectCallback, allowDeselect, groupClassName, fieldClassName, containerStyle } = props;
  let defaultValue = props.defaultValue;

  if (typeof defaultValue == 'undefined') {
    defaultValue = React.Children.toArray(children)[0]?.props?.label;
  }

  const [activeButton, setActiveButton] = useState(defaultValue);

  function onSelected(button) {
    let buttonNullable = button;
    if (button == activeButton && allowDeselect) {
      buttonNullable = defaultValue;
    }

    setActiveButton(buttonNullable);
    if (onSelectCallback) {
      onSelectCallback(buttonNullable);
    }
  }

  return (
    <div className={`field toggle-button-group ${styles.toggleButtonGroup}`} style={containerStyle}>
      <div className={`${groupClassName}`}>
        <div className={`${fieldClassName}`}>
          {children.map((child) => {
            return !!child
              ? React.cloneElement(child, {
                  key: child.props.label,
                  onSelected: onSelected,
                  isActive: activeButton === child.props.label,
                })
              : null;
          })}
        </div>
      </div>
    </div>
  );
}

export default ToggleButtonGroup;
