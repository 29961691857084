import React, { useEffect, useState, useContext } from 'react';
import { socket } from 'socket';
import { getMenu, editItem } from 'api/menuAPI';
import { MenuContext, GeneralContext } from 'contexts';
import { Map } from './Map';

function OpenCloseStore() {
  const [isGettingMenu, setIsGettingMenu] = useState(false);
  const [menuState, menuDispatch] = useContext(MenuContext);
  const [generalState, generalDispatch] = useContext(GeneralContext);
  const { menu } = menuState;
  const { settings, storeOpen } = generalState;

  useEffect(() => {
    async function asyncFunc() {
      const menuObj = await getMenu();
      menuDispatch({ type: 'SET_MENU', menu: menuObj });
      setIsGettingMenu(false);
    }
    if (!menu) {
      setIsGettingMenu(true);
      asyncFunc();
    }
  }, []);

  useEffect(() => {
    if (storeOpen == null) {
      socket.emit('store/are_we_open');
    }
    if (settings == null) {
      socket.emit('store/get_store_settings');
    }
  }, []);

  useEffect(() => {
    const handler1 = (resp) => generalDispatch({ type: 'RECEIVED_STORE_OPENSTATUS', newStoreOpen: resp.open });
    const handler2 = (resp) => generalDispatch({ type: 'RECEIVED_STORE_SETTINGS', newSettings: resp.settings });

    socket.on('store/store_open_status_updated', handler1);
    socket.on('store/store_settings_updated', handler2);
    return () => {
      socket.off('store/store_open_status_updated', handler1);
      socket.off('store/store_settings_updated', handler2);
    };
  }, []);

  async function handleUpdateSettings(key, val) {
    const newSettings = { ...settings, [key]: val };
    socket.emit('store/update_store_settings', newSettings);
  }

  function handleCloseStore() {
    socket.emit('store/close_store');
  }

  function handleOpenStore() {
    socket.emit('store/open_store');
  }

  async function handleChangeQtyAvailable(i, qtyNew) {
    const itemUpdated = { ...i, Item_QtyAvailable: qtyNew };
    await editItem({ item: itemUpdated });
  }

  return (
    <div style={{ paddingLeft: '80px', marginTop: '20px', textAlign: 'left', width: '700px' }}>
      {!storeOpen ? (
        <button className="button is-success" onClick={handleOpenStore} style={{ width: 'auto', zIndex: 0 }}>
          Open Store
        </button>
      ) : (
        <button className="button is-danger" onClick={handleCloseStore} style={{ width: 'auto', zIndex: 0 }}>
          Close Store
        </button>
      )}
      <br />
      <Map />
      <div style={{ marginTop: '30px' }}>
        <div className="title is-size-4" style={{ marginBottom: '8px' }}>
          Set Hours Header
        </div>
        <div key="store-hours-container-key">
          <textarea
            key="store-hours-key"
            name="storeHoursText"
            rows={2}
            cols={40}
            onBlur={(e) => handleUpdateSettings('Settings_HoursText', e?.target?.value)}
            defaultValue={settings?.Settings_HoursText}
            style={{ padding: '7px' }}
          />
        </div>
        <br />
        <div className="title is-size-4" style={{ marginBottom: '8px' }}>
          Set Daily Counts
        </div>
        {menu?.items
          ?.filter((i) => i.active)
          ?.map((i) => {
            return (
              <div key={i.id}>
                <div>{i?.name}</div>
                <input
                  key={i.id + '-' + i.Item_QtyAvailable} //having this key here lets this input update in realtime with the menuContext data as the menu data changes
                  placeholder="Bulgogi"
                  className="input"
                  defaultValue={i?.Item_QtyAvailable || 0}
                  onBlur={(e) => handleChangeQtyAvailable(i, e?.target?.value)}
                  style={{ width: '70px', marginBottom: '7px' }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export { OpenCloseStore };
