import React, { useContext, useEffect } from 'react';
import { Level, Button } from 'react-bulma-components';
import { AuthContext, ShoppingCartContext } from 'contexts';
import styled from 'styled-components';
import _ from 'lodash';
import { MenuContext } from 'contexts';
import { getMenu } from 'api/menuAPI';
import { getItemPrice } from 'utils/general';
import { useScreenType } from 'utils/useScreenType';

const CartProduct = ({ item }) => {
  const { name, price } = item;
  const [cartState, cartDispatch] = useContext(ShoppingCartContext);
  const [authState] = useContext(AuthContext);
  const screenType = useScreenType();

  const [menuState, menuDispatch] = useContext(MenuContext);
  const menu = menuState.menu;

  useEffect(() => {
    const asyncContainerFunc = async function () {
      if (!menu) {
        const menuObj = await getMenu();
        menuDispatch({ type: 'SET_MENU', menu: menuObj });
      }
    };
    asyncContainerFunc();
  }, []);

  const defaultItemOptions = menu?.item_options.filter((io) =>
    item.OrderItem_DefaultOptions?.includes(io.id),
  );

  const itemPrice = getItemPrice(item);

  function handleDeleteFromCart(item) {
    cartDispatch({ type: 'DELETE_ITEM_FROM_CART', item });
    return true;
  }

  function handleNoChargeItem(item) {
    const copy = {
      ...item,
      OrderItem_NoCharge: +!item.OrderItem_NoCharge,
    };
    cartDispatch({ type: 'UPDATE_ITEM_IN_CART', item: copy });
  }

  const Styles = styled.div`
    word-wrap: break-word;
    * {
      word-wrap: break-word;
    }
  `;

  if (!menu) {
    return null;
  }

  const chosenItemIds = item.OrderItem_ChosenOptions?.sort((a, b) =>
    `${a.is_protein} ${a.name}` > `${b.is_protein} ${b.name}` ? -1 : 1,
  ).map((ci) => ci.id);
  const defaultItemIds = item.OrderItem_DefaultOptions;

  let unchosenItems = defaultItemIds?.filter((id) => !chosenItemIds?.includes(id));
  let unchosenObjects = defaultItemOptions
    ?.filter((obj) => obj.active)
    //?.filter((obj) => !obj.sold_out) //uncomment for --> dont count soldout things as "unchosen"
    ?.filter((obj) => unchosenItems?.includes(obj.id));

  let addedItems = chosenItemIds?.filter((id) => !defaultItemIds?.includes(id));
  let addedObjects = item.OrderItem_ChosenOptions?.filter((obj) => addedItems?.includes(obj.id)) || [];

  let label = (
    <>
      <p>{name}</p>
      {item?.OrderItem_Notes && (
        <div
          className="has-text-left"
          style={{ fontSize: '0.9rem', textAlign: 'left', fontStyle: 'italic', marginLeft: '20px' }}
        >
          **{item.OrderItem_Notes}
        </div>
      )}
      {addedObjects.map((co, idx) => (
        <p key={idx} style={{ marginLeft: '20px' }}>
          {co?.is_protein ? `PROTEIN - ${co.name}` : `ADD - ${co.name}`}
          {co?.price > 0 ? ` (+${Number(co.price).toFixed(2)})` : null}
        </p>
      ))}
      {unchosenObjects.map((co, idx) => (
        <p key={idx} style={{ marginLeft: '20px' }}>
          NO - {co.name}
        </p>
      ))}
    </>
  );
  if (item.option_logic == '1') {
    label = (
      <>
        <p>{item.OrderItem_ChosenOptions?.[0].name}</p>
        {item?.OrderItem_Notes && (
          <div
            className="has-text-left"
            style={{ fontSize: '0.9rem', textAlign: 'left', fontStyle: 'italic', marginLeft: '20px' }}
          >
            **{item.OrderItem_Notes}
          </div>
        )}
      </>
    );
  }
  return (
    <Styles>
      <Level className={'is-mobile'} style={{ marginBottom: '10px', alignItems: 'baseline' }}>
        <Level.Side
          align="left"
          style={{
            maxWidth: screenType == 'mobile' ? '200px' : '250px',
            textAlign: 'left',
          }}
        >
          <h4>{label}</h4>
        </Level.Side>
        <Level.Side align="right">
          <Grid>
            <Column col={authState.isAuthenticated ? 1 : '1 / 3'} style={{ textAlign: 'right' }}>
              {item.OrderItem_NoCharge ? 'No Charge' : Number(itemPrice).toFixed(2)}
            </Column>
            {authState.isAuthenticated && (
              <Column col={2}>
                <Button
                  onClick={() => handleNoChargeItem(item)}
                  style={{
                    padding: '0',
                    width: '2.5em',
                    height: '2.5em',
                    display: 'flex',
                    margin: '5px',
                  }}
                >
                  <i className="fas fa-dollar-sign" aria-hidden="true"></i>
                </Button>
              </Column>
            )}
            <Column col={3}>
              <Button
                onClick={() => handleDeleteFromCart(item)}
                className="trashButton"
                style={{
                  padding: '0',
                  width: '2.5em',
                  height: '2.5em',
                  display: 'flex',
                  margin: '5px',
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </Button>
            </Column>
          </Grid>
        </Level.Side>
      </Level>
    </Styles>
  );
};

const Headers = function () {
  const [authState] = useContext(AuthContext);

  return (
    <Grid>
      <ColumnHeader col={authState.isAuthenticated ? 1 : '1 / 3'} style={{ textAlign: 'right' }}>
        Price
      </ColumnHeader>
    </Grid>
  );
};

const TotalsLine = function ({ price }) {
  const [authState] = useContext(AuthContext);

  return (
    <Grid>
      <ColumnFooter col={authState.isAuthenticated ? 1 : '1 / 3'} style={{ textAlign: 'right' }}>
        ${Number(price).toFixed(2)}
      </ColumnFooter>
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 45px 45px;
`;
const ColumnFooter = styled.h4`
  margin-top: 1.5rem;
  font-weight: bold;
  text-align: right;
  border-top: 2px solid #4a4a4a;
  grid-column: ${(props) => props.col};
`;
const ColumnHeader = styled.h4`
  font-weight: bold;
  text-align: right;
  border-bottom: 2px solid #4a4a4a;
  grid-column: ${(props) => props.col};
`;
const Column = styled.div`
  align-self: center;
  text-align: right;
  grid-column: ${(props) => props.col};
`;

CartProduct.Headers = Headers;
CartProduct.TotalsLine = TotalsLine;
export default CartProduct;
