import React from 'react';
import styled from 'styled-components';

const Product = ({ option, onClick, parentItem }) => {
  const classNames = option.sold_out ? 'button sold-out' : 'button';
  const price = option?.price || parentItem?.price;
  return (
    <MarginContainer>
      <Container className={classNames} onClick={option.sold_out ? null : onClick}>
        {option?.sold_out ? <div>**sold out</div> : null}
        <Title>{option.name}</Title>
        {price && <p>${Number(price).toFixed(2)}</p>}
      </Container>
    </MarginContainer>
  );
};

export default Product;

const Title = styled.div`
  font-weight: 700 !important;
  user-select: none;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  padding-bottom: 2px;
`;
const Container = styled.div`
  display: block;
  height: auto;
  padding: 20px;
  cursor: pointer;
  margin: 10px 0;
  border-radius: 4px;
`;
const MarginContainer = styled.div`
  flex-shrink: 3;
  text-align: center;
  .sold-out {
    pointer-events: none;
    opacity: 0.4;
  }
`;
