import createContext from '../utils/createContext';
import moment from 'moment';

const midnightTonight = moment().endOf('day');
const currentTime = moment();

const defaultInitialState = {
  itemsInCart: [],
  cartPromo: { promoRate: 1, promoCode: null, promoMessage: null },
  salesTaxRate: process.env.REACT_APP_SALES_TAX_RATE,
  expiration: midnightTonight.toISOString(),
};
const parsedFromStorage = JSON.parse(localStorage.getItem('cartState'));
const parsedExpiration =
  parsedFromStorage && parsedFromStorage?.expiration ? moment(parsedFromStorage.expiration) : null;
const initialState =
  parsedExpiration != null && currentTime.isBefore(parsedExpiration)
    ? parsedFromStorage
    : defaultInitialState;

const sortItems = function (items) {
  return items.sort((a, b) =>
    `${a.name} ${JSON.stringify(a.OrderItem_ChosenOptions)} ${a.uniqueIdent}`.toLowerCase() >
    `${b.name} ${JSON.stringify(b.OrderItem_ChosenOptions)} ${b.uniqueIdent}`.toLowerCase()
      ? 1
      : -1,
  );
};
const addIdentifiersToItems = function (items) {
  items.forEach((i) => (i.variantIdent = `${i.id}-${JSON.stringify(i.OrderItem_ChosenOptions)}`));
  items.forEach((i, index) => (i.uniqueIdent = index));
  return items;
};

const reducer = (state, { type, item, rate, code, message, new_taxrate }) => {
  var newItemsInCart;
  var promoObjNew;
  switch (type) {
    case 'SET_TAX_RATE':
      const stateNew = { ...state, salesTaxRate: new_taxrate };
      localStorage.setItem('cartState', JSON.stringify(stateNew));
      return { ...stateNew };
    case 'SET_CART_PROMO':
      promoObjNew = { promoRate: rate, promoCode: code, promoMessage: message };
      newItemsInCart = state.itemsInCart.slice();
      for (var i of newItemsInCart) {
        i.OrderItem_PromoCode = code;
        i.OrderItem_PromoRate = rate;
        i.promo_price = Number(i.price * rate).toFixed(2);
        for (var io of i.OrderItem_ChosenOptions) {
          io.promo_price = Number(io.price * rate).toFixed(2);
        }
      }
      localStorage.setItem(
        'cartState',
        JSON.stringify({ ...state, itemsInCart: newItemsInCart, cartPromo: promoObjNew }),
      );
      return { ...state, itemsInCart: newItemsInCart, cartPromo: promoObjNew };
    case 'ADD_ITEM_TO_CART':
      newItemsInCart = state.itemsInCart.slice();
      newItemsInCart.push({ ...item });
      newItemsInCart = sortItems(newItemsInCart);
      newItemsInCart = addIdentifiersToItems(newItemsInCart);
      localStorage.setItem('cartState', JSON.stringify({ ...state, itemsInCart: newItemsInCart }));
      // toast.info(`Added to cart`, {
      //   containerId: 'top-center',
      //   autoClose: 1500,
      //   transition: Flip,
      //   hideProgressBar: true,
      // });
      return { ...state, itemsInCart: newItemsInCart };
    case 'UPDATE_ITEM_IN_CART':
      newItemsInCart = state.itemsInCart.slice();
      const index = newItemsInCart.findIndex((i) => i.uniqueIdent == item.uniqueIdent);
      if (index > -1) {
        newItemsInCart.splice(index, 1, item);
        newItemsInCart = sortItems(newItemsInCart);
        newItemsInCart = addIdentifiersToItems(newItemsInCart);
        localStorage.setItem('cartState', JSON.stringify({ ...state, itemsInCart: newItemsInCart }));
      }
      return { ...state, itemsInCart: newItemsInCart };
    case 'DELETE_ITEM_FROM_CART':
      newItemsInCart = state.itemsInCart.slice().filter((i) => i.uniqueIdent !== item.uniqueIdent);
      newItemsInCart = sortItems(newItemsInCart);
      newItemsInCart = addIdentifiersToItems(newItemsInCart);
      localStorage.setItem('cartState', JSON.stringify({ ...state, itemsInCart: newItemsInCart }));
      return { ...state, itemsInCart: newItemsInCart };
    case 'EMPTY_CART':
      localStorage.setItem('cartState', JSON.stringify({ ...defaultInitialState }));
      return { ...defaultInitialState };
    default:
      return state;
  }
};

const [ShoppingCartContext, ShoppingCartProvider] = createContext({
  initialState,
  reducer,
});

export { ShoppingCartContext, ShoppingCartProvider };
