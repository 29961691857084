import React, { useState } from 'react';
import Cookies from 'universal-cookie';

function DeviceConfig() {
  const cookiesHandle = new Cookies();
  const nameEnvModifier = window.location.host.split('.').includes('dev') ? 'dev' : 'prod';
  const cookieName = `device-is-kiosk-${nameEnvModifier}`;

  const initialVal = cookiesHandle.get(cookieName) || null;
  const [stateval, setStateval] = useState(initialVal);

  // const cookieVal = cookiesHandle.get('device-is-kiosk', '1', { domain: domainForCookie });
  function setDeviceAsKiosk() {
    cookiesHandle.set(cookieName, '1', {
      domain: `.outpost-eats.com`,
      path: '/',
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365), //1 year from today
    });
    setStateval(1);
  }

  function clearKioskCookie() {
    cookiesHandle.set(cookieName, 1, {
      domain: `.outpost-eats.com`,
      path: '/',
      expires: new Date(Date.now() - 1), //1 millisecound ago
    });
    setStateval(null);
  }

  return (
    <div style={{ paddingLeft: '80px', marginTop: '20px', textAlign: 'left', width: '700px' }}>
      {stateval == 1 ? (
        <button className="button is-danger" onClick={clearKioskCookie} style={{ width: 'auto', zIndex: 0 }}>
          Mark device as NOT kiosk
        </button>
      ) : (
        <button className="button is-info" onClick={setDeviceAsKiosk} style={{ width: 'auto', zIndex: 0 }}>
          Mark device as kiosk
        </button>
      )}
    </div>
  );
}

export { DeviceConfig };
