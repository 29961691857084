import { AuthContext, GeneralContext } from 'contexts';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CartIconWithBadge } from '../CartIconWithBadge';
import { logout } from 'api/authAPI';
import { useScreenType } from 'utils/useScreenType';
import Cookies from 'universal-cookie';
import { StoreStatusBanner } from 'components/StoreStatusBanner';
import useLongPress from 'utils/useLongPress';
import { socket } from 'socket';
import { PublicStatusBanner } from 'components/PublicStatusBanner';

const Styles = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;

  .navbar {
    padding: 10px;
    ${(props) => (props?.kioskCookie == 1 ? 'zoom: 1.25;' : null)}
    background: #1c1912;
    border-bottom: 0;
    width: 100%;
    left: 0;
    right: 20px;
  }
  .navbar-item {
    background: ${(props) => (props?.isKitchen ? '#1c1912' : 'transparent')};
    border: 2px solid white;
    color: white;
    margin-right: 10px;
    height: 90px;
  }
  a.navbar-item:not(.no-hover):hover {
    background: #292419;
    filter: brightness(1.2);
    border: 2px solid white;
    color: white;
  }
  .navbar-item.active:not(.no-hover):hover {
    background: #292419;
    filter: brightness(1.2);
    border: 2px solid #b38929;
    color: #b38929;
  }
  .navbar-item.active {
    background: ${(props) => (props?.isKitchen ? '#1c1912' : 'transparent')};
    border: 2px solid #b38929;
    color: #b38929;
  }
  .navbar-item:not(.logo-item) {
    padding: 12px 20px;
  }
  .navbar-item.last {
    margin-right: unset;
  }
`;

export const NavigationBar = ({ handleOpenCartSidebar, sidebarProps }) => {
  const [authState, authDispatch] = useContext(AuthContext);
  const [generalState, generalDispatch] = useContext(GeneralContext);
  const history = useHistory();
  const burgerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  const screen = useScreenType();
  const cookiesHandle = new Cookies();
  const cookieNameModifier = window.location.host.split('.').includes('dev') ? 'dev' : 'prod';
  const cookieName = `device-is-kiosk-${cookieNameModifier}`;
  const kioskCookie = cookiesHandle.get(cookieName) || null;
  const { settings, storeOpen } = generalState;

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    if (storeOpen == null) {
      socket.emit('store/are_we_open');
    }
    if (settings == null) {
      socket.emit('store/get_store_settings');
    }
  }, []);

  useEffect(() => {
    const handler1 = (resp) => generalDispatch({ type: 'RECEIVED_STORE_OPENSTATUS', newStoreOpen: resp.open });
    const handler2 = (resp) => generalDispatch({ type: 'RECEIVED_STORE_SETTINGS', newSettings: resp.settings });

    socket.on('store/store_open_status_updated', handler1);
    socket.on('store/store_settings_updated', handler2);
    return () => {
      socket.off('store/store_open_status_updated', handler1);
      socket.off('store/store_settings_updated', handler2);
    };
  }, []);

  function handleMenuIconLongPress() {
    if (kioskCookie == 1) {
      prompt();
    }
  }
  const longPressHandlers = useLongPress(handleMenuIconLongPress, 10000);

  function handleClickOutside(event) {
    const clickIsInDropdown = dropdownRef.current && dropdownRef.current.contains(event.target);
    const clickIsInBurger = burgerRef.current && burgerRef.current.contains(event.target);
    if (!clickIsInDropdown && !clickIsInBurger) {
      setIsMobileDropdownOpen(false);
    }
  }

  function handleMobileCartClick() {
    history.push('/cart');
  }

  async function doLogout() {
    setIsMobileDropdownOpen(false);
    await logout();
    authDispatch({ type: 'RESET_STATE' });
  }

  const MobileDropdownMenuStyles =
    screen == 'tablet' || screen == 'mobile'
      ? styled.div`
          ${screen == 'tablet' ? 'max-width: 400px;' : null}
          width: 100%;
          position: fixed;
          padding: 0;
          top: ${(props) => (props?.kioskCookie == 1 ? '172px;' : '180px;')}
          right: 0;
          .navbar .button:hover {
            border: initial;
            background: initial;
          }
          .button.active {
            border-radius: 0;
            border: 1px solid #ddd;
            border-bottom: 0px;
          }
          .button {
            margin: 0;
            border-radius: 0;
            border: 1px solid #ddd;
            border-bottom: 0px;
          }
        `
      : styled.div``;

  const lastClass = authState.isAuthenticated ? null : 'last ';
  const isKitchen = authState.isAuthenticated;

  if ((screen == 'mobile' || screen == 'tablet') && !authState.isAuthenticated) {
    return (
      <>
        <Styles screen={screen} kioskCookie={kioskCookie}>
          <nav
            className="navbar"
            role="navigation"
            aria-label="main navigation"
            style={{ textAlign: 'left', position: 'sticky' }}
          >
            <div className="navbar-start" style={{ display: 'inline-flex', alignItems: 'center' }}>
              <NavLink
                className={`button navbar-item ${lastClass}`}
                to="/menu"
                {...longPressHandlers}
                style={{
                  border: '0px',
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'start',
                  height: 'fit-content',
                  padding: '5px',
                }}
                onClick={
                  kioskCookie == 1 && window?.location?.pathname?.includes('/menu') //hard reload menu page for kiosk (because sometimes kiosk is asleep and misses websocket changes)
                    ? (e) => {
                        e.preventDefault();
                        window.location.reload();
                      }
                    : null
                }
              >
                <img
                  style={{
                    marginTop: '-5px',
                    height: '75px',
                    maxHeight: '75px',
                    filter: 'brightness(0) invert(1)',
                  }}
                  src="/images/OutpostEatsLogoHorizontal.png"
                />
                <span
                  style={{
                    color: 'white',
                    fontSize: '21px',
                    lineHeight: 1,
                    fontWeight: 'bold',
                    fontFamily: 'Bebas Neue',
                    transform: 'rotateY(20deg)',
                    marginLeft: '-8px',
                    whiteSpace: 'pre',
                    wordWrap: 'normal',
                    textAlign: 'left',
                  }}
                >
                  {settings?.Settings_HoursText}
                </span>
              </NavLink>
            </div>

            <div
              className="navbar-end"
              style={{
                display: 'inline-flex',
                float: 'right',
                marginRight: 0,
                alignItems: 'center',
                height: '100%',
                position: 'absolute',
                right: '10px',
                top: '0',
              }}
            >
              {storeOpen && <CartIconWithBadge isHiddenOnMobileDevice={false} handleClickCallback={handleMobileCartClick} />}
            </div>
          </nav>
          <PublicStatusBanner sidebarProps={sidebarProps} />
        </Styles>
      </>
    );
  }

  return (
    <Styles isKitchen={isKitchen} screen={screen} kioskCookie={kioskCookie}>
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation"
        style={{ textAlign: 'left', position: 'sticky' }}
      >
        <div className="navbar-brand">
          <NavLink
            className={`button navbar-item ${lastClass}`}
            to="/menu"
            onClick={() => setIsMobileDropdownOpen(false)}
            {...longPressHandlers}
            style={{
              border: '0px',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'start',
              height: 'fit-content',
              padding: '5px',
            }}
          >
            <img
              style={{
                marginTop: '-5px',
                height: '75px',
                maxHeight: '75px',
                filter: 'brightness(0) invert(1)',
              }}
              src="/images/OutpostEatsLogoHorizontal.png"
            />
            <span
              style={{
                color: 'white',
                fontSize: '22px',
                lineHeight: 1,
                fontWeight: 'bold',
                fontFamily: 'Bebas Neue',
                transform: 'rotateY(20deg)',
                marginLeft: '-8px',
                whiteSpace: 'pre',
                wordWrap: 'normal',
                textAlign: 'left',
              }}
            >
              {settings?.Settings_HoursText}
            </span>
          </NavLink>
          <a
            role="button"
            ref={burgerRef}
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            style={{ color: 'white' }}
            onClick={() => setIsMobileDropdownOpen(!isMobileDropdownOpen)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <MobileDropdownMenuStyles
          kioskCookie={kioskCookie}
          className={`${isMobileDropdownOpen ? 'is-active' : ''} navbar-menu is-shadowless`}
          style={isMobileDropdownOpen ? { backgroundColor: 'transparent' } : null}
          ref={dropdownRef}
        >
          <div className="navbar-start" style={{ alignItems: 'center' }}>
            {authState?.isAuthenticated && (
              <NavLink className="button navbar-item" to="/order-queue" onClick={() => setIsMobileDropdownOpen(false)}>
                Work Queue
              </NavLink>
            )}
            {authState?.isAuthenticated && (
              <NavLink className="button navbar-item" to="/order-history" onClick={() => setIsMobileDropdownOpen(false)}>
                Order History
              </NavLink>
            )}
            {authState?.isAuthenticated && (
              <NavLink className="button navbar-item" to="/admin" onClick={() => setIsMobileDropdownOpen(false)}>
                Admin
              </NavLink>
            )}
            {authState?.isAuthenticated && (screen == 'mobile' || screen == 'tablet') && (
              <NavLink className="button navbar-item" to="/cart" onClick={() => setIsMobileDropdownOpen(false)}>
                Checkout
              </NavLink>
            )}
          </div>

          <div className="navbar-end" style={{ alignItems: 'center' }}>
            {authState?.isAuthenticated && (
              <div
                onClick={doLogout}
                className="button navbar-item last"
                style={{ marginRight: screen == 'mobile' || screen == 'tablet' ? 0 : '15px' }}
              >
                Logout
              </div>
            )}
            {storeOpen && (
              <CartIconWithBadge
                isHiddenOnMobileDevice={true}
                handleClickCallback={() => handleOpenCartSidebar()}
                style={{ marginRight: '7px' }}
              />
            )}
          </div>
        </MobileDropdownMenuStyles>
      </nav>
      {isKitchen ? <StoreStatusBanner /> : <PublicStatusBanner sidebarProps={sidebarProps} />}
    </Styles>
  );
};
