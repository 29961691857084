const CHECKOUT_TYPES = {
  CASH: 'CASH',
  CREDIT: 'CREDIT',
  MANUAL_CREDIT: 'MANUAL_CREDIT',
};

const ORDER_STATUS = {
  NEW: 'NEW',
  DONE: 'DONE',
  CANCELLED: 'CANCELLED',
  IN_PROGRESS: 'IN PROGRESS',
};

const NO_CHARGE_ORDER = 'NO_CHARGE_ORDER';

export { CHECKOUT_TYPES, ORDER_STATUS, NO_CHARGE_ORDER };
