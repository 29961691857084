import createContext from 'utils/createContext';
import { socket } from '../socket';

function windowFocusInCallback() {
  const isIOS =
    (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;
  if (isIOS) {
    socket.emit('store/are_we_open');
    socket.emit('store/get_store_settings');
  }
}

const initialState = {
  settings: null,
  storeOpen: null,
  windowFocusInCallback,
};

const reducer = (state, { type, newSettings, newStoreOpen }) => {
  switch (type) {
    case 'RECEIVED_STORE_SETTINGS':
      return {
        ...state,
        settings: { ...newSettings },
      };
    case 'RECEIVED_STORE_OPENSTATUS':
      return {
        ...state,
        storeOpen: newStoreOpen,
      };
    case 'RESET_STATE':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const [GeneralContext, GeneralProvider] = createContext({ initialState, reducer });

export { GeneralContext, GeneralProvider };
