import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { socket } from 'socket';

const CheckBoxStyles = styled.div`
  input.checkbox {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .label {
    display: inline-block;
  }
`;

const ErrorStyles = styled.div`
  .has-text-danger {
    white-space: nowrap;
    display: block;
  }
`;

function ManageCouponCodes() {
  const [errors, setErrors] = useState({});
  const [codes, setCodes] = useState([]);

  const handlePromoCodesListResp = (resp) => {
    const { promoCodes } = resp;
    setCodes(promoCodes);
  };

  useEffect(() => {
    socket.on('promo/updated_promocodes_list', handlePromoCodesListResp);
    socket.emit('promo/list_promo_codes', {});
    return () => {
      socket.off('promo/updated_promocodes_list', handlePromoCodesListResp);
    };
  }, []);

  function handleFieldChange(fieldname, value, pcode) {
    const newErrorObj = { ...errors };
    if (fieldname == 'PromoCode_RateAfterDiscount') {
      if (isNaN(+value) || +value < 0 || +value >= 1) {
        newErrorObj.PromoCode_RateAfterDiscount = 'Please enter a numeric value between 0 and 1.';
      } else {
        value = Number(value).toFixed(2);
        delete newErrorObj.PromoCode_RateAfterDiscount;
      }
    }
    if (Object.keys(newErrorObj).length > 0) {
      setErrors(newErrorObj);
    } else {
      const newObj = { ...pcode };
      newObj[fieldname] = value;
      socket.emit('promo/edit_promo_code', newObj);
    }
  }

  function handleNewRow() {
    socket.emit('promo/create_promo_code', {});
  }

  function handleDeleteRow(pcode) {
    socket.emit('promo/delete_promo_code', pcode);
  }

  return (
    <ErrorStyles>
      <CheckBoxStyles>
        <div style={{ paddingLeft: '80px', minWidth: '900px' }}>
          <table className="table">
            <thead>
              <tr>
                <th>
                  <label className="label">Promo Code</label>
                  {errors.PromoCode_Code && <span className="has-text-danger is-size-7">{errors.PromoCode_Code}</span>}
                </th>
                <th>
                  <label className="label">Discounted Rate</label>
                  {errors.PromoCode_RateAfterDiscount && (
                    <span className="has-text-danger is-size-7">{errors.PromoCode_RateAfterDiscount}</span>
                  )}
                </th>
                <th>
                  <label className="label">Expiration Date?</label>
                  {errors.PromoCode_Expiration && (
                    <span className="has-text-danger is-size-7">{errors.PromoCode_Expiration}</span>
                  )}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {codes.map((pcode, index) => {
                const expDate = pcode?.PromoCode_Expiration
                  ? new Date(pcode.PromoCode_Expiration.replace(/Z$/, '')).toLocaleDateString()
                  : null;
                return (
                  <tr key={pcode.PromoCode_ID}>
                    <td>
                      <input
                        defaultValue={pcode.PromoCode_Code}
                        placeholder={`new_promo_code`}
                        className="input"
                        onBlur={(e) => handleFieldChange('PromoCode_Code', e.target.value, pcode)}
                      />
                    </td>
                    <td>
                      <input
                        defaultValue={pcode.PromoCode_RateAfterDiscount}
                        className="input"
                        onBlur={(e) => handleFieldChange('PromoCode_RateAfterDiscount', e.target.value, pcode)}
                      />
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <input
                        defaultValue={expDate}
                        className="input"
                        onBlur={(e) => handleFieldChange('PromoCode_Expiration', e.target.value, pcode)}
                      />
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <button
                        style={{ padding: '16px', margin: '0' }}
                        className="delete"
                        onClick={() => handleDeleteRow(pcode)}
                      ></button>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>
                  <button
                    style={{ float: 'left', width: '40px', height: '40px', padding: '10px', color: '#555' }}
                    className="button"
                    onClick={() => handleNewRow()}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CheckBoxStyles>
    </ErrorStyles>
  );
}

export { ManageCouponCodes };
