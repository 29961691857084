import React, { useState, useEffect } from 'react';
import { ORDER_STATUS } from 'utils/constants';
import { socket } from 'socket';

const roundToNearest5 = (x) => Math.round(x / 5) * 5;

export function EstimatedWaitTime({ orderId }) {
  const [queue, setQueue] = useState(null);

  const getData = (ordersArray) => {
    let queueArray = ordersArray
      ?.filter((o) => o.order_status == ORDER_STATUS.NEW || o.order_status == ORDER_STATUS.IN_PROGRESS)
      ?.sort(function (a, b) {
        return a.id - b.id;
      });

    setQueue(queueArray);
  };

  const requeryInitialData = () => {
    socket.emit('orders/initial_data');
  };

  useEffect(() => {
    socket.on('orders/get_data', getData);
    socket.on('orders/requery_initial_data', requeryInitialData);
    socket.emit('orders/initial_data');
    return () => {
      socket.off('orders/get_data', getData);
      socket.off('orders/requery_initial_data', requeryInitialData);
    };
  }, []);

  if (!queue) {
    return null;
  }

  let itemsAhead = queue.slice();
  let index = -1;
  if (orderId) {
    index = queue.findIndex((o) => o.id == orderId);
    itemsAhead = queue.slice(index);
  }
  const estTime = itemsAhead.reduce((sum, o) => {
    const estimatedOrderTime = Math.max.apply(
      Math,
      o.items.map(function (i) {
        return Number(i.estimated_prep_time_minutes || 0);
      }),
    );
    return sum + estimatedOrderTime;
  }, 0);
  return {
    time: roundToNearest5(estTime),
    position: index,
    done: orderId && index < 0,
  };
}
