import React, { createContext, useReducer, useCallback } from 'react';

export default ({ reducer, initialState }) => {
  const Context = createContext(null);
  function Provider({ children }) {
    const [state, dispatch] = useReducer(useCallback(reducer, []), initialState);
    return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
  }
  return [Context, Provider];
};
