import createContext from 'utils/createContext';

const initialState = {
  loginError: null,
  isAuthenticated: false,
  isAuthenticating: false,
  attemptAutoAuthenticate: true,
  user: null,
};

const reducer = (state, { type, user, errorcode, wasAutoAuthAttempt }) => {
  switch (type) {
    case 'AUTH_REQUESTED':
      return {
        ...state,
        isAuthenticating: true,
        loginError: null,
      };
    case 'AUTH_COMPLETED':
      return {
        ...state,
        isAuthenticating: false,
        attemptAutoAuthenticate: false,
        isAuthenticated: true,
        user: user,
      };
    case 'AUTH_FAILED':
      return {
        ...state,
        isAuthenticating: false,
        attemptAutoAuthenticate: false,
        isAuthenticated: false,
        loginError: wasAutoAuthAttempt ? null : errorcode,
      };
    case 'RESET_STATE':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const [AuthContext, AuthProvider] = createContext({ initialState, reducer });

export { AuthContext, AuthProvider };
