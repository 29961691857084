import { useEffect, useState } from 'react';

function useWindowFocus({ focusIn, focusOut }) {
  const [isFocused, setIsFocused] = useState(true);

  useEffect(() => {
    const handleActivityFalse = () => {
      setIsFocused(false);
      if (focusOut) {
        focusOut();
      }
    };

    const handleActivityTrue = () => {
      setIsFocused(true);
      if (focusIn) {
        focusIn();
      }
    };

    window.addEventListener('focus', handleActivityTrue);
    window.addEventListener('blur', handleActivityFalse);

    return () => {
      window.removeEventListener('focus', handleActivityTrue);
      window.removeEventListener('blur', handleActivityFalse);
    };
  }, [isFocused]);

  return { isFocused };
}

export { useWindowFocus };
