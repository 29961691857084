import React, { useEffect } from 'react';
import socketIOClient from 'socket.io-client';

var socket;
const SocketSetup = () => {
  if (!socket) {
    socket = socketIOClient(process.env.REACT_APP_POS_SERVICE_URL);
  }
  useEffect(() => {
    socket.on('connect', () => {
      console.log('connected');
    });
  }, []);

  return <>{null}</>;
};

export { SocketSetup, socket };
