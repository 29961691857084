import axios from 'axios'; //axios is for communicating with anything else
import http from '../utils/http'; //http util is for communicating with nodeJS backend
//import { createNewOrder } from './ordersAPI';
import moment from 'moment';

const {
  REACT_APP_ELAVON_DIP_READER_PIN,
  REACT_APP_ELAVON_USER_ID,
  REACT_APP_ELAVON_VENDOR_ID,
  REACT_APP_ELAVON_MERCHANT_ID,
  REACT_APP_ELAVON_IS_DEV,
  REACT_APP_POS_SERVICE_URL,
  REACT_APP_ELAVON_BMSUSER,
  REACT_APP_ELAVON_BMSPASS,
} = process.env;

const dipReaderCwsApiUrl = 'https://localhost:9790/rest/command';

//TODO TODO TODO:
//TODO TODO TODO:
//----------
//cancel card-present
//refund ecomm
//refund card-present
//prettier-ignore

async function refundPayment(payload) {
  const refundResp = await http.post(`${REACT_APP_POS_SERVICE_URL}/elavon/refund-payment`, payload);

  const returnObject = {
    data: {
      ...refundResp?.data,
      processedSuccessfully: refundResp?.data?.ssl_result == 0 ? true : false,
    },
  };

  if (!returnObject.data.processedSuccessfully) {
    let errorDetail = null;
    if (returnObject?.data?.errorMessage) {
      errorDetail = returnObject?.data?.errorMessage;
    }
    if (returnObject?.data?.ssl_result_message && returnObject?.data?.ssl_result_message != 'APPROVAL') {
      errorDetail = returnObject?.data?.ssl_result_message;
    }
    if (!errorDetail) {
      errorDetail = 'Refund failed.';
    }
    throw new Error(errorDetail);
  }
  return returnObject;
}
async function voidTransaction(payload) {
  const voidResp = await http.post(`${REACT_APP_POS_SERVICE_URL}/elavon/void-transaction`, payload);

  const returnObject = {
    data: {
      ...voidResp?.data,
      processedSuccessfully: voidResp?.data?.ssl_result == 0 ? true : false,
    },
  };

  if (!returnObject.data.processedSuccessfully) {
    let errorDetail = null;
    if (returnObject?.data?.errorMessage) {
      errorDetail = returnObject?.data?.errorMessage;
    }
    if (returnObject?.data?.ssl_result_message && returnObject?.data?.ssl_result_message != 'APPROVAL') {
      errorDetail = returnObject?.data?.ssl_result_message;
    }
    if (!errorDetail) {
      errorDetail = 'Void failed.';
    }
    throw new Error(errorDetail);
  }
  return returnObject;
}

async function startTransactionCardPresent(args) {
  const { elavonAMT, elavonCurrency, elavonInvoiceNum, elavonTaxAMT, elavonCustID } = args;

  const parsedFromStorage = JSON.parse(sessionStorage.getItem('dipReaderGatewayHash'));
  let dipReaderGatewayHash = parsedFromStorage?.value;
  const dipReaderGatewayExpiration = parsedFromStorage?.expiration
    ? moment(parsedFromStorage?.expiration)
    : moment().subtract(1, 'hours');
  const currentTime = moment();

  if (!dipReaderGatewayHash || currentTime.isSameOrAfter(dipReaderGatewayExpiration)) {
    console.log('opening payment gateway... - ' + Date.now());
    const reqBodyGateway = {
      method: 'openPaymentGateway',
      requestId: Date.now(),
      targetType: 'paymentGatewayConverge',
      version: '1.0',
      parameters: {
        paymentGatewayEnvironment: REACT_APP_ELAVON_IS_DEV == 1 ? 'DEMO' : 'PROD',
        pin: REACT_APP_ELAVON_DIP_READER_PIN,
        userId: REACT_APP_ELAVON_USER_ID,
        vendorId: REACT_APP_ELAVON_VENDOR_ID,
        merchantId: REACT_APP_ELAVON_MERCHANT_ID,
        bmsUsername: REACT_APP_ELAVON_BMSUSER,
        bmsPassword: REACT_APP_ELAVON_BMSPASS,
        app: 'VMM',
        handleDigitalSignature: false,
        email: 'joel@transitionbikes.com',
        vendorAppName: 'Transition Bikes',
        vendorAppVersion: '1.0.0',
        overrideDebitNetworkPreferences: {
          visa: 'GLOBAL_NETWORK',
          mastercard: 'GLOBAL_NETWORK',
          discover: 'GLOBAL_NETWORK',
        },
      },
    };

    const resp = await axios.post(dipReaderCwsApiUrl, reqBodyGateway);
    const midnightTonight = moment().endOf('day');
    dipReaderGatewayHash = resp?.data?.data?.paymentGatewayCommand?.openPaymentGatewayData?.paymentGatewayId;
    sessionStorage.setItem(
      'dipReaderGatewayHash',
      JSON.stringify({
        value: dipReaderGatewayHash,
        expiration: midnightTonight.toISOString(),
      }),
    );
  }

  const reqBodyStartTransaction = {
    method: 'startPaymentTransaction',
    requestId: Date.now(),
    targetType: 'paymentGatewayConverge',
    version: '1.0',
    parameters: {
      paymentGatewayId: dipReaderGatewayHash,
      transactionType: 'SALE',
      baseTransactionAmount: {
        value: elavonAMT,
        currencyCode: elavonCurrency,
      },
      tenderType: 'CARD',
      cardType: 'CREDIT',
      signatureOption: 'DISABLED',
      invoiceNumber: elavonInvoiceNum,
      partialApprovalAllowed: false,
      isTaxInclusive: elavonTaxAMT > 0 ? true : false,
      customerCode: elavonCustID,
      discountAmounts: null,
      taxAmounts: [
        {
          value: elavonTaxAMT,
          currencyCode: elavonCurrency,
        },
      ],
    },
  };
  const startResp = await axios.post(dipReaderCwsApiUrl, reqBodyStartTransaction);
  const dipReaderSaleIdentifier = startResp?.data?.data?.paymentGatewayCommand?.chanId;

  return { dipReaderSaleIdentifier, dipReaderGatewayHash };
}

//prettier-ignore
async function checkStatusCardPresent(args) {
  const { dipReaderSaleIdentifier, dipReaderGatewayHash, preTax, taxRate, tip, order } = args;
  const reqBodyCheckStatus = {
    method: 'getPaymentTransactionStatus',
    requestId: Date.now(),
    targetType: 'paymentGatewayConverge',
    version: '1.0',
    parameters: {
      paymentGatewayId: dipReaderGatewayHash,
      chanId: dipReaderSaleIdentifier,
    },
  };
  const respGetStatus = await axios.post(dipReaderCwsApiUrl, reqBodyCheckStatus);
  const returnVal = { data: respGetStatus?.data?.data };
  if (
    respGetStatus?.data?.data?.paymentGatewayCommand?.completed &&
    respGetStatus?.data?.data?.paymentGatewayCommand?.paymentTransactionData && 
    respGetStatus?.data?.data?.paymentGatewayCommand?.paymentTransactionData?.result == 'APPROVED'
  ) {
    const elavonTransID = respGetStatus?.data?.data?.paymentGatewayCommand?.paymentTransactionData?.id;
    const otherData = {
      preTax: preTax,
      taxRate: taxRate,
      tip: tip,
      elavonTerminal: 'Retail',
    };
    const elavonData = {
      ssl_result: respGetStatus?.data?.data?.paymentGatewayCommand?.paymentTransactionData?.result == 'APPROVED' ? "0" : "1",
      ssl_salestax: respGetStatus?.data?.data?.paymentGatewayCommand?.paymentTransactionData?.tax?.value/100,
      ssl_txn_id: respGetStatus?.data?.data?.paymentGatewayCommand?.paymentTransactionData?.id,
      ssl_amount: respGetStatus?.data?.data?.paymentGatewayCommand?.paymentTransactionData?.amountAuthorized?.value/100
    }
    const payload = { elavonData, otherData, order, elavon_transaction_id: elavonTransID };
    const markOrderPaidResult = await http.post(`${REACT_APP_POS_SERVICE_URL}/orders/markpaid-cardpresent-order`, payload);
    returnVal.data.order = markOrderPaidResult.data.order;
  }
  return returnVal;
}

export { startTransactionCardPresent, checkStatusCardPresent, refundPayment, voidTransaction };
