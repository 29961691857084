import React from 'react';

function ToggleButton(props) {
  const { isActive, controlClassName, children, onSelected, label, className, onClick } = props;

  const onButtonClick = () => {
    if (onClick) {
      onClick();
    }
    onSelected(label);
  };

  const zIndex = isActive ? { zIndex: 3 } : { zIndex: 0 };

  return (
    <div className={`control toggle-button ${controlClassName}`} style={zIndex}>
      <button
        type="button"
        style={{ marginTop: '5px' }}
        className={`button ${isActive ? 'active-toggle-option' : ''} ${className || ''}`}
        onClick={() => onButtonClick()}
      >
        {children}
      </button>
    </div>
  );
}

export default ToggleButton;
