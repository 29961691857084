import React, { useState, useContext, useEffect } from 'react';
import { OrderTicket } from 'components/OrderTicket';
import { ORDER_STATUS } from 'utils/constants';
import { socket } from '../socket';
import styled from 'styled-components';
import _ from 'lodash';
import { PageLoadSpinner } from 'components/_shared/PageLoadSpinner';

const StylesContainer = styled.div`
  display: flex;
  align-items: start;
  width: 100vw - 60px;
  margin: 0 30px;
  overflow-x: auto;
  min-height: 750px;
`;

const TicketStyles = styled.div`
  margin: 20px;
`;

const OrderCount = styled.div`
  user-select: none;
  pointer-events: none;
  position: absolute;
  font-size: 90px;
  font-weight: bold;
  top: 80px;
  right: 30px;
  z-index: 10;
`;

export const OrdersActiveQueue = () => {
  const [orders, setOrders] = useState([]);
  const [isWaitingForInitialData, setIsWaitingForInitialData] = useState(true);

  const getData = (newOrdersArray) => {
    newOrdersArray = newOrdersArray?.sort(function (a, b) {
      return new Date(a.inserted_at) - new Date(b.inserted_at);
    });
    newOrdersArray = newOrdersArray.filter(
      (o) => o.order_status == ORDER_STATUS.NEW || o.order_status == ORDER_STATUS.IN_PROGRESS,
    );
    setIsWaitingForInitialData(false);
    setOrders(newOrdersArray);
  };

  const requeryInitialData = () => {
    socket.emit('orders/initial_data');
  };

  useEffect(() => {
    socket.on('orders/get_data', getData);
    socket.on('orders/requery_initial_data', requeryInitialData);
    socket.emit('orders/initial_data');
    return () => {
      socket.off('orders/get_data', getData);
      socket.off('orders/requery_initial_data', requeryInitialData);
    };
  }, []);

  //start - this useeffect may or may not actually be used / needed
  //start - this useeffect may or may not actually be used / needed
  useEffect(() => {
    socket.on('orders/get_data', getData);
    socket.on('orders/requery_initial_data', requeryInitialData);
    socket.emit('orders/initial_data');
    return () => {
      socket.off('orders/get_data', getData);
      socket.off('orders/requery_initial_data', requeryInitialData);
    };
  }, []);
  //end - this useeffect may or may not actually be used / needed
  //end - this useeffect may or may not actually be used / needed

  if (isWaitingForInitialData) {
    return <PageLoadSpinner />;
  }

  if (!orders?.length) {
    return <p>Queue is empty.</p>;
  }

  return (
    <StylesContainer>
      <OrderCount>{orders?.length}</OrderCount>
      {orders.map((o) => (
        <TicketStyles key={o.id}>
          <OrderTicket orderInitial={o} />
        </TicketStyles>
      ))}
    </StylesContainer>
  );
};
