import http from '../utils/http';
import { toJpeg } from 'html-to-image';

async function printReceipt(htmlElement) {
  if (!htmlElement) {
    return;
  }

  const { receiptJpeg } = await htmlToImage(htmlElement);
  const payload = {
    receiptJpeg,
    printerName: 'epson-receipt-1',
  };

  const resp = await http.post(`https://printservice.local:3002/print`, payload);
  return resp;
}

async function htmlToImage(htmlElement) {
  if (!htmlElement) {
    return;
  }

  const width = htmlElement.scrollWidth * 4;
  const height = htmlElement.scrollHeight * 4;

  const receiptJpeg = await toJpeg(htmlElement, {
    quality: 1,
    backgroundColor: '#ffffff',
    canvasWidth: width,
    canvasHeight: height,
    style: { visibility: 'visible', height: 'auto' },
  });

  return {
    receiptJpeg,
    width,
    height,
  };
}

function printReceiptFromImage({ receiptJpeg, width, height }) {
  if (!receiptJpeg || !width || !height) {
    return;
  }

  const payload = {
    receiptJpeg,
    width,
    height,
    printerName: Date.now() % 2 == 0 ? 'epson_t88v_1' : 'epson_t20iii_1',
  };

  return http.post(`https://printserver.local:3002/print`, payload);
}

export { printReceipt, printReceiptFromImage, htmlToImage };

// const elavonPayload = {
//   ssl_first_name: 'John',
//   ssl_last_name: 'Smith',
//   ssl_amount: '25.00',
//   ssl_avs_address: '123 Main',
//   ssl_avs_zip: '37920',
// };
// const elavonResp = await http.post(
//   `${process.env.REACT_APP_POS_SERVICE_URL}/elavon/get-transaction-token`,
//   elavonPayload,
// );
// return elavonResp;
