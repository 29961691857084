import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ItemOptionSelect } from 'components/_shared/ItemOptionSelect';
import { MenuGroupingSelect } from 'components/_shared/MenuGroupingSelect';
import Select from 'react-select';
import { createItem } from 'api/menuAPI';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { toBase64 } from 'utils/general';

const CheckBoxStyles = styled.div`
  input.checkbox {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    vertical-align: middle;
    margin-bottom: 6px;
  }
  .label {
    display: inline-block;
  }
`;

const SelectStyles = styled.div`
  input {
    height: auto;
    box-shadow: none !important;
  }
`;

const ErrorStyles = styled.div`
  .react-select__control {
    padding: 1px;
  }
  .textarea {
    min-height: 80px;
  }
  .has-text-danger {
    white-space: nowrap;
  }
`;

const ItemOptionLogicValues = [
  { value: 'N', label: 'N' },
  { value: '1', label: '1' },
];

const initialItem = {
  name: null,
  description: null,
  price: null,
  cost: null,
  available_options: [],
  default_options: [],
  gluten_free: false,
  vegetarian: false,
  vegan: false,
  active: true,
  sold_out: false,
  option_logic: ItemOptionLogicValues[0],
  menu_grouping: null,
  file: null,
  image_src_base64: null,
  image_filename_extension: null,
};

function AddNewItem() {
  const [newItem, setNewItem] = useState(initialItem);
  const [errors, setErrors] = useState({});
  const { available_options, default_options } = newItem;
  const history = useHistory();

  function handleFieldChange(fieldname, value) {
    if ((fieldname == 'price' || fieldname == 'cost') && value.startsWith('$')) {
      value = value.slice(1);
    }
    if (fieldname == 'price' || fieldname == 'cost') {
      value = Number(value).toString();
    }
    setNewItem({ ...newItem, [fieldname]: value });
  }

  //if there are errors on the screen and our newItem object changes, recalculate errors
  useEffect(() => {
    if (Object.keys(errors).length) {
      testForErrors();
    }
  }, [newItem]);

  //if an item is removed from the available array, and it's in the defaults, remove it from the defaults array
  useEffect(() => {
    let needsUpdate = false;
    let newDefaultsArr = [...default_options];
    for (var o of default_options) {
      if (!available_options.find((ao) => ao.value == o.value)) {
        newDefaultsArr = newDefaultsArr.filter((d) => d.value != o.value);
        needsUpdate = true;
      }
    }
    if (needsUpdate) {
      setNewItem({ ...newItem, default_options: newDefaultsArr });
    }
  }, [available_options]);

  //if an item is added as a default but isnt available, add it to available array
  useEffect(() => {
    let needsUpdate = false;
    let newAvailableArray = [...available_options];
    for (var o of default_options) {
      if (!available_options.find((ao) => ao.value == o.value)) {
        newAvailableArray.push(o);
        needsUpdate = true;
      }
    }
    if (needsUpdate) {
      setNewItem({ ...newItem, available_options: newAvailableArray });
    }
  }, [default_options]);

  async function handleSelectFile(event) {
    const allowedFileTypes = ['jpg', 'jpeg', 'png', 'gif'];
    if (allowedFileTypes.includes(event?.target?.files?.[0]?.name?.split('.')?.pop()?.toLowerCase())) {
      setNewItem({
        ...newItem,
        file: event.target.files[0],
        image_src_base64: await toBase64(event.target.files[0]),
        image_filename_extension: '.' + event.target.files[0].name.split('.').pop()?.toLowerCase(),
      });
    }
  }
  function handleRemoveImage() {
    setNewItem({
      ...newItem,
      file: null,
      image_src_base64: null,
      image_filename_extension: null,
    });
  }

  function testForErrors() {
    const newErrorsObj = {};
    const estTimeMins = newItem.estimated_prep_time_minutes;
    if (!newItem.name) newErrorsObj.name = 'Required';
    if (!newItem?.Item_SortIndex) newErrorsObj.Item_SortIndex = 'Required';
    if (newItem.price && isNaN(newItem.price?.trim())) {
      newErrorsObj.price = 'Must be numeric';
    }
    if (newItem?.Item_SortIndex && isNaN(newItem?.Item_SortIndex?.trim())) {
      newErrorsObj.Item_SortIndex = 'Must be numeric';
    }
    if (newItem.cost && isNaN(newItem.cost?.trim())) {
      newErrorsObj.cost = 'Must be numeric';
    }
    if (estTimeMins == null || estTimeMins?.toString().trim() == '') {
      newErrorsObj.estimated_prep_time_minutes = 'Required';
    } else if (isNaN(estTimeMins?.toString().trim())) {
      newErrorsObj.estimated_prep_time_minutes = 'Must be numeric';
    }
    if (!newItem.menu_grouping) newErrorsObj.menu_grouping = 'Required';
    if (!newItem.option_logic) newErrorsObj.option_logic = 'Required';
    if (newItem.option_logic.value == '1' && !newItem.available_options.length) {
      newErrorsObj.available_options = "At least 1 option is required when option logic is '1'";
    }
    setErrors(newErrorsObj);
    if (Object.keys(newErrorsObj).length) {
      return true;
    }
    return false;
  }

  async function submitForm() {
    const hasErrors = testForErrors();
    if (hasErrors) {
      return;
    } else {
      const estPrepMins = newItem.estimated_prep_time_minutes;
      const mappedItemForSubmit = {
        ...newItem,
        price: newItem.price > 0 ? Number(newItem.price).toFixed(2) : null,
        cost: newItem.cost > 0 ? Number(newItem.cost).toFixed(2) : null,
        estimated_prep_time_minutes: Number(estPrepMins) > 0 ? Math.round(estPrepMins) : 0,
        option_logic: newItem.option_logic.value,
        menu_grouping: newItem.menu_grouping.value,
        default_options: newItem.default_options.map((o) => o.value),
        available_options: newItem.available_options.map((o) => o.value),
      };

      await createItem({ item: mappedItemForSubmit });
      toast.info(`${mappedItemForSubmit.name} added successfully`, {
        containerId: 'top-center',
        autoClose: 2500,
      });
      history.push('/admin');
    }
  }

  return (
    <ErrorStyles>
      <div style={{ paddingLeft: '80px', minWidth: '900px' }} className="columns">
        <div className="column column1">
          <div className="field has-text-left" style={{ maxWidth: '385px' }}>
            <label className="label">Image</label>
            <div className="file has-name">
              <label className="file-label">
                <input className="file-input" type="file" name="resume" onChange={handleSelectFile} />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label" style={{ fontSize: '15px' }}>
                    Choose file (.jpg, .png, .gif)
                  </span>
                </span>
                <span className="file-name">{newItem?.file?.name}</span>
              </label>
            </div>
            {newItem?.image_src_base64 ? (
              <>
                <img src={newItem?.image_src_base64} style={{ marginTop: '15px', height: '100px' }} />
                <i
                  className="far fa-trash-alt"
                  aria-hidden="true"
                  onClick={handleRemoveImage}
                  style={{ verticalAlign: 'top', marginTop: '15px', marginLeft: '5px', cursor: 'pointer' }}
                />
              </>
            ) : (
              <p style={{ marginTop: '15px', fontStyle: 'italic' }}>**No Image**</p>
            )}
          </div>
          <div className="field has-text-left">
            <label className="label">Name</label>
            <div className="control">
              <input
                placeholder="Bulgogi"
                className="input"
                onChange={(e) => handleFieldChange('name', e.target.value)}
              />
              {errors.name && <span className="has-text-danger is-size-7">{errors.name}</span>}
            </div>
          </div>
          <div className="field has-text-left">
            <label className="label">Sort Order</label>
            <div className="control">
              <input
                className="input"
                value={newItem?.Item_SortIndex || ''}
                onChange={(e) => handleFieldChange('Item_SortIndex', e?.target?.value)}
              />
              {errors?.Item_SortIndex && (
                <span className="has-text-danger is-size-7">{errors?.Item_SortIndex}</span>
              )}
            </div>
          </div>
          <div className="field has-text-left">
            <div className="field has-text-left" style={{ display: 'inline-grid', maxWidth: '120px' }}>
              <label className="label">Price</label>
              <div className="control">
                <input
                  placeholder="$10.00"
                  className="input"
                  onChange={(e) => handleFieldChange('price', e.target.value)}
                />
                {errors.price && <span className="has-text-danger is-size-7">{errors.price}</span>}
              </div>
            </div>
            <div
              className="field has-text-left"
              style={{ display: 'inline-grid', maxWidth: '120px', marginLeft: '24px' }}
            >
              <label className="label">Cost</label>
              <div className="control">
                <input
                  placeholder="$5.00"
                  className="input"
                  onChange={(e) => handleFieldChange('cost', e.target.value)}
                />
                {errors.cost && <span className="has-text-danger is-size-7">{errors.cost}</span>}
              </div>
            </div>
            <div
              className="field has-text-left"
              style={{ display: 'inline-grid', maxWidth: '100px', marginLeft: '24px' }}
            >
              <label className="label">Option Logic</label>
              <SelectStyles>
                <Select
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                  value={newItem?.option_logic}
                  options={ItemOptionLogicValues}
                  onChange={(val) => handleFieldChange('option_logic', val)}
                />
              </SelectStyles>
              {errors.option_logic && (
                <span className="has-text-danger is-size-7">{errors.option_logic}</span>
              )}
            </div>
          </div>
          <div className="field has-text-left">
            <label className="label">Menu Grouping</label>
            <div className="control">
              <MenuGroupingSelect
                handleChange={(val) => handleFieldChange('menu_grouping', val)}
                value={newItem.menu_grouping}
              />
              {errors.menu_grouping && (
                <span className="has-text-danger is-size-7">{errors.menu_grouping}</span>
              )}
            </div>
          </div>
          <div className="field has-text-left" style={{ width: '170px' }}>
            <label className="label">Est Prep Time (mins)</label>
            <input
              placeholder="..."
              className="input"
              onChange={(e) => handleFieldChange('estimated_prep_time_minutes', e.target.value)}
            />
            {errors.estimated_prep_time_minutes && (
              <span className="has-text-danger is-size-7">{errors.estimated_prep_time_minutes}</span>
            )}
          </div>
          <div className="field has-text-left">
            <label className="label">Description</label>
            <div className="control">
              <textarea
                style={{ verticalAlign: 'top' }}
                placeholder="A sweet and savory koren beef dish"
                className="textarea"
                onChange={(e) => handleFieldChange('description', e.target.value)}
              />
              {errors.description && <span className="has-text-danger is-size-7">{errors.description}</span>}
            </div>
          </div>
          <div className="field has-text-left">
            <label className="label">Recipe</label>
            <div className="control">
              <textarea
                style={{ verticalAlign: 'top' }}
                placeholder="Recipe goes here..."
                className="textarea"
                onChange={(e) => handleFieldChange('recipe_text', e.target.value)}
              />
              {errors.recipe && <span className="has-text-danger is-size-7">{errors.recipe}</span>}
            </div>
          </div>
        </div>
        <div className="column column2">
          <div className="field has-text-left">
            <label className="label">Available Options</label>
            <div className="control">
              <ItemOptionSelect
                handleChange={(arr) => handleFieldChange('available_options', arr)}
                value={newItem.available_options}
              />
              {errors.available_options && (
                <span className="has-text-danger is-size-7">{errors.available_options}</span>
              )}
            </div>
          </div>
          <div className="field has-text-left">
            <label className="label">Default Options</label>
            <div className="control">
              <ItemOptionSelect
                handleChange={(arr) => handleFieldChange('default_options', arr)}
                value={newItem.default_options}
              />
              {errors.default_options && (
                <span className="has-text-danger is-size-7">{errors.default_options}</span>
              )}
            </div>
          </div>
          <CheckBoxStyles>
            <div className="columns">
              <div className="column">
                <div className="field has-text-left">
                  <label className="label">
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={newItem.gluten_free}
                      onChange={() => handleFieldChange('gluten_free', !newItem.gluten_free)}
                    />
                    <span>Gluten Free?</span>
                  </label>
                  {errors.gluten_free && (
                    <span className="has-text-danger is-size-7">{errors.gluten_free}</span>
                  )}
                </div>
                <div className="field has-text-left">
                  <label className="label">
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={newItem.vegan}
                      onChange={() => handleFieldChange('vegan', !newItem.vegan)}
                    />
                    <span>Vegan?</span>
                  </label>
                  {errors.vegan && <span className="has-text-danger is-size-7">{errors.vegan}</span>}
                </div>
                <div className="field has-text-left">
                  <label className="label">
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={newItem.vegetarian}
                      onChange={() => handleFieldChange('vegetarian', !newItem.vegetarian)}
                    />
                    <span>Vegetarian?</span>
                  </label>
                  {errors.vegetarian && (
                    <span className="has-text-danger is-size-7">{errors.vegetarian}</span>
                  )}
                </div>
              </div>
              <div className="column">
                <div className="field has-text-left">
                  <label className="label">
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={newItem.active}
                      onChange={() => handleFieldChange('active', !newItem.active)}
                    />
                    <span>Is Active?</span>
                  </label>
                  {errors.active && <span className="has-text-danger is-size-7">{errors.active}</span>}
                </div>
                <div className="field has-text-left">
                  <label className="label">
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={newItem.sold_out}
                      onChange={() => handleFieldChange('sold_out', !newItem.sold_out)}
                    />
                    <span>Is Sold Out?</span>
                  </label>
                  {errors.sold_out && <span className="has-text-danger is-size-7">{errors.sold_out}</span>}
                </div>
              </div>
            </div>
            <div className="field has-text-left">
              <label className="label">Assembly Instructions</label>
              <div className="control">
                <textarea
                  style={{ verticalAlign: 'top' }}
                  placeholder="Assembly details go here..."
                  className="textarea"
                  onChange={(e) => handleFieldChange('assembly_text', e.target.value)}
                />
                {errors.assembly_text && (
                  <span className="has-text-danger is-size-7">{errors.assembly_text}</span>
                )}
              </div>
            </div>
            <br />
            <br />
            <button className="button" onClick={submitForm}>
              Submit
            </button>
          </CheckBoxStyles>
        </div>
      </div>
    </ErrorStyles>
  );
}

export { AddNewItem };
