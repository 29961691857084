import React, { useContext, useEffect } from 'react';
import Select from 'react-select';
import { getMenu } from 'api/menuAPI';
import { MenuContext } from 'contexts';
import styled from 'styled-components';
import { socket } from '../../socket';

const SelectStyles = styled.div`
  input {
    height: auto;
    box-shadow: none !important;
  }
  .react-select__control {
    padding: 1px;
  }
`;

const MenuGroupingSelect = ({ handleChange, value, classNamePrefix }) => {
  const [menuState, menuDispatch] = useContext(MenuContext);
  const menu = menuState.menu;

  useEffect(() => {
    const asyncContainerFunc = async function () {
      if (!menu) {
        const menuObj = await getMenu();
        menuDispatch({ type: 'SET_MENU', menu: menuObj });
      }
    };
    asyncContainerFunc();
  }, []);

  if (!menu) {
    return null;
  }

  return (
    <SelectStyles>
      <Select
        classNamePrefix={classNamePrefix || 'react-select'}
        closeMenuOnSelect={true}
        value={value}
        options={menu?.menu_groups.map((g) => ({ value: g.name, label: g.name }))}
        onChange={handleChange}
      />
    </SelectStyles>
  );
};

export { MenuGroupingSelect };
