import React from 'react';
import styled from 'styled-components';

const PageLoadSpinner = () => {
  const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    min-height: 500px;
    width: 100%;
    background: #fff;
    opacity: 1;
    z-index: 1;
    transition: opacity 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    .loader {
      height: 80px;
      width: 80px;
    }
  `;

  return (
    <Wrapper>
      <div className="loader is-loading"></div>
    </Wrapper>
  );
};
const SmallInlineSpinner = ({ width, height }) => {
  const Wrapper = styled.div`
    width: ${width || '100px'};
    height: ${height || '50px'};
    background: #fff;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    .loader {
      height: 80px;
      width: 80px;
    }
  `;

  return (
    <Wrapper>
      <div className="loader is-loading"></div>
    </Wrapper>
  );
};
export { PageLoadSpinner, SmallInlineSpinner };
