import React, { useState, useEffect } from 'react';
import { Level, Button, Heading } from 'react-bulma-components';
import { refundPayment, voidTransaction } from 'api/elavonAPI';
import { CHECKOUT_TYPES } from 'utils/constants';
import { socket } from '../socket';
import styled from 'styled-components';
import { getCartPrice } from 'utils/general';
import moment from 'moment';

const RefundDialog = ({ orderInitial, closeDialogCallback }) => {
  const [order, setOrder] = useState(orderInitial);

  const orderDate = moment(new Date(order.inserted_at?.slice(0, -1)));
  const todayDate = moment(new Date());
  const showVoidDialog = orderDate.startOf('day').isSame(todayDate.startOf('day'));

  const handleOrderDataResponse = (o) => {
    if (o.id == orderInitial.id) {
      setOrder(o);
    }
  };

  useEffect(() => {
    socket.on('order/order_data', handleOrderDataResponse);
    socket.emit('orders/query_order_detail', orderInitial);
    return () => {
      socket.off('order/order_data', handleOrderDataResponse);
    };
  }, []);

  const orderItems = order.items.slice();

  const refundType = order?.checkoutType == CHECKOUT_TYPES.CASH ? 'Cash Refund' : 'Credit Refund';
  const [isFullRefund, setIsFullRefund] = useState(null);
  const [isVoid, setIsVoid] = useState(null);
  const [refundItems, setRefundItems] = useState([]);
  const [isRefundTip, setIsRefundTip] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  function resetComponentState() {
    setError(null);
    setIsFullRefund(null);
    setRefundItems([]);
    setIsRefundTip(0);
    setIsProcessing(false);
    closeDialogCallback();
  }

  const isAlreadyPartiallyRefunded =
    orderItems.filter((i) => i.OrderItem_Refunded)?.length > 0 || order.tip_refunded;

  const isAlreadyFullyRefunded =
    orderItems.filter((i) => i.OrderItem_Refunded)?.length == orderItems?.length &&
    (order.tip <= 0 || (order.tip > 0 && order.tip_refunded));

  const Styles = styled.div`
    .button {
      height: 70px;
      width: auto;
    }
    .button.marginRight {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .button.marginRight:last-child {
      margin-right: unset;
    }
  `;

  async function refundOrder() {
    //do the refund
    setIsProcessing(true);
    const itemsAmount = getCartPrice(refundItems);

    const taxAmount = itemsAmount * Number(order.sales_tax_rate);
    const tipAmount = isRefundTip ? Number(order.tip_amount || 0) : 0;
    const totalAmount = Math.floor((itemsAmount + taxAmount + tipAmount) * 100) / 100;

    setError(null);
    try {
      const refundResult = await refundPayment({
        elavonTerminal: order.elavon_terminal,
        transactionId: order.elavon_transaction_id,
        refundAmount: totalAmount,
        orderId: order?.id,
        preTax: itemsAmount,
        taxRate: order.sales_tax_rate,
        tip: tipAmount,
      });
      setIsProcessing(false);

      //if unsuccessful error is triggered and we'll end up in catch block
      const refundItemsUpdated = refundItems.map((i) => {
        i.OrderItem_Refunded = 1;
        return i;
      });
      const allItemsArray = orderItems
        .slice()
        .map(
          (i) => refundItemsUpdated.find((updatedItem) => updatedItem.OrderItem_ID == i.OrderItem_ID) || i,
        );

      const updatedOrderObj = {
        ...order,
        tip_refunded: order.tip_refunded || isRefundTip,
        items: allItemsArray,
      };
      //update the order object with items and tip marked as refunded if they were refunded
      socket.emit('orders/update_order', updatedOrderObj);
      resetComponentState();
    } catch (err) {
      const error1 = err?.response?.data?.split('Error: ')?.[1]?.split('<br>')?.[0];
      const error2 = err?.message;
      setError(error1 || error2);
      setIsProcessing(false);
    }
  }

  async function voidOrder() {
    setIsProcessing(true);
    setError(null);
    try {
      console.log('void clicked');
      const voidResult = await voidTransaction({
        elavonTerminal: order.elavon_terminal,
        transactionId: order.elavon_transaction_id,
        orderId: order?.id,
        preTax: order?.pre_tax,
        taxRate: order?.sales_tax_rate,
        tip: order?.tip_amount || 0,
      });
      setIsProcessing(false);

      const allItemsArray = orderItems.slice().map((i) => ({ ...i, OrderItem_Refunded: 1 }));
      const updatedOrderObj = {
        ...order,
        tip_refunded: 1,
        items: allItemsArray,
      };
      socket.emit('orders/update_order', updatedOrderObj);
      resetComponentState();
    } catch (err) {
      const error1 = err?.response?.data?.split('Error: ')?.[1]?.split('<br>')?.[0];
      const error2 = err?.message;
      setError(error1 || error2);
      setIsProcessing(false);
    }
  }

  function toggleRefundItem(item) {
    const foundIndex = refundItems.findIndex((i) => i == item);
    const copiedArray = refundItems.slice();
    if (foundIndex >= 0) {
      copiedArray.splice(foundIndex, 1);
    } else {
      copiedArray.push(item);
    }
    setRefundItems(copiedArray);
  }

  function handleClickFullRefund() {
    if (!isAlreadyPartiallyRefunded) {
      setIsFullRefund(true);
      setIsRefundTip(1);
      setRefundItems(orderItems.slice());
    }
  }
  function handleClickVoidOrder() {
    if (!isAlreadyPartiallyRefunded) {
      setIsVoid(true);
    }
  }

  function handleClickPartialRefund() {
    setIsFullRefund(false);
  }

  function handleCancel() {
    setIsFullRefund(null);
    setIsRefundTip(0);
    closeDialogCallback();
  }

  if (isFullRefund == null && isVoid == null) {
    return (
      <Styles>
        <Heading subtitle size={5}>
          {refundType}
        </Heading>
        {isAlreadyFullyRefunded ? (
          <div className="notification is-success is-light">Order has been fully refunded</div>
        ) : null}
        {showVoidDialog ? (
          <Button
            disabled={isAlreadyPartiallyRefunded || isAlreadyFullyRefunded}
            className="button marginRight"
            onClick={handleClickVoidOrder}
          >
            Void Transaction
          </Button>
        ) : null}
        <Button
          disabled={isAlreadyPartiallyRefunded || isAlreadyFullyRefunded}
          className="button marginRight"
          onClick={handleClickFullRefund}
        >
          Full Refund
        </Button>
        <Button
          className="button marginRight"
          disabled={isAlreadyFullyRefunded}
          onClick={handleClickPartialRefund}
        >
          Partial Refund
        </Button>
      </Styles>
    );
  } else if (isVoid) {
    const submitButtonClasses = isProcessing ? 'button is-success is-loading' : 'button is-success';

    return (
      <Styles>
        <Heading subtitle size={5}>
          Void Transaction?
        </Heading>
        <Level style={{ width: '500px' }}>
          <Level.Side align="left">
            <Button className="button is-danger" onClick={handleCancel}>
              Cancel
            </Button>
          </Level.Side>
          <Level.Side align="right">
            <Button
              disabled={isProcessing}
              className={submitButtonClasses}
              onClick={async () => await voidOrder()}
            >
              Confirm Void - ${Number(order.total).toFixed(2)}
            </Button>
          </Level.Side>
        </Level>
        {error && (
          <div
            className="notification is-danger is-light"
            id="card-error"
            style={{ marginTop: '15px', marginBottom: '0' }}
          >
            {error}
          </div>
        )}
      </Styles>
    );
  } else if (isFullRefund) {
    const submitButtonClasses = isProcessing ? 'button is-success is-loading' : 'button is-success';

    return (
      <Styles>
        <Heading subtitle size={5}>
          {refundType} - Full
        </Heading>
        <Level style={{ width: '500px' }}>
          <Level.Side align="left">
            <Button className="button is-danger" onClick={handleCancel}>
              Cancel
            </Button>
          </Level.Side>
          <Level.Side align="right">
            <Button
              disabled={isProcessing}
              className={submitButtonClasses}
              onClick={async () => await refundOrder()}
            >
              Issue Full Refund - ${Number(order.total).toFixed(2)}
            </Button>
          </Level.Side>
        </Level>
        {error && (
          <div
            className="notification is-danger is-light"
            id="card-error"
            style={{ marginTop: '15px', marginBottom: '0' }}
          >
            {error}
          </div>
        )}
      </Styles>
    );
  } else {
    //partial refund
    const tipButtonClasses = isRefundTip ? 'button marginRight is-info' : 'button marginRight';
    const submitButtonClasses = isProcessing ? 'button is-success is-loading' : 'button is-success';
    const refundItemsAmount = getCartPrice(refundItems);

    const refundTaxAmount = refundItemsAmount * Number(order.sales_tax_rate);
    const refundTipAmount = isRefundTip ? Number(order.tip_amount || 0) : 0;
    const refundTotalAmount = Math.floor((refundItemsAmount + refundTaxAmount + refundTipAmount) * 100) / 100;

    return (
      <Styles>
        <Heading subtitle size={5}>
          {refundType} - Partial
        </Heading>
        {orderItems.map((i, idx) => {
          let itemDisplay = i.OrderItem_Name;
          if (i?.OrderItem_ChosenOptions?.length == 1 && i?.OrderItem_OptionLogic == 1) {
            itemDisplay = i?.OrderItem_ChosenOptions[0]?.name;
          }

          const classes = refundItems.includes(i) ? 'button marginRight is-info' : 'button marginRight';
          const noPointerEventsStyle = i.refunded || i.OrderItem_NoCharge ? { pointerEvents: 'none' } : {};
          return (
            <Button
              key={idx}
              className={classes}
              disabled={i.OrderItem_Refunded || i.OrderItem_NoCharge || false}
              onClick={() => !i.OrderItem_Refunded && toggleRefundItem(i)}
              style={{ display: 'inline-block', ...noPointerEventsStyle }}
            >
              <span>{itemDisplay}</span>
              {i.OrderItem_NoCharge && (
                <>
                  <br />
                  <span className="is-size-7">**no charge item</span>
                </>
              )}
            </Button>
          );
        })}

        {order?.tip_amount > 0 ? (
          <Button
            className={tipButtonClasses}
            disabled={order.tip_refunded}
            onClick={() => !order.tip_refunded && setIsRefundTip(+!isRefundTip)}
          >
            Tip
          </Button>
        ) : null}

        <Level style={{ width: '500px', marginTop: '40px' }}>
          <Level.Side align="left">
            <Button className="button is-danger" onClick={handleCancel}>
              Cancel
            </Button>
          </Level.Side>
          <Level.Side align="right">
            <Button
              className={submitButtonClasses}
              disabled={!refundTotalAmount || isProcessing}
              onClick={async () => await refundOrder()}
            >
              Issue Partial Refund - ${refundTotalAmount.toFixed(2)}
            </Button>
          </Level.Side>
        </Level>
        {error && (
          <div
            className="notification is-danger is-light"
            id="card-error"
            style={{ marginTop: '15px', marginBottom: '0' }}
          >
            {error}
          </div>
        )}
      </Styles>
    );
  }
};

export { RefundDialog };
