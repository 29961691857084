import { useEffect, useState } from 'react';

const matrix = [
  { query: '(max-width: 768px)', key: 'mobile' },
  { query: '(max-width: 1023px)', key: 'tablet' },
  { query: '(max-width: 1215px)', key: 'desktop' },
  { query: '(max-width: 1407px)', key: 'widescreen' },
  { query: '(min-width: 1408px)', key: 'fullhd' },
];

export const useScreenType = () => {
  const matchMobile = window.matchMedia(matrix[0].query);
  const matchTablet = window.matchMedia(matrix[1].query);
  const matchDesktop = window.matchMedia(matrix[2].query);
  const matchWidescreen = window.matchMedia(matrix[3].query);

  const [isMobile, setIsMobile] = useState(matchMobile.matches);
  const [isTablet, setIsTablet] = useState(matchTablet.matches);
  const [isDesktop, setIsDesktop] = useState(matchDesktop.matches);
  const [isWideScreen, setIsWideScreen] = useState(matchWidescreen.matches);

  useEffect(() => {
    const handlerMobile = (e) => setIsMobile(e.matches);
    const handlerTablet = (e) => setIsTablet(e.matches);
    const handlerDesktop = (e) => setIsDesktop(e.matches);
    const handlerWidescreen = (e) => setIsWideScreen(e.matches);

    matchMobile.addEventListener('change', handlerMobile);
    matchTablet.addEventListener('change', handlerTablet);
    matchDesktop.addEventListener('change', handlerDesktop);
    matchWidescreen.addEventListener('change', handlerWidescreen);

    return () => {
      matchMobile.removeEventListener('change', handlerMobile);
      matchTablet.removeEventListener('change', handlerTablet);
      matchDesktop.removeEventListener('change', handlerDesktop);
      matchWidescreen.removeEventListener('change', handlerWidescreen);
    };
  });
  if (isMobile) {
    return matrix[0].key;
  } else if (isTablet) {
    return matrix[1].key;
  } else if (isDesktop) {
    return matrix[2].key;
  } else if (isWideScreen) {
    return matrix[3].key;
  } else {
    return matrix[4].key;
  }
};
