import React, { useContext, useEffect, useState } from 'react';
import { ShoppingCartContext, MenuContext } from 'contexts';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { submitOrderNoCharge, submitOrderEcomm } from 'api/ordersAPI';
import { useHistory } from 'react-router';
import { useScreenType } from 'utils/useScreenType';
import styled from 'styled-components';
import ToggleButtonGroup from 'components/_shared/ToggleButtonGroup';
import ToggleButton from 'components/_shared/ToggleButton';
import { CHECKOUT_TYPES, ORDER_STATUS } from 'utils/constants';
import { getDiscountCodeDiscount } from 'api/adminAPI';
import { getCartPrice, getCartPricePreDiscount } from 'utils/general';
import styles from './Checkout.scss';
import PhoneNumberInput from 'react-phone-number-input/input';
import { arrayGroupBy } from 'utils/general';
import { getMenu } from 'api/menuAPI';

const tipOptions = [
  { label: '20%', getTipAmount: (p) => Math.round(p * 20) / 100 },
  { label: '18%', getTipAmount: (p) => Math.round(p * 18) / 100 },
  { label: '15%', getTipAmount: (p) => Math.round(p * 15) / 100 },
];

const Styles = styled.div`
  font-size: 15px;
  .level {
    margin: 0;
    padding: '0px 4px';
  }
  hr {
    margin: 5px 0;
    background-color: #eee;
  }
`;

const HideStockPaymentInputErrors = styled.div`
  .displayMe > div:nth-child(2) {
    display: none;
  }
`;

const TableStyled = styled.table`
  td {
    padding: 7px;
  }
  td.out-of-stock-message {
    color: white;
    font-family: Bebas Neue;
    font-size: 25px;
    line-height: 1;
    background: black;
  }
`;

const GetUnderstockItems = function () {
  const [{ itemsInCart }, cartDispatch] = useContext(ShoppingCartContext);
  const [{ menu }, menuDispatch] = useContext(MenuContext);
  const cartItemGroups = arrayGroupBy(itemsInCart, 'id');
  const cartItemIds = [...new Set(itemsInCart.map((i) => i.id))];
  const understockItems = [];
  for (let itemId of cartItemIds) {
    const itemDemandQty = cartItemGroups[itemId]?.length;
    const itemAvailQty = menu?.items?.find((i) => i.id == itemId)?.Item_QtyAvailable;
    if (itemAvailQty < itemDemandQty) {
      understockItems.push({ id: itemId, itemAvailQty, itemDemandQty });
    }
  }

  useEffect(() => {
    const asyncContainerFunc = async function () {
      if (!menu) {
        const menuObj = await getMenu();
        menuDispatch({ type: 'SET_MENU', menu: menuObj });
      }
    };
    asyncContainerFunc();
  }, []);

  const UnderstockItemsHTMLPiece = (
    <TableStyled>
      <tbody>
        <tr>
          <td colSpan="100%" className="out-of-stock-message">
            Oops, looks like we're a little low & can't take this order as-is...
          </td>
        </tr>
        <tr>
          <td>Item</td>
          <td style={{ whiteSpace: 'nowrap' }}>In-Cart</td>
          <td style={{ whiteSpace: 'nowrap' }}>On-Hand</td>
        </tr>
        {understockItems?.map((ui, index) => {
          const itemObject = menu?.items?.find((mi) => mi.id == ui.id);
          return (
            <tr key={index}>
              <td>{itemObject.name}</td>
              <td>{ui.itemDemandQty}</td>
              <td>{ui.itemAvailQty}</td>
            </tr>
          );
        })}
      </tbody>
    </TableStyled>
  );
  return {
    understockItems,
    UnderstockItemsHTMLPiece,
  };
};

export function CheckoutPublic() {
  const screenType = useScreenType();
  const [notes, setNotes] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [custName, setCustName] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [{ itemsInCart, salesTaxRate, cartPromo }, cartDispatch] = useContext(ShoppingCartContext);
  const [discountCode, setDiscountCode] = useState(cartPromo?.promoCode || null);
  const history = useHistory();
  const [selectedTip, setSelectedTip] = useState(null);
  const cartTotal = getCartPrice(itemsInCart);
  const cartTotalPreDiscount = getCartPricePreDiscount(itemsInCart);
  const [ccData, setCCData] = useState({ checkCardData: cartTotal > 0 });
  const { understockItems, UnderstockItemsHTMLPiece } = GetUnderstockItems();

  useEffect(() => {
    if (cartTotal > 0) {
      setCCData({ ...ccData, checkCardData: true });
    } else {
      setCCData({ checkCardData: false });
    }
  }, [cartTotal]);

  function makeString(arr) {
    if (arr.length === 1) return arr[0];
    const firsts = arr.slice(0, arr.length - 1);
    const last = arr[arr.length - 1];
    return firsts.join(', ') + ' and ' + last;
  }

  function testForErrors() {
    const ccBitsMissing = [];
    if (ccData?.checkCardData) {
      if (!ccData?.cardNum) ccBitsMissing.push('Card Number');
      if (!ccData?.cardExp) ccBitsMissing.push('Expiration');
      if (!ccData?.cardCVV) ccBitsMissing.push('CVV');
      if (!ccData?.cardZip) ccBitsMissing.push('Zip');
    }
    const errosObj = {};
    if (!phoneNumber) errosObj.phoneNumber = 'Required.';
    if (!custName) errosObj.custName = 'Required.';
    if (ccBitsMissing?.length) {
      errosObj.creditCard = makeString(ccBitsMissing) + ' required.';
    }
    setFormErrors(errosObj);
    if (Object.keys(errosObj).length) {
      return true;
    }
    return false;
  }

  //if there are formErrors on the screen and our monitored values change, recalculate formErrors
  useEffect(() => {
    if (Object.keys(formErrors).length) {
      testForErrors();
    }
  }, [phoneNumber, discountCode, custName, notes, ccData]);

  const tax = cartTotal * salesTaxRate;
  const tip = selectedTip?.getTipAmount(cartTotal) || 0;

  useEffect(() => {
    if (!itemsInCart?.length) {
      history.replace('/');
    }
  }, [itemsInCart]);

  useEffect(() => {
    const applyDiscountCode = async function () {
      let data = await getDiscountCodeDiscount({ discountCode });
      cartDispatch({
        type: 'SET_CART_PROMO',
        rate: data?.promoRate != null ? data?.promoRate : 1,
        code: data?.promoCode,
        message: data?.promoMessage,
      });
    };
    if (discountCode) {
      applyDiscountCode();
    } else {
      cartDispatch({
        type: 'SET_CART_PROMO',
        rate: 1,
        code: null,
        message: null,
      });
    }
  }, [discountCode]);

  function setOrToggleTip(option) {
    if (option == selectedTip) {
      setSelectedTip(null);
    } else {
      setSelectedTip(option);
    }
  }

  if (!itemsInCart?.length) {
    return null;
  }

  if (understockItems?.length) {
    return (
      <div className="columns is-centered" style={{ padding: 0, marginTop: 0 }}>
        <div
          className="column is-half is-full-mobile is-two-fifths-desktop"
          style={{
            padding: 0,
            maxWidth: '500px',
            minWidth: screenType == 'mobile' ? '300px' : '380px',
            margin: 'auto',
          }}
        >
          <div
            style={{
              textAlign: 'left',
              border: '2px solid #4e4e4e',
              borderRadius: '4px',
              padding: '10px',
              background: 'white',
            }}
          >
            {UnderstockItemsHTMLPiece}
          </div>
        </div>
      </div>
    );
  }

  if (cartTotal == 0) {
    return (
      <div className="columns is-centered" style={{ padding: 0, marginTop: 0 }}>
        <div
          className="column is-half is-full-mobile is-two-fifths-desktop"
          style={{
            padding: 0,
            maxWidth: '500px',
            minWidth: screenType == 'mobile' ? '300px' : '380px',
            margin: 'auto',
          }}
        >
          <div
            style={{
              textAlign: 'left',
              border: '2px solid #4e4e4e',
              borderRadius: '4px',
              padding: '10px',
              background: 'white',
            }}
          >
            <LogisticsForm
              discountCode={discountCode}
              setDiscountCode={setDiscountCode}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              custName={custName}
              setCustName={setCustName}
              setOrToggleTip={setOrToggleTip}
              formErrors={formErrors}
              cartTotal={cartTotal}
              notes={notes}
              setNotes={setNotes}
            />
          </div>

          <NoChargeForm
            phoneNumber={phoneNumber}
            notes={notes}
            testForErrors={testForErrors}
            custName={custName}
            cartTotalPreDiscount={cartTotalPreDiscount}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="columns is-centered" style={{ padding: 0, marginTop: 0 }}>
        <div
          className="column is-half is-full-mobile is-two-fifths-desktop"
          style={{
            padding: 0,
            maxWidth: '500px',
            minWidth: screenType == 'mobile' ? '300px' : '380px',
            margin: 'auto',
          }}
        >
          <div
            style={{
              textAlign: 'left',
              border: '2px solid #4e4e4e',
              borderRadius: '4px',
              padding: '10px',
              background: 'white',
            }}
          >
            <LogisticsForm
              discountCode={discountCode}
              setDiscountCode={setDiscountCode}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              custName={custName}
              setCustName={setCustName}
              setOrToggleTip={setOrToggleTip}
              formErrors={formErrors}
              cartTotal={cartTotal}
              notes={notes}
              setNotes={setNotes}
            />
          </div>
          <CardNotPresentForm
            custName={custName}
            cartTotal={cartTotal}
            cartTotalPreDiscount={cartTotalPreDiscount}
            phoneNumber={phoneNumber}
            tax={tax}
            tip={tip}
            notes={notes}
            isCardPresent={false}
            checkoutType={CHECKOUT_TYPES.MANUAL_CREDIT}
            testForErrors={testForErrors}
            formErrors={formErrors}
            setCCData={setCCData}
          />
        </div>
      </div>
    </>
  );
}

const CheckoutLineItem = function ({ item }) {
  const [menuState, menuDispatch] = useContext(MenuContext);
  const menu = menuState.menu;

  const defaultItemOptions = menu?.item_options?.filter((mi) =>
    item.OrderItem_DefaultOptions?.includes(mi.id),
  );

  const chosenItemIds = item.OrderItem_ChosenOptions?.map((ci) => ci.id);
  const defaultItemIds = defaultItemOptions?.map((di) => di.id);

  let unchosenItems = defaultItemIds?.filter((id) => !chosenItemIds?.includes(id));
  let unchosenObjects = defaultItemOptions
    ?.filter((obj) => obj.active)
    //?.filter((obj) => !obj.sold_out) //uncomment for --> dont count soldout things as "unchosen"
    ?.filter((obj) => unchosenItems?.includes(obj.id));

  let addedItems = chosenItemIds?.filter((id) => !defaultItemIds?.includes(id));
  let addedObjects = item.OrderItem_ChosenOptions?.filter((obj) => addedItems?.includes(obj.id));

  let options = (
    <div>
      {addedObjects?.map((o) => (
        <p key={o.id}>ADD - {o.name}</p>
      ))}
      {unchosenObjects?.map((o) => (
        <p key={o.id}>NO - {o.name}</p>
      ))}
    </div>
  );

  let name = item?.name;
  if (item?.OrderItem_OptionLogic == 1) {
    name = item?.OrderItem_ChosenOptions?.[0].name || name;
    options = null;
  }

  if (!addedItems?.length && !unchosenObjects?.length) {
    options = null;
  }

  return (
    <>
      <div style={{ textAlign: 'left', marginBottom: 0 }}>
        <div style={{ marginTop: '5px' }}>{name}</div>
        {options}
      </div>
    </>
  );
};

const LogisticsForm = function ({
  discountCode,
  setDiscountCode,
  phoneNumber,
  setPhoneNumber,
  custName,
  setCustName,
  setOrToggleTip,
  formErrors,
  cartTotal,
  setNotes,
  notes,
}) {
  const [{ cartPromo, itemsInCart }, cartDispatch] = useContext(ShoppingCartContext);
  const itemsInCartCopy = itemsInCart?.slice();

  function handleItemNotesChange(e, item) {
    const copy = { ...item, OrderItem_Notes: e.target.value?.trim() };
    cartDispatch({ type: 'UPDATE_ITEM_IN_CART', item: copy });
  }
  return (
    <>
      <div className="level is-mobile" style={{ marginBottom: 0, alignItems: 'start' }}>
        <div
          className="level-left"
          style={{ width: 'calc(50% - 5px)', maxWidth: 'unset', marginRight: '10px', display: 'block' }}
        >
          <input
            onBlur={(e) => setCustName(e.target.value || null)}
            className="input"
            placeholder="Name"
            style={{ margin: '5px 0px', padding: '2px 8px', height: 'unset' }}
            defaultValue={custName}
          ></input>
          {formErrors?.custName && (
            <span className="has-text-danger is-size-7" style={{ display: 'block' }}>
              {formErrors?.custName}
            </span>
          )}
        </div>
        <div className="level-right" style={{ width: 'calc(50% - 5px)', display: 'block' }}>
          <PhoneNumberInput
            country="US"
            className="input"
            value={phoneNumber}
            onChange={setPhoneNumber}
            placeholder="Phone"
            maxLength="14"
            style={{ margin: '5px 0px', padding: '2px 8px', height: 'unset' }}
          />
          {formErrors?.phoneNumber && (
            <span className="has-text-danger is-size-7" style={{ display: 'block' }}>
              {formErrors?.phoneNumber}
            </span>
          )}
          <span style={{ display: 'block', fontSize: '11px' }}>
            (we'll text you when your order is ready)
          </span>
        </div>
      </div>

      <input
        onBlur={(e) => setDiscountCode(e.target.value || null)}
        className="input"
        placeholder="Dicount code?"
        style={{ margin: '5px 0', padding: '2px 8px', height: 'unset' }}
        defaultValue={cartPromo?.promoCode}
      ></input>
      <div style={{ textAlign: 'left', fontSize: '0.8em' }}>{cartPromo?.promoMessage}</div>
      <div style={{ display: 'none' }}>
        {/* hide this section for now */}
        <p style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '1.2em', marginBottom: '5px' }}>
          Item specific notes?
        </p>
        {itemsInCartCopy?.map((item) => (
          <div style={{}} key={JSON.stringify(item)}>
            <CheckoutLineItem item={item} />
            <input
              onChange={(e) => handleItemNotesChange(e, item)}
              className="input"
              placeholder="Item notes here..."
              style={{
                height: 'unset',
                marginBottom: '5px',
                borderColor: '#ccc',
                padding: '2px 8px',
              }}
              defaultValue={item?.OrderItem_Notes || null}
            ></input>
          </div>
        ))}
      </div>
      <div style={{ marginBottom: 0 }}>
        <textarea
          onChange={(e) => setNotes(e.target.value || null)}
          className="textarea"
          placeholder="Notes for the kitchen?"
          style={{
            minHeight: '75px',
            borderColor: '#ccc',
            margin: '5px 0px',
            padding: '2px 8px',
          }}
          defaultValue={notes || null}
        ></textarea>
      </div>
      {cartTotal > 0 ? (
        <>
          <p style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '1.2em' }}>Add a Tip?</p>
          <ToggleButtonGroup
            defaultValue={null}
            allowDeselect={true}
            fieldClassName={'columns is-centered is-mobile tips-buttons-field'}
            containerStyle={{ margin: 'initial' }}
          >
            {tipOptions.map((option) => (
              <ToggleButton
                onClick={() => setOrToggleTip(option)}
                label={option.label}
                key={option.label}
                className="tip-button"
                controlClassName="column"
              >
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
      ) : null}
    </>
  );
};

const NoChargeForm = function ({ notes, phoneNumber, testForErrors, custName, cartTotalPreDiscount }) {
  const [{ itemsInCart, salesTaxRate, cartPromo }, cartDispatch] = useContext(ShoppingCartContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [remoteError, setRemoteError] = useState(null);
  const history = useHistory();

  const submitNoChargeOrder = async (e) => {
    e.preventDefault();
    const hasErrors = testForErrors();
    if (hasErrors) {
      return;
    } else {
      const order = {
        total: (0).toFixed(2),
        pre_tax: (0).toFixed(2),
        pre_tax_before_discounts: cartTotalPreDiscount.toFixed(2),
        tip_amount: (0).toFixed(2),
        sales_tax: (0).toFixed(2),
        sales_tax_rate: salesTaxRate,
        checkout_type: CHECKOUT_TYPES.MANUAL_CREDIT,
        order_status: ORDER_STATUS.NEW,
        items: itemsInCart.slice(),
        notes: notes,
        Order_PromoCode: cartPromo?.promoCode || null,
        Order_PromoRate: 0,
        Order_PhoneNumber: phoneNumber,
        Order_CustName: custName,
      };
      setIsSubmitting(true);
      setRemoteError(null);
      try {
        const result = await submitOrderNoCharge({ order });
        history.push('/order-confirmation', { order: result?.data?.order });
      } catch (err) {
        const error1 = err?.response?.data?.split('Error: ')?.[1]?.split('<br>')?.[0];
        const error2 = err?.message;
        setRemoteError(error1 || error2);
        setIsSubmitting(false);
      }
      //const receiptProps = await htmlToImage(document.getElementById('receipt-hidden'));
      //printReceiptFromImage(receiptProps).catch((err) => console.log(err));
    }
  };

  let buttonClasses = 'button is-dark creditButton submit ';
  let extraClasses = isSubmitting ? 'is-loading ' : '';
  buttonClasses = buttonClasses + extraClasses;

  return (
    <div
      style={{
        textAlign: 'left',
        border: '2px solid #4e4e4e',
        borderRadius: '4px',
        marginTop: '20px',
        padding: '10px',
        background: 'white',
      }}
    >
      {' '}
      <div className="">
        <Styles>
          <div className="level is-mobile">
            <div className="level-left">Total Due</div>
            <div className="level-right">No charges</div>
          </div>
        </Styles>
        <button
          className={buttonClasses}
          id="submit"
          disabled={isSubmitting}
          onClick={submitNoChargeOrder}
          style={{ marginTop: '10px' }}
        >
          Submit No Charge Order
        </button>
        {remoteError && (
          <div
            className="notification is-danger is-light"
            id="card-error"
            style={{ marginTop: '15px', marginBottom: '0' }}
          >
            {remoteError}
          </div>
        )}
      </div>
    </div>
  );
};

function ZipInput({ cardZip, setCardZip }) {
  const zipRegex = /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/; //regex for USA and CAN zips
  function handleChangeCardZip(e) {
    setCardZip(e.target.value?.toLocaleUpperCase());
  }
  function handleBlurCardZip(e) {
    if (!e.target.value?.match(zipRegex)) {
      setCardZip(null);
    }
  }
  return (
    <input
      value={cardZip || ''}
      onBlur={handleBlurCardZip}
      onChange={handleChangeCardZip}
      placeholder="ZIP"
      style={{ width: '3.5em' }}
    />
  );
}

const CardNotPresentForm = function ({
  cartTotal,
  cartTotalPreDiscount,
  tax,
  tip,
  checkoutType,
  notes,
  phoneNumber,
  custName,
  formErrors,
  testForErrors,
  setCCData,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [remoteError, setRemoteError] = useState(null);
  const [{ itemsInCart, salesTaxRate, cartPromo }, cartDispatch] = useContext(ShoppingCartContext);
  const screenType = useScreenType();
  const [cardNum, setCardNum] = useState(null);
  const [cardExp, setCardExp] = useState(null);
  const [cardCVV, setCardCVV] = useState(null);
  const [cardZip, setCardZip] = useState(null);

  function handleChangeCardNum(e) {
    setCardNum(e.target.value);
  }
  function handleChangeCardExp(e) {
    setCardExp(e.target.value);
  }
  function handleChangeCardCVV(e) {
    setCardCVV(e.target.value);
  }

  useEffect(() => {
    setCCData({ cardNum, cardExp, cardCVV, cardZip, checkCardData: true });
  }, [cardNum, cardExp, cardCVV, cardZip]);

  const { wrapperProps, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();

  const total = cartTotal + tax + (tip || 0);
  const history = useHistory();

  const submitCreditPayment = async (e) => {
    e.preventDefault();
    const hasErrors = testForErrors();
    if (hasErrors) {
      return;
    } else {
      const order = {
        total: total.toFixed(2),
        pre_tax: cartTotal.toFixed(2),
        pre_tax_before_discounts: cartTotalPreDiscount.toFixed(2),
        tip_amount: (tip || 0).toFixed(2),
        sales_tax: tax.toFixed(2),
        sales_tax_rate: salesTaxRate,
        checkout_type: checkoutType,
        order_status: ORDER_STATUS.NEW,
        items: itemsInCart.slice(),
        notes: notes,
        Order_PromoCode: cartPromo?.promoCode || null,
        Order_PromoRate: cartPromo?.promoRate != null ? cartPromo?.promoRate : 1,
        Order_PhoneNumber: phoneNumber,
        Order_CustName: custName,
      };
      setIsSubmitting(true);
      setRemoteError(null);
      try {
        const paymentResult = await submitOrderEcomm({
          itemsInCart,
          tip,
          taxRate: salesTaxRate,
          tax,
          preTax: cartTotal.toFixed(2),
          order,
          elavonAMT: total,
          cardNum: cardNum,
          cardExp: cardExp,
          cardCVV: cardCVV,
          cardZip: cardZip,
        });
        history.push('/order-confirmation', { order: paymentResult.data.order });
      } catch (err) {
        const error1 = err?.response?.data?.split('Error: ')?.[1]?.split('<br>')?.[0];
        const error2 = err?.message;
        setRemoteError(error1 || error2);
        setIsSubmitting(false);
      }
    }
  };

  let buttonClasses = 'button is-dark creditButton submit ';
  let extraClasses = isSubmitting ? 'is-loading ' : '';
  buttonClasses = buttonClasses + extraClasses;

  const mobileSpecificInputStyles = screenType == 'mobile' ? { zoom: '0.95' } : {};
  const cvvProps = getCVCProps({ onChange: handleChangeCardCVV });
  cvvProps.placeholder = 'CVV';

  return (
    <div
      className=""
      style={{ marginTop: '20px', padding: '10px', background: 'white', border: '2px solid #4e4e4e' }}
    >
      <div className="">
        <Styles>
          <div className="level is-mobile">
            <div className="level-left">Subtotal</div>
            <div className="level-right">${cartTotal.toFixed(2)}</div>
          </div>
          <div className="level is-mobile">
            <div className="level-left">Tax</div>
            <div className="level-right">${tax.toFixed(2)}</div>
          </div>
          {tip > 0 ? (
            <div className="level is-mobile">
              <div className="level-left">Tip</div>
              <div className="level-right">${tip.toFixed(2)}</div>
            </div>
          ) : null}
          <hr />
          <div className="level is-mobile">
            <div className="level-left">Total Due</div>
            <div className="level-right">${total.toFixed(2)}</div>
          </div>
        </Styles>
        <HideStockPaymentInputErrors>
          <PaymentInputsWrapper
            {...wrapperProps}
            style={{ ...mobileSpecificInputStyles, width: '100%' }}
            className="displayMe"
          >
            {screenType != 'mobile' && <svg {...getCardImageProps({ images })} />}
            <input {...getCardNumberProps({ onChange: handleChangeCardNum })} style={{ flexGrow: 1 }}></input>
            <input {...getExpiryDateProps({ onChange: handleChangeCardExp })} />
            <input {...cvvProps} />
            <ZipInput setCardZip={setCardZip} cardZip={cardZip} />
          </PaymentInputsWrapper>
        </HideStockPaymentInputErrors>

        {remoteError && (
          <div
            className="notification is-danger is-light"
            id="card-error"
            style={{ marginTop: '15px', marginBottom: '0' }}
          >
            {remoteError}
          </div>
        )}
        {formErrors?.creditCard && (
          <span className="has-text-danger is-size-7" style={{ display: 'block' }}>
            {formErrors?.creditCard}
          </span>
        )}
        <button
          className={buttonClasses}
          id="submit"
          disabled={isSubmitting}
          onClick={submitCreditPayment}
          style={{ marginTop: '10px' }}
        >
          Pay Now
        </button>
      </div>
    </div>
  );
};
