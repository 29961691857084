import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { ShoppingCartContext } from 'contexts';
import { OrderTracker } from 'components/OrderTracker';
import { socket } from '../socket';

export function OrderConfirmationKiosk() {
  const { state } = useLocation();
  const [order, setOrder] = useState(state?.order);

  const history = useHistory();
  const [cartState, cartDispatch] = useContext(ShoppingCartContext);

  useEffect(async () => {
    if (order && cartState.itemsInCart?.length) {
      cartDispatch({ type: 'EMPTY_CART' });
    }
  }, []);

  if (!state) {
    history.replace('/');
    return null;
  }
  if (!order) {
    history.replace('/');
    return null;
  }

  return <OrderTracker order={order} kioskVersion={1} />;
}
