import createContext from '../utils/createContext';

const defaultInitialState = {
  orders: [],
};
const initialState = JSON.parse(localStorage.getItem('ordersState')) || defaultInitialState;

const reducer = (state, { type, order }) => {
  var newOrdersArray;
  switch (type) {
    case 'SAVE_ORDER':
      newOrdersArray = state.orders.slice();
      newOrdersArray.push(order);
      localStorage.setItem('ordersState', JSON.stringify({ ...state, orders: newOrdersArray }));
      return { ...state, orders: newOrdersArray };

    case 'UPDATE_ORDER':
      newOrdersArray = state.orders.slice();
      const index = newOrdersArray.findIndex((o) => o.id == order.id);
      if (index > -1) {
        newOrdersArray[index] = order;
      }
      localStorage.setItem('ordersState', JSON.stringify({ ...state, orders: newOrdersArray }));
      return { ...state, orders: newOrdersArray };

    default:
      return state;
  }
};

const [OrdersContext, OrdersProvider] = createContext({
  initialState,
  reducer,
});

export { OrdersContext, OrdersProvider };
