import http from '../utils/http';
import { toBase64 } from 'utils/general';

const getDiscountCodeDiscount = async ({ discountCode }) => {
  const resp = await http.post(`${process.env.REACT_APP_POS_SERVICE_URL}/administration/promo/check-discount-code`, {
    discountCode,
  });
  return resp?.data;
};
async function getTruckDatabaseLocation() {
  const resp = await http.get(`${process.env.REACT_APP_POS_SERVICE_URL}/administration/location/get-truck-location`);
  return resp?.data;
}
async function setTruckDatabaseLocation({ location }) {
  const resp = await http.post(`${process.env.REACT_APP_POS_SERVICE_URL}/administration/location/set-truck-location`, {
    location,
  });
  return resp?.data;
}

export { getDiscountCodeDiscount, getTruckDatabaseLocation, setTruckDatabaseLocation };
