import axios from 'axios';

let axiosInstance;

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 401) {
    window.location.pathname = '/';
  }
  return null;
}

function hasAuthToken() {
  return !!axiosInstance.defaults.headers.common.Authorization;
}
function setAuthToken(token) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  localStorage.setItem('authtoken', token);
}
function clearAuthToken() {
  localStorage.removeItem('authtoken');
  delete axiosInstance.defaults.headers.common.Authorization;
}

export default {
  init() {
    axiosInstance = axios.create({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    const tokenFromStorage = localStorage.getItem('authtoken');
    if (!hasAuthToken() && tokenFromStorage) {
      setAuthToken(tokenFromStorage);
    }
  },
  async get(uri, config) {
    const response = await axiosInstance.get(uri, config);
    return checkStatus(response);
  },
  async put(uri, data, config) {
    const response = await axiosInstance.put(uri, data, config);
    return checkStatus(response);
  },
  async post(uri, data, config) {
    const response = await axiosInstance.post(uri, data, config);
    return checkStatus(response);
  },
  async delete(uri, config) {
    const response = await axiosInstance.delete(uri, config);
    return checkStatus(response);
  },
  async head(uri, config) {
    const response = await axiosInstance.head(uri, config);
    return checkStatus(response);
  },
  clearAuthToken,
  setAuthToken,
};
