import React, { useContext, useState, useEffect } from 'react';
//import serviceRoles from 'auth/service-roles';
import { login } from 'api/authAPI';
import { AuthContext } from 'contexts';
import { useHistory } from 'react-router';
import ErrorBlock from 'components/_shared/ErrorBlock';

const Login = function () {
  const [authState, authDispatch] = useContext(AuthContext);
  const [loginDetails, setLoginDetails] = useState({ email: null, password: null });
  const history = useHistory();

  const { isAuthenticating } = authState;

  function handleEnterKey(event) {
    if (event.keyCode === 13) {
      doLogin();
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleEnterKey);
    return () => {
      document.removeEventListener('keyup', handleEnterKey);
    };
  });

  async function doLogin() {
    authDispatch({ type: 'AUTH_REQUESTED' });
    try {
      const loginResp = await login(loginDetails);
      const userObj = { ...loginResp?.data };
      delete userObj.token;
      authDispatch({ type: 'AUTH_COMPLETED', user: userObj });
      history.push('/order-queue');
    } catch (error) {
      authDispatch({ type: 'AUTH_FAILED', errorcode: 'UNKNOWN' });
    }
  }

  function handleChangeEmail(e) {
    setLoginDetails({ ...loginDetails, email: e.target.value });
  }

  function handleChangePassword(e) {
    setLoginDetails({ ...loginDetails, password: e.target.value });
  }

  const loadingClass = isAuthenticating ? 'is-loading' : '';
  return (
    <div className="container" style={{ marginTop: '100px' }}>
      <div className="columns is-centered">
        <div className="column is-5-tablet is-4-desktop">
          <div
            className="box"
            style={{
              minWidth: '320px',
              maxWidth: '450px',
              margin: '0 auto',
              border: '1px solid #b5b5b5',
              boxShadow: 'none',
            }}
          >
            <div className="field">
              <label className="label">Email</label>
              <div className="control has-icons-left">
                <input
                  type="email"
                  placeholder="e.g. bobsmith@gmail.com"
                  className="input"
                  required
                  onChange={handleChangeEmail}
                />
                <span className="icon is-small is-left">
                  <i className="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <div className="field">
              <label className="label">Password</label>
              <div className="control has-icons-left">
                <input
                  type="password"
                  placeholder="*******"
                  className="input"
                  required
                  onChange={handleChangePassword}
                  autoComplete="off"
                />
                <span className="icon is-small is-left">
                  <i className="fa fa-lock"></i>
                </span>
              </div>
            </div>
            <div className="field">
              <button
                disabled={isAuthenticating}
                className={`button is-info ${loadingClass}`}
                onClick={doLogin}
              >
                Login
              </button>
              <ErrorBlock
                message={authState?.loginError ? 'Invalid username or password' : null}
                isVisible={authState?.loginError}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Login };
