import React, { useContext, useEffect, useState } from 'react';
import { ShoppingCartContext, MenuContext } from 'contexts';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { startTransactionCardPresent, checkStatusCardPresent } from 'api/elavonAPI';
import { submitOrderEcomm, submitOrderCardPresent, submitOrderNoCharge } from 'api/ordersAPI';
import { htmlToImage, printReceiptFromImage } from 'api/receiptsAPI';
import { getDiscountCodeDiscount } from 'api/adminAPI';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ToggleButtonGroup from 'components/_shared/ToggleButtonGroup';
import ToggleButton from 'components/_shared/ToggleButton';
import { CurrencyInput } from 'components/_shared/CurrencyInput';
import { CHECKOUT_TYPES, ORDER_STATUS } from 'utils/constants';
// import { HiddenReceiptForPrint } from 'components/HiddenReceiptForPrint';
import { getCartPrice, getCartPricePreDiscount } from 'utils/general';
import { useInterval } from 'utils/useInterval';
import { useScreenType } from 'utils/useScreenType';
import styles from './Checkout.scss';
import PhoneNumberInput from 'react-phone-number-input/input';
import AnimateHeight from 'react-animate-height';

const Styles = styled.div`
  font-size: 15px;
  .level {
    margin: 0;
    padding: '0px 4px';
  }
  hr {
    margin: 5px 0;
    background-color: #eee;
  }
`;

const tipOptions = [
  { label: '20%', getTipAmount: (p) => Math.round(p * 20) / 100 },
  { label: '18%', getTipAmount: (p) => Math.round(p * 18) / 100 },
  { label: '15%', getTipAmount: (p) => Math.round(p * 15) / 100 },
];

const defaultCheckoutType = CHECKOUT_TYPES.CREDIT;

const CheckoutLineItem = function ({ item }) {
  const [menuState, menuDispatch] = useContext(MenuContext);
  const menu = menuState.menu;

  const defaultItemOptions = menu?.item_options?.filter((mi) =>
    item.OrderItem_DefaultOptions?.includes(mi.id),
  );

  const chosenItemIds = item.OrderItem_ChosenOptions?.map((ci) => ci.id);
  const defaultItemIds = defaultItemOptions?.map((di) => di.id);

  let unchosenItems = defaultItemIds?.filter((id) => !chosenItemIds?.includes(id));
  let unchosenObjects = defaultItemOptions
    ?.filter((obj) => obj.active)
    //?.filter((obj) => !obj.sold_out) //uncomment for --> dont count soldout things as "unchosen"
    ?.filter((obj) => unchosenItems?.includes(obj.id));

  let addedItems = chosenItemIds?.filter((id) => !defaultItemIds?.includes(id));
  let addedObjects = item.OrderItem_ChosenOptions?.filter((obj) => addedItems?.includes(obj.id));

  let options = (
    <div>
      {addedObjects?.map((o) => (
        <p key={o.id}>ADD - {o.name}</p>
      ))}
      {unchosenObjects?.map((o) => (
        <p key={o.id}>NO - {o.name}</p>
      ))}
    </div>
  );

  let name = item?.name;
  if (item?.OrderItem_OptionLogic == 1) {
    name = item?.OrderItem_ChosenOptions?.[0].name || name;
    options = null;
  }

  if (!addedItems?.length && !unchosenObjects?.length) {
    options = null;
  }

  return (
    <>
      <div style={{ textAlign: 'left', marginBottom: 0 }}>
        <div style={{ marginTop: '5px' }}>{name}</div>
        {options}
      </div>
    </>
  );
};

const LogisticsForm = function ({
  discountCode,
  setDiscountCode,
  phoneNumber,
  setPhoneNumber,
  setOrToggleTip,
  cartTotal,
  formErrors,
}) {
  const [{ cartPromo }, cartDispatch] = useContext(ShoppingCartContext);
  const [formHeight, setFormHeight] = useState('auto');

  return (
    <div style={{ textAlign: 'left', border: '1px solid #b5b5b5', borderRadius: '4px', padding: '10px' }}>
      <button
        onClick={() => setFormHeight(formHeight == 0 ? 'auto' : 0)}
        className="button"
        style={{
          width: 'auto',
          border: 'none',
          padding: 0,
          fontSize: '1.2em',
          color: 'darkgreen',
          textDecoration: 'underline',
          filter: 'brightness(1)',
          backgroundColor: 'initial',
          boxShadow: 'none',
          height: 'unset',
        }}
      >
        {formHeight == 0 ? 'Logistics?' : 'Logistics ^'}
      </button>
      <AnimateHeight
        duration={300}
        height={formHeight} // see props documentation below
        style={{ overflow: 'hidden' }}
      >
        <PhoneNumberInput
          country="US"
          className="input"
          value={phoneNumber}
          onChange={setPhoneNumber}
          placeholder="Phone number (we'll text you when your order's ready)"
          maxLength="14"
          style={{ marginBottom: 0, padding: '2px 8px', height: 'unset' }}
        />
        {formErrors?.phoneNumber && (
          <span className="has-text-danger is-size-7">{formErrors?.phoneNumber}</span>
        )}
        <>
          <input
            onBlur={(e) => setDiscountCode(e.target.value || null)}
            className="input"
            placeholder="Dicount code?"
            defaultValue={cartPromo?.promoCode}
            style={{ margin: '8px 0', padding: '2px 8px', height: 'unset' }}
          ></input>
          <div style={{ textAlign: 'left', fontSize: '0.8em' }}>{cartPromo?.promoMessage}</div>
          <p style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '1.2em' }}>Add a Tip?</p>
          <ToggleButtonGroup
            defaultValue={null}
            allowDeselect={true}
            fieldClassName={'columns is-centered is-mobile tips-buttons-field'}
          >
            {tipOptions.map((option) => (
              <ToggleButton
                onClick={() => setOrToggleTip(option)}
                key={option.label}
                label={option.label}
                className="tip-button"
                controlClassName="column"
              >
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
      </AnimateHeight>
    </div>
  );
};

const NotesForm = function ({ notes, setNotes }) {
  const [{ itemsInCart }, cartDispatch] = useContext(ShoppingCartContext);
  const itemsInCartCopy = itemsInCart?.slice();
  const [formHeight, setFormHeight] = useState(0);

  function handleItemNotesChange(e, item) {
    const copy = { ...item, OrderItem_Notes: e.target.value?.trim() };
    cartDispatch({ type: 'UPDATE_ITEM_IN_CART', item: copy });
  }
  return (
    <div
      style={{
        textAlign: 'left',
        border: '1px solid #b5b5b5',
        borderRadius: '4px',
        padding: '10px',
        marginTop: '20px',
      }}
    >
      <button
        onClick={() => setFormHeight(formHeight == 0 ? 'auto' : 0)}
        className="button"
        style={{
          width: 'auto',
          border: 'none',
          padding: 0,
          fontSize: '1.2em',
          color: 'darkgreen',
          textDecoration: 'underline',
          filter: 'brightness(1)',
          backgroundColor: 'initial',
          boxShadow: 'none',
          height: 'unset',
        }}
      >
        {formHeight == 0 ? 'Notes?' : 'Notes ^'}
      </button>
      <AnimateHeight
        duration={300}
        height={formHeight} // see props documentation below
        style={{ overflow: 'hidden' }}
      >
        <div style={{ display: 'none' }}>
          {/* hide this section for now */}
          <p style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '1.2em', marginBottom: '5px' }}>
            Item specific notes?
          </p>
          {itemsInCartCopy?.map((item) => (
            <div style={{}} key={JSON.stringify(item)}>
              <CheckoutLineItem item={item} />
              <input
                onChange={(e) => handleItemNotesChange(e, item)}
                className="input"
                placeholder="Item notes here..."
                style={{
                  height: 'unset',
                  marginBottom: '5px',
                  borderColor: '#ccc',
                  padding: '2px 8px',
                }}
                defaultValue={item?.OrderItem_Notes || null}
              ></input>
            </div>
          ))}
        </div>
        <p style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '1.2em', marginBottom: '5px' }}>
          General notes?
        </p>
        <div style={{ marginBottom: '10px' }}>
          <textarea
            onChange={(e) => setNotes(e.target.value || null)}
            className="textarea"
            placeholder="General notes here..."
            style={{
              minHeight: '75px',
              borderColor: '#ccc',
              padding: '2px 8px',
            }}
            defaultValue={notes || null}
          ></textarea>
        </div>
      </AnimateHeight>
    </div>
  );
};

export function CheckoutPrivate() {
  const [{ itemsInCart, salesTaxRate, cartPromo }, cartDispatch] = useContext(ShoppingCartContext);
  // const [orderForReceipt, setOrderForReceipt] = useState(null);
  const [checkoutIsDone, setCheckoutIsDone] = useState(false);
  const [notes, setNotes] = useState(null);
  const [discountCode, setDiscountCode] = useState(cartPromo?.promoCode || null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  function testForErrors() {
    const errosObj = {};
    if (!phoneNumber) errosObj.phoneNumber = 'Required. Just so we can make sure you get your food!';
    setFormErrors(errosObj);
    if (Object.keys(errosObj).length) {
      return true;
    }
    return false;
  }

  //if there are formErrors on the screen and our monitored values change, recalculate formErrors
  useEffect(() => {
    if (Object.keys(formErrors).length) {
      testForErrors();
    }
  }, [phoneNumber, discountCode, notes]);

  const history = useHistory();
  const [selectedTip, setSelectedTip] = useState(null);
  const cartTotal = getCartPrice(itemsInCart);
  const cartTotalPreDiscount = getCartPricePreDiscount(itemsInCart);

  const tax = cartTotal * salesTaxRate;
  const tip = selectedTip?.getTipAmount(cartTotal) || 0;
  const [checkoutType, setCheckoutType] = useState(defaultCheckoutType);

  useEffect(() => {
    if (checkoutIsDone) {
      cartDispatch({ type: 'EMPTY_CART' });
    }
  }, [checkoutIsDone]);

  useEffect(() => {
    const applyDiscountCode = async function () {
      let data = await getDiscountCodeDiscount({ discountCode });
      cartDispatch({
        type: 'SET_CART_PROMO',
        rate: data?.promoRate != null ? data?.promoRate : 1,
        code: data?.promoCode,
        message: data?.promoMessage,
      });
    };
    if (discountCode) {
      applyDiscountCode();
    } else {
      cartDispatch({
        type: 'SET_CART_PROMO',
        rate: 1,
        code: null,
        message: null,
      });
    }
  }, [discountCode]);

  useEffect(() => {
    if (!itemsInCart?.length) {
      if (checkoutIsDone) {
        history.push('/order-queue');
      } else {
        history.replace('/');
      }
    }
  }, [itemsInCart]);

  function setOrToggleTip(option) {
    if (option == selectedTip) {
      setSelectedTip(null);
    } else {
      setSelectedTip(option);
    }
  }

  if (!itemsInCart?.length) {
    return null;
  }

  if (cartTotal == 0) {
    return (
      <div className="columns is-centered" style={{ padding: '1em', paddingTop: 0, marginTop: 0 }}>
        <div
          className="column is-half is-full-mobile is-two-fifths-desktop"
          style={{ padding: 0, maxWidth: '500px', minWidth: '300px', margin: 'auto' }}
        >
          <LogisticsForm
            cartTotal={cartTotal}
            discountCode={discountCode}
            setDiscountCode={setDiscountCode}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setOrToggleTip={setOrToggleTip}
            formErrors={formErrors}
          />
          <NotesForm notes={notes} setNotes={setNotes} />
          {/* <HiddenReceiptForPrint receiptDivId="receipt-hidden" order={orderForReceipt} /> */}
          <NoChargeForm
            phoneNumber={phoneNumber}
            cartTotalPreDiscount={cartTotalPreDiscount}
            // setOrderForReceipt={setOrderForReceipt}
            setCheckoutIsDone={setCheckoutIsDone}
            notes={notes}
            testForErrors={testForErrors}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="columns is-centered" style={{ padding: '1em', paddingTop: 0, marginTop: 0 }}>
        <div
          className="column is-half is-full-mobile is-two-fifths-desktop"
          style={{ padding: 0, maxWidth: '500px', minWidth: '300px', margin: 'auto' }}
        >
          {/* <HiddenReceiptForPrint receiptDivId="receipt-hidden" order={orderForReceipt} /> */}
          <LogisticsForm
            cartTotal={cartTotal}
            discountCode={discountCode}
            setDiscountCode={setDiscountCode}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setOrToggleTip={setOrToggleTip}
            formErrors={formErrors}
          />
          <NotesForm notes={notes} setNotes={setNotes} />
          <ToggleButtonGroup
            groupClassName={'checkout-width checkout-types'}
            fieldClassName={'field has-addons'}
            containerStyle={{ marginBottom: '-1px', marginTop: '18px' }}
          >
            <ToggleButton onClick={() => setCheckoutType(CHECKOUT_TYPES.CREDIT)} label="Credit">
              Credit
            </ToggleButton>
            <ToggleButton onClick={() => setCheckoutType(CHECKOUT_TYPES.MANUAL_CREDIT)} label="Manual Credit">
              Manual Credit
            </ToggleButton>
            <ToggleButton onClick={() => setCheckoutType(CHECKOUT_TYPES.CASH)} label="Cash">
              Cash
            </ToggleButton>
          </ToggleButtonGroup>
          {checkoutType == CHECKOUT_TYPES.CASH && (
            <CashCheckoutForm
              cartTotal={cartTotal}
              cartTotalPreDiscount={cartTotalPreDiscount}
              tax={tax}
              tip={tip}
              phoneNumber={phoneNumber}
              checkoutType={checkoutType}
              testForErrors={testForErrors}
              notes={notes}
            />
          )}
          {checkoutType == CHECKOUT_TYPES.CREDIT && (
            <CardPresentForm
              cartTotal={cartTotal}
              cartTotalPreDiscount={cartTotalPreDiscount}
              tax={tax}
              tip={tip}
              phoneNumber={phoneNumber}
              checkoutType={checkoutType}
              // setOrderForReceipt={setOrderForReceipt}
              setCheckoutIsDone={setCheckoutIsDone}
              testForErrors={testForErrors}
              notes={notes}
            />
          )}
          {checkoutType == CHECKOUT_TYPES.MANUAL_CREDIT && (
            <CardNotPresentForm
              cartTotal={cartTotal}
              cartTotalPreDiscount={cartTotalPreDiscount}
              tax={tax}
              tip={tip}
              phoneNumber={phoneNumber}
              checkoutType={checkoutType}
              // setOrderForReceipt={setOrderForReceipt}
              setCheckoutIsDone={setCheckoutIsDone}
              testForErrors={testForErrors}
              notes={notes}
            />
          )}
        </div>
      </div>
    </>
  );
}

const NoChargeForm = function ({
  cartTotalPreDiscount,
  // setOrderForReceipt,
  setCheckoutIsDone,
  notes,
  phoneNumber,
  testForErrors,
}) {
  const [{ itemsInCart, salesTaxRate, cartPromo }, cartDispatch] = useContext(ShoppingCartContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [remoteError, setRemoteError] = useState(null);

  const submitNoChargeOrder = async (e) => {
    e.preventDefault();
    const hasErrors = testForErrors();
    if (hasErrors) {
      return;
    } else {
      const order = {
        total: (0).toFixed(2),
        pre_tax: (0).toFixed(2),
        pre_tax_before_discounts: cartTotalPreDiscount.toFixed(2),
        tip_amount: (0).toFixed(2),
        sales_tax: (0).toFixed(2),
        sales_tax_rate: salesTaxRate,
        checkout_type: CHECKOUT_TYPES.MANUAL_CREDIT,
        order_status: ORDER_STATUS.NEW,
        items: itemsInCart.slice(),
        notes: notes,
        Order_PromoCode: cartPromo?.promoCode || null,
        Order_PromoRate: 0,
        Order_PhoneNumber: phoneNumber,
      };
      setIsSubmitting(true);
      setRemoteError(null);
      try {
        const result = await submitOrderNoCharge({ itemsInCart, order });
        // setOrderForReceipt(result.data.order);
        setIsSubmitting(false);
        setCheckoutIsDone(true);
      } catch (err) {
        const error1 = err?.response?.data?.split('Error: ')?.[1]?.split('<br>')?.[0];
        const error2 = err?.message;
        setRemoteError(error1 || error2);
        setIsSubmitting(false);
      }
      //const receiptProps = await htmlToImage(document.getElementById('receipt-hidden'));
      //printReceiptFromImage(receiptProps).catch((err) => console.log(err));
    }
  };

  let buttonClasses = 'button is-dark creditButton submit ';
  let extraClasses = isSubmitting ? 'is-loading ' : '';
  buttonClasses = buttonClasses + extraClasses;

  return (
    <div className="sr-main" style={{ marginTop: '20px' }}>
      <div className="sr-payment-form">
        <Styles>
          <div className="level is-mobile">
            <div className="level-left">Total Due</div>
            <div className="level-right">No charges</div>
          </div>
        </Styles>
        <button className={buttonClasses} id="submit" disabled={isSubmitting} onClick={submitNoChargeOrder}>
          Submit No Charge Order
        </button>
        {remoteError && (
          <div
            className="notification is-danger is-light"
            id="card-error"
            style={{ marginTop: '15px', marginBottom: '0' }}
          >
            {remoteError}
          </div>
        )}
      </div>
    </div>
  );
};

const CashCheckoutForm = function ({
  cartTotal,
  cartTotalPreDiscount,
  tax,
  tip,
  checkoutType,
  notes,
  phoneNumber,
  testForErrors,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currencyInputValue, setCurrencyInputValue] = useState(null);

  let buttonClasses = 'button is-dark cashButton submit ';
  let extraClasses = isSubmitting ? 'is-loading ' : '';
  buttonClasses = buttonClasses + extraClasses;

  function timeoutDummy(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const total = cartTotal + tax + (tip || 0);
  function LikelyTenderedAmounts() {
    const exact = total;
    const nearestDollar = Math.ceil(total);
    const nearestFiveDollars = Math.ceil(total / 5) * 5;
    const Style = styled.div`
      .button {
        height: 60px;
        white-space: pre-wrap;
        width: 120px;
        margin: 20px;
      }
    `;
    return (
      <Style>
        <p>Likely Provided Amounts?</p>
        <button className="button" onClick={() => setCurrencyInputValue(exact.toFixed(2))}>
          Exact Change
        </button>
        {nearestDollar != exact ? (
          <button className="button" onClick={() => setCurrencyInputValue(nearestDollar.toFixed(2))}>
            ${nearestDollar.toFixed(2)}
          </button>
        ) : null}

        {nearestFiveDollars != nearestDollar ? (
          <button className="button" onClick={() => setCurrencyInputValue(nearestFiveDollars.toFixed(2))}>
            ${nearestFiveDollars.toFixed(2)}
          </button>
        ) : null}
      </Style>
    );
  }

  const submitCashPayment = async (e) => {
    e.preventDefault();
    const hasErrors = testForErrors();
    if (hasErrors) {
      return;
    } else {
      setIsSubmitting(true);
      //await timeoutDummy(500);
      alert('add cash payment method');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="sr-main" style={{ borderTopLeftRadius: 0 }}>
      <div className="sr-payment-form">
        <Styles>
          <div className="level is-mobile">
            <div className="level-left">Subtotal</div>
            <div className="level-right">${cartTotal.toFixed(2)}</div>
          </div>
          <div className="level is-mobile">
            <div className="level-left">Tax</div>
            <div className="level-right">${tax.toFixed(2)}</div>
          </div>
          {tip > 0 ? (
            <div className="level is-mobile">
              <div className="level-left">Tip</div>
              <div className="level-right">${tip.toFixed(2)}</div>
            </div>
          ) : null}
          <hr />
          <div className="level is-mobile">
            <div className="level-left">Total Due</div>
            <div className="level-right">${total.toFixed(2)}</div>
          </div>
        </Styles>
        <br />
        <LikelyTenderedAmounts />
        <div className="sr-combo-inputs-row">
          <div className="control has-icons-left">
            <CurrencyInput
              className="input cash-input"
              placeholder="Cash Given"
              value={currencyInputValue}
              setValueCallback={setCurrencyInputValue}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-dollar-sign"></i>
            </span>
          </div>
        </div>
        <button className={buttonClasses} id="submit" disabled={isSubmitting} onClick={submitCashPayment}>
          Pay Cash
        </button>
      </div>
    </div>
  );
};

const CardNotPresentForm = function ({
  cartTotal,
  cartTotalPreDiscount,
  tax,
  tip,
  checkoutType,
  // setOrderForReceipt,
  setCheckoutIsDone,
  notes,
  phoneNumber,
  testForErrors,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [remoteError, setRemoteError] = useState(null);
  const [{ itemsInCart, salesTaxRate, cartPromo }, cartDispatch] = useContext(ShoppingCartContext);
  const total = cartTotal + tax + (tip || 0);
  const [cardNum, setCardNum] = useState(null);
  const [cardExp, setCardExp] = useState(null);
  const [cardCVV, setCardCVV] = useState(null);
  const [cardZip, setCardZip] = useState(null);
  const screenType = useScreenType();

  function handleChangeCardNum(e) {
    setCardNum(e.target.value);
  }
  function handleChangeCardExp(e) {
    setCardExp(e.target.value);
  }
  function handleChangeCardCVV(e) {
    setCardCVV(e.target.value);
  }
  function handleChangeCardZip(e) {
    setCardZip(e.target.value);
  }
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getZIPProps,
  } = usePaymentInputs();

  const submitCreditPayment = async (e) => {
    e.preventDefault();
    const hasErrors = testForErrors();
    if (hasErrors) {
      return;
    } else {
      const order = {
        total: total.toFixed(2),
        pre_tax: cartTotal.toFixed(2),
        pre_tax_before_discounts: cartTotalPreDiscount.toFixed(2),
        tip_amount: (tip || 0).toFixed(2),
        sales_tax: tax.toFixed(2),
        sales_tax_rate: salesTaxRate,
        checkout_type: checkoutType,
        order_status: ORDER_STATUS.NEW,
        items: itemsInCart.slice(),
        notes: notes,
        Order_PromoCode: cartPromo?.promoCode || null,
        Order_PromoRate: cartPromo?.promoRate != null ? cartPromo?.promoRate : 1,
        Order_PhoneNumber: phoneNumber,
      };
      setIsSubmitting(true);
      setRemoteError(null);
      try {
        const paymentResult = await submitOrderEcomm({
          itemsInCart,
          tip,
          taxRate: salesTaxRate,
          tax,
          preTax: cartTotal.toFixed(2),
          order,
          elavonAMT: total,
          cardNum: cardNum,
          cardExp: cardExp,
          cardCVV: cardCVV,
          cardZip: cardZip,
        });
        // setOrderForReceipt(paymentResult?.data?.order);
        setCheckoutIsDone(true);
      } catch (err) {
        const error1 = err?.response?.data?.split('Error: ')?.[1]?.split('<br>')?.[0];
        const error2 = err?.message;
        setRemoteError(error1 || error2);
        setIsSubmitting(false);
      }
    }
  };

  let buttonClasses = 'button is-dark creditButton submit ';
  let extraClasses = isSubmitting ? 'is-loading ' : '';
  buttonClasses = buttonClasses + extraClasses;

  const mobileSpecificInputStyles = screenType == 'mobile' ? { zoom: '0.95' } : {};
  const cvvProps = getCVCProps({ onChange: handleChangeCardCVV });
  cvvProps.placeholder = 'CVV';

  return (
    <div className="sr-main" style={{ borderTopLeftRadius: 0 }}>
      <div className="sr-payment-form">
        <Styles>
          <div className="level is-mobile">
            <div className="level-left">Subtotal</div>
            <div className="level-right">${cartTotal.toFixed(2)}</div>
          </div>
          <div className="level is-mobile">
            <div className="level-left">Tax</div>
            <div className="level-right">${tax.toFixed(2)}</div>
          </div>
          {tip > 0 ? (
            <div className="level is-mobile">
              <div className="level-left">Tip</div>
              <div className="level-right">${tip.toFixed(2)}</div>
            </div>
          ) : null}
          <hr />
          <div className="level is-mobile">
            <div className="level-left">Total Due</div>
            <div className="level-right">${total.toFixed(2)}</div>
          </div>
        </Styles>
        <PaymentInputsWrapper
          {...wrapperProps}
          style={{ ...mobileSpecificInputStyles, width: '100%', marginTop: '5px' }}
        >
          {screenType != 'mobile' && <svg {...getCardImageProps({ images })} />}
          <input {...getCardNumberProps({ onChange: handleChangeCardNum })} style={{ flexGrow: 1 }}></input>
          <input {...getExpiryDateProps({ onChange: handleChangeCardExp })} />
          <input {...cvvProps} />
          <input {...getZIPProps({ onChange: handleChangeCardZip })} style={{ width: '3em' }} />
        </PaymentInputsWrapper>
        {remoteError && (
          <div
            className="notification is-danger is-light"
            id="card-error"
            style={{ marginTop: '15px', marginBottom: '0' }}
          >
            {remoteError}
          </div>
        )}
        <button className={buttonClasses} id="submit" disabled={isSubmitting} onClick={submitCreditPayment}>
          Pay Manual Credit
        </button>
      </div>
    </div>
  );
};

//prettier-ignore
const CardPresentForm = function ({
  cartTotal,
  cartTotalPreDiscount,
  tax,
  tip,
  checkoutType,
  // setOrderForReceipt,
  setCheckoutIsDone,
  notes,
  phoneNumber,
  testForErrors,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [gatewayHash, setGatewayHash] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [remoteError, setRemoteError] = useState(null);
  const [{ itemsInCart, salesTaxRate, cartPromo }, cartDispatch] = useContext(ShoppingCartContext);
  const total = cartTotal + tax + (tip || 0);
  const history = useHistory();
  const [addedOrder, setAddedOrder] = useState(null);

  const orderTemplate = {
    total: total.toFixed(2),
    pre_tax: cartTotal.toFixed(2),
    pre_tax_before_discounts: cartTotalPreDiscount.toFixed(2),
    tip_amount: (tip || 0).toFixed(2),
    sales_tax: tax.toFixed(2),
    sales_tax_rate: salesTaxRate,
    checkout_type: checkoutType,
    order_status: ORDER_STATUS.NEW,
    items: itemsInCart.slice(),
    notes: notes,
    Order_PromoCode: cartPromo?.promoCode || null,
    Order_PromoRate: cartPromo?.promoRate != null ? cartPromo?.promoRate : 1,
    Order_PhoneNumber: phoneNumber,
    elavon_terminal: 'Retail',
  };

  useEffect(() => {
    if (transactionStatus?.data?.paymentGatewayCommand?.completed) {
      let errorMessage = null;
      let wasApproval = transactionStatus?.data?.paymentGatewayCommand?.paymentTransactionData?.result == 'APPROVED';
      let wasFullAmount = transactionStatus?.data?.paymentGatewayCommand?.paymentTransactionData?.amount?.value == Math.round(total * 100);
      let wasGivenAnID = transactionStatus?.data?.paymentGatewayCommand?.paymentTransactionData?.id

      if (transactionStatus?.data?.paymentGatewayCommand?.paymentTransactionData?.errors?.length > 0 ){
        errorMessage = transactionStatus.data.data.paymentGatewayCommand.paymentTransactionData.errors[0];
      } else if (
        transactionStatus?.data?.paymentGatewayCommand?.paymentTransactionData?.result &&
        transactionStatus?.data?.paymentGatewayCommand?.paymentTransactionData?.result != 'APPROVED'
      ) {
        errorMessage = transactionStatus?.data?.paymentGatewayCommand?.paymentTransactionData?.result;
      } else if (transactionStatus?.data?.order?.Order_Paid != 1) {
        errorMessage = 'Took money but failed to mark order as paid';
      } 

      if (errorMessage) {
        setRemoteError(errorMessage);
        setTransactionHash(null);
        setTransactionStatus(null);
        setIsSubmitting(false);
      }else if (wasApproval && wasFullAmount && wasGivenAnID){
        // setOrderForReceipt(transactionStatus?.data?.order);
        setCheckoutIsDone(true);
        setIsSubmitting(false);
      }
    }
  }, [transactionStatus]);

  useInterval(
    () => {
      const asyncContainer = async () => {
        const transStatus = await checkStatusCardPresent({
          dipReaderSaleIdentifier: transactionHash,
          dipReaderGatewayHash: gatewayHash,
          preTax: cartTotal.toFixed(2),
          taxRate: salesTaxRate,
          tip: tip.toFixed(2),
          order: addedOrder
        });
        setTransactionStatus(transStatus);
      };
      asyncContainer();
    },
    transactionHash && gatewayHash && addedOrder && !transactionStatus?.data?.paymentGatewayCommand?.completed
      ? 500
      : null,
  );

  const startCardPresentTransaction = async (e) => {
    e.preventDefault();
    const hasErrors = testForErrors();
    if (hasErrors) {
      return;
    } else {
      setIsSubmitting(true);
      setRemoteError(null);
      try {
        //paymentResult = await collectPayment({ itemsInCart, tip, isCardPresent, order });
        const addOrderResult = await submitOrderCardPresent({ order: orderTemplate });
        if(addOrderResult?.data?.order?.id){
          setAddedOrder(addOrderResult.data.order);
          const { dipReaderSaleIdentifier, dipReaderGatewayHash } =
          await startTransactionCardPresent({
            elavonAMT: Math.round(total * 100),
            elavonCurrency: 'USD',
            elavonInvoiceNum: Date.now()+'-retail-'+Math.floor(Math.random()*1000),
            elavonTaxAMT: Math.round(tax * 100),
            elavonCustID: '0',
          });
          setGatewayHash(dipReaderGatewayHash);
          setTransactionHash(dipReaderSaleIdentifier);
        }
      } catch (err) {
        setRemoteError(err.message);
        setIsSubmitting(false);
      }
    }
  };

  let buttonClasses = 'button is-dark creditButton submit ';
  let extraClasses = isSubmitting ? 'is-loading ' : '';
  buttonClasses = buttonClasses + extraClasses;

  return (
    <div className="sr-main" style={{ borderTopLeftRadius: 0 }}>
      <div className="sr-payment-form">
        <Styles>
          <div className="level is-mobile">
            <div className="level-left">Subtotal</div>
            <div className="level-right">${cartTotal.toFixed(2)}</div>
          </div>
          <div className="level is-mobile">
            <div className="level-left">Tax</div>
            <div className="level-right">${tax.toFixed(2)}</div>
          </div>
          {tip > 0 ? (
            <div className="level is-mobile">
              <div className="level-left">Tip</div>
              <div className="level-right">${tip.toFixed(2)}</div>
            </div>
          ) : null}
          <hr />
          <div className="level is-mobile">
            <div className="level-left">Total Due</div>
            <div className="level-right">${total.toFixed(2)}</div>
          </div>
        </Styles>
        {remoteError && (
          <div
            className="notification is-danger is-light"
            id="card-error"
            style={{ marginTop: '15px', marginBottom: '0' }}
          >
            {remoteError}
          </div>
        )}
        <button
          className={buttonClasses}
          id="submit"
          disabled={isSubmitting}
          onClick={startCardPresentTransaction}
        >
          Send to Terminal
        </button>
      </div>
    </div>
  );
};
