import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { ItemOptionSelect } from 'components/_shared/ItemOptionSelect';
import { MenuGroupingSelect } from 'components/_shared/MenuGroupingSelect';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getMenu } from 'api/menuAPI';
import { MenuContext } from 'contexts';
import { editItem } from 'api/menuAPI';
import { toBase64 } from 'utils/general';
import _ from 'lodash';

const CheckBoxStyles = styled.div`
  input.checkbox {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    vertical-align: middle;
    margin-bottom: 6px;
  }
  .label {
    display: inline-block;
  }
  .button[disabled] {
    pointer-events: none;
  }
`;

const SelectStyles = styled.div`
  input {
    height: auto;
    box-shadow: none !important;
  }
`;

const ErrorStyles = styled.div`
  .react-select__control {
    padding: 1px;
  }
  .has-text-danger {
    white-space: nowrap;
  }
  .textarea {
    min-height: 80px;
  }
`;

const ItemOptionLogicValues = [
  { value: 'N', label: 'N' },
  { value: '1', label: '1' },
];

function mapInitialValues(item, menu) {
  const returnItem = {
    ...item,
    available_options: menu.item_options
      .filter((o) => item.available_options.includes(o.id))
      .map((o) => ({ value: o.id, label: o.name })),
    default_options: menu.item_options
      .filter((o) => item.default_options.includes(o.id))
      .map((o) => ({ value: o.id, label: o.name })),
    option_logic: ItemOptionLogicValues?.find((v) => v.value == item.option_logic),
    menu_grouping: { value: item.menu_grouping, label: item.menu_grouping },
    price: mapCurrency(item.price?.toString()),
    cost: mapCurrency(item.cost?.toString()),
  };
  return returnItem;
}

function mapCurrency(value) {
  if (!value) {
    return { value: value, label: value };
  }
  const returnObj = { value: value.trim(), label: value.trim() };
  if (returnObj.value.startsWith('$')) {
    returnObj.value = returnObj.value.slice(1);
  }
  returnObj.value = Number(returnObj.value).toString();
  return returnObj;
}

function EditItem({ itemId }) {
  const [item, setItem] = useState(null);
  useEffect(() => {
    async function loadData() {
      const m = await getMenu();
      const i = m.items.find((i) => i.id == itemId);
      const itemMapped = mapInitialValues(i, m);
      console.log(itemMapped);
      setItem(itemMapped);
    }
    loadData();
  }, []);
  if (!item) {
    return null;
  }
  return <EditItemInternal item={item} />;
}

function EditItemInternal({ item }) {
  const [inital, setInitial] = useState({ ...item });
  const [itemForUpdate, setItemForUpdate] = useState(inital);
  const [errors, setErrors] = useState({});
  const { available_options, default_options } = itemForUpdate;

  function handleFieldChange(fieldname, value) {
    if (fieldname == 'price' || fieldname == 'cost') {
      value = mapCurrency(value?.toString());
    }
    setItemForUpdate({ ...itemForUpdate, [fieldname]: value });
  }

  function isFormSubmitDisabled() {
    const initialForCompare = {
      ...inital,
    };
    const current = { ...itemForUpdate };
    delete current.file;
    delete initialForCompare.file;

    const isDisabled = _.isEqual(initialForCompare, current);
    return isDisabled;
  }

  //if there are errors on the screen and our itemForUpdate object changes, recalculate errors
  useEffect(() => {
    if (Object.keys(errors).length) {
      testForErrors();
    }
  }, [itemForUpdate]);

  //if an item is removed from the available array, and it's in the defaults, remove it from the defaults array
  useEffect(() => {
    let needsUpdate = false;
    let newDefaultsArr = [...default_options];
    for (var o of default_options) {
      if (!available_options.find((ao) => ao.value == o.value)) {
        newDefaultsArr = newDefaultsArr.filter((d) => d.value != o.value);
        needsUpdate = true;
      }
    }
    if (needsUpdate) {
      setItemForUpdate({ ...itemForUpdate, default_options: newDefaultsArr });
    }
  }, [available_options]);

  //if an item is added as a default but isnt available, add it to available array
  useEffect(() => {
    let needsUpdate = false;
    let newAvailableArray = [...available_options];
    for (var o of default_options) {
      if (!available_options.find((ao) => ao.value == o.value)) {
        newAvailableArray.push(o);
        needsUpdate = true;
      }
    }
    if (needsUpdate) {
      setItemForUpdate({ ...itemForUpdate, available_options: newAvailableArray });
    }
  }, [default_options]);

  function handleRemoveImage() {
    setItemForUpdate({
      ...itemForUpdate,
      file: null,
      filesystem_path: null,
      image_src_base64: null,
      image_filename_extension: null,
    });
  }

  async function handleSelectFile(event) {
    const allowedFileTypes = ['jpg', 'jpeg', 'png', 'gif'];
    if (allowedFileTypes.includes(event?.target?.files?.[0]?.name?.split('.')?.pop()?.toLowerCase())) {
      setItemForUpdate({
        ...itemForUpdate,
        file: event.target.files[0],
        image_src_base64: await toBase64(event.target.files[0]),
        image_filename_extension: '.' + event.target.files[0].name.split('.').pop()?.toLowerCase(),
      });
    }
  }

  function testForErrors() {
    const newErrorsObj = {};
    const estTimeMins = itemForUpdate.estimated_prep_time_minutes;

    if (!itemForUpdate.name) newErrorsObj.name = 'Required';
    if (!itemForUpdate.Item_SortIndex) newErrorsObj.Item_SortIndex = 'Required';
    if (itemForUpdate.price.value && isNaN(itemForUpdate.price.value?.trim())) {
      newErrorsObj.price = 'Must be numeric';
    }
    if (itemForUpdate?.Item_SortIndex && isNaN(itemForUpdate?.Item_SortIndex?.trim())) {
      newErrorsObj.Item_SortIndex = 'Must be numeric';
    }
    if (itemForUpdate.cost.value && isNaN(itemForUpdate.cost.value?.trim())) {
      newErrorsObj.cost = 'Must be numeric';
    }
    if (estTimeMins == null || estTimeMins?.toString().trim() == '') {
      newErrorsObj.estimated_prep_time_minutes = 'Required';
    } else if (isNaN(estTimeMins?.toString().trim())) {
      newErrorsObj.estimated_prep_time_minutes = 'Must be numeric';
    }
    if (!itemForUpdate.menu_grouping) newErrorsObj.menu_grouping = 'Required';
    if (!itemForUpdate.option_logic) newErrorsObj.option_logic = 'Required';
    if (itemForUpdate.option_logic.value == '1' && !itemForUpdate.available_options.length) {
      newErrorsObj.available_options = "At least 1 option is required when option logic is '1'";
    }
    setErrors(newErrorsObj);
    if (Object.keys(newErrorsObj).length) {
      return true;
    }
    return false;
  }

  async function submitForm() {
    const hasErrors = testForErrors();
    if (hasErrors) {
      return;
    } else {
      const estPrepMins = itemForUpdate.estimated_prep_time_minutes;
      const mappedItemForSubmit = {
        ...itemForUpdate,
        price: itemForUpdate.price.value > 0 ? Number(itemForUpdate.price.value).toFixed(2) : null,
        cost: itemForUpdate.cost.value > 0 ? Number(itemForUpdate.cost.value).toFixed(2) : null,
        estimated_prep_time_minutes: Number(estPrepMins) > 0 ? Math.round(estPrepMins) : 0,
        option_logic: itemForUpdate.option_logic.value,
        menu_grouping: itemForUpdate.menu_grouping.value,
        default_options: itemForUpdate.default_options.map((o) => o.value),
        available_options: itemForUpdate.available_options.map((o) => o.value),
      };

      await editItem({ item: mappedItemForSubmit });
      toast.info(`${mappedItemForSubmit.name} updated successfully`, {
        containerId: 'top-center',
        autoClose: 2500,
      });
      setInitial(itemForUpdate);
    }
  }

  if (!itemForUpdate) {
    return null;
  }

  const submitDisabled = isFormSubmitDisabled();
  let imageSrc = itemForUpdate?.image_src_base64 ? itemForUpdate?.image_src_base64 : null;
  if (imageSrc == null && itemForUpdate?.filesystem_path) {
    imageSrc = process.env.REACT_APP_POS_SERVICE_URL + '/public/images/menu/' + item.filesystem_path;
  }

  return (
    <ErrorStyles>
      <div style={{ paddingLeft: '80px', minWidth: '900px' }} className="columns">
        <div className="column column1">
          <div className="field has-text-left" style={{ maxWidth: '385px' }}>
            <label className="label">Image</label>
            <div className="file has-name">
              <label className="file-label">
                <input className="file-input" type="file" name="resume" onChange={handleSelectFile} />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label" style={{ fontSize: '15px' }}>
                    Choose file (.jpg, .png, .gif)
                  </span>
                </span>
                <span className="file-name">{itemForUpdate?.file?.name}</span>
              </label>
            </div>
            {imageSrc ? (
              <>
                <img src={imageSrc} style={{ marginTop: '15px', height: '100px' }} />
                <i
                  className="far fa-trash-alt"
                  aria-hidden="true"
                  onClick={handleRemoveImage}
                  style={{ verticalAlign: 'top', marginTop: '15px', marginLeft: '5px', cursor: 'pointer' }}
                />
              </>
            ) : (
              <p style={{ marginTop: '15px', fontStyle: 'italic' }}>**No Image**</p>
            )}
          </div>
          <div className="field has-text-left">
            <label className="label">Name</label>
            <div className="control">
              <input
                placeholder="Bulgogi"
                className="input"
                value={itemForUpdate.name || ''}
                onChange={(e) => handleFieldChange('name', e.target.value)}
              />
              {errors.name && <span className="has-text-danger is-size-7">{errors.name}</span>}
            </div>
          </div>
          <div className="field has-text-left">
            <label className="label">Sort Order</label>
            <div className="control">
              <input
                className="input"
                value={itemForUpdate.Item_SortIndex || ''}
                onChange={(e) => handleFieldChange('Item_SortIndex', e.target.value)}
              />
              {errors.Item_SortIndex && (
                <span className="has-text-danger is-size-7">{errors.Item_SortIndex}</span>
              )}
            </div>
          </div>
          <div className="field has-text-left">
            <div className="field has-text-left" style={{ display: 'inline-grid', maxWidth: '120px' }}>
              <label className="label">Price</label>
              <div className="control">
                <input
                  placeholder="$10.00"
                  className="input"
                  value={itemForUpdate.price.label}
                  onChange={(e) => handleFieldChange('price', e.target.value)}
                />
                {errors.price && <span className="has-text-danger is-size-7">{errors.price}</span>}
              </div>
            </div>
            <div
              className="field has-text-left"
              style={{ display: 'inline-grid', maxWidth: '120px', marginLeft: '24px' }}
            >
              <label className="label">Cost</label>
              <div className="control">
                <input
                  placeholder="$5.00"
                  className="input"
                  value={itemForUpdate.cost.label}
                  onChange={(e) => handleFieldChange('cost', e.target.value)}
                />
                {errors.cost && <span className="has-text-danger is-size-7">{errors.cost}</span>}
              </div>
            </div>
            <div
              className="field has-text-left"
              style={{ display: 'inline-grid', maxWidth: '110px', marginLeft: '24px' }}
            >
              <label className="label">Option Logic</label>
              <SelectStyles>
                <Select
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                  value={itemForUpdate?.option_logic}
                  options={ItemOptionLogicValues}
                  onChange={(val) => handleFieldChange('option_logic', val)}
                />
              </SelectStyles>
              {errors.option_logic && (
                <span className="has-text-danger is-size-7">{errors.option_logic}</span>
              )}
            </div>
          </div>
          <div className="field has-text-left">
            <label className="label">Menu Grouping</label>
            <div className="control">
              <MenuGroupingSelect
                handleChange={(val) => handleFieldChange('menu_grouping', val)}
                value={itemForUpdate.menu_grouping}
              />
              {errors.menu_grouping && (
                <span className="has-text-danger is-size-7">{errors.menu_grouping}</span>
              )}
            </div>
          </div>
          <div className="field has-text-left" style={{ width: '170px' }}>
            <label className="label">Est Prep Time (mins)</label>
            <input
              placeholder="..."
              className="input"
              value={itemForUpdate.estimated_prep_time_minutes}
              onChange={(e) => handleFieldChange('estimated_prep_time_minutes', e.target.value)}
            />
            {errors.estimated_prep_time_minutes && (
              <span className="has-text-danger is-size-7">{errors.estimated_prep_time_minutes}</span>
            )}
          </div>
          <div className="field has-text-left">
            <label className="label">Description</label>
            <div className="control">
              <textarea
                style={{ verticalAlign: 'top' }}
                placeholder="A sweet and savory koren beef dish"
                className="textarea"
                value={itemForUpdate.description || ''}
                onChange={(e) => handleFieldChange('description', e.target.value)}
              />
              {errors.description && <span className="has-text-danger is-size-7">{errors.description}</span>}
            </div>
          </div>
          <div className="field has-text-left">
            <label className="label">Recipe</label>
            <div className="control">
              <textarea
                style={{ verticalAlign: 'top' }}
                placeholder="Recipe goes here..."
                value={itemForUpdate.recipe_text || ''}
                className="textarea"
                onChange={(e) => handleFieldChange('recipe_text', e.target.value)}
              />
              {errors.recipe && <span className="has-text-danger is-size-7">{errors.recipe}</span>}
            </div>
          </div>
        </div>
        <div className="column column2">
          <div className="field has-text-left">
            <label className="label">Available Options</label>
            <div className="control">
              <ItemOptionSelect
                handleChange={(arr) => handleFieldChange('available_options', arr)}
                value={itemForUpdate.available_options}
              />
              {errors.available_options && (
                <span className="has-text-danger is-size-7">{errors.available_options}</span>
              )}
            </div>
          </div>
          <div className="field has-text-left">
            <label className="label">Default Options</label>
            <div className="control">
              <ItemOptionSelect
                handleChange={(arr) => handleFieldChange('default_options', arr)}
                value={itemForUpdate.default_options}
              />
              {errors.default_options && (
                <span className="has-text-danger is-size-7">{errors.default_options}</span>
              )}
            </div>
          </div>
          <CheckBoxStyles>
            <div className="columns">
              <div className="column">
                <div className="field has-text-left">
                  <label className="label">
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={itemForUpdate.gluten_free}
                      onChange={() => handleFieldChange('gluten_free', !itemForUpdate.gluten_free)}
                    />
                    <span>Gluten Free?</span>
                  </label>
                  {errors.gluten_free && (
                    <span className="has-text-danger is-size-7">{errors.gluten_free}</span>
                  )}
                </div>
                <div className="field has-text-left">
                  <label className="label">
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={itemForUpdate.vegan}
                      onChange={() => handleFieldChange('vegan', !itemForUpdate.vegan)}
                    />
                    <span>Vegan?</span>
                  </label>
                  {errors.vegan && <span className="has-text-danger is-size-7">{errors.vegan}</span>}
                </div>
                <div className="field has-text-left">
                  <label className="label">
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={itemForUpdate.vegetarian}
                      onChange={() => handleFieldChange('vegetarian', !itemForUpdate.vegetarian)}
                    />
                    <span>Vegetarian?</span>
                  </label>
                  {errors.vegetarian && (
                    <span className="has-text-danger is-size-7">{errors.vegetarian}</span>
                  )}
                </div>
              </div>
              <div className="column">
                <div className="field has-text-left">
                  <label className="label">
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={itemForUpdate.active}
                      onChange={() => handleFieldChange('active', !itemForUpdate.active)}
                    />
                    <span>Is Active?</span>
                  </label>
                  {errors.active && <span className="has-text-danger is-size-7">{errors.active}</span>}
                </div>
              </div>
            </div>
            <div className="field has-text-left">
              <label className="label">Assembly Instructions</label>
              <div className="control">
                <textarea
                  style={{ verticalAlign: 'top' }}
                  placeholder="Assembly details go here..."
                  value={itemForUpdate.assembly_text || ''}
                  className="textarea"
                  onChange={(e) => handleFieldChange('assembly_text', e.target.value)}
                />
                {errors.assembly_text && (
                  <span className="has-text-danger is-size-7">{errors.assembly_text}</span>
                )}
              </div>
            </div>
            <br />
            <br />
            <button disabled={submitDisabled} className="button" onClick={submitForm}>
              Save Changes
            </button>
          </CheckBoxStyles>
        </div>
      </div>
    </ErrorStyles>
  );
}

export { EditItem };
