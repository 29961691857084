import React, { useContext, useEffect } from 'react';
import { PublicLayout } from './public/PublicLayout';
import { PrivateLayout } from './private/PrivateLayout';
import { AuthContext, MenuContext, ShoppingCartContext } from 'contexts';
import { checkAuth } from 'api/authAPI';
import { KitchenLoginPage } from './KitchenLoginPage';
import { socket } from 'socket';

export function Layout() {
  const [authState, authDispatch] = useContext(AuthContext);
  const [cartState, cartDispatch] = useContext(ShoppingCartContext);
  const [menuState, menuDispatch] = useContext(MenuContext);

  const isKitchenSubdomain = window.location.host?.includes('kitchen.'); //|| window.location.host?.includes('kitchen-');

  const handleNewTaxRate = ({ new_taxrate }) => {
    if (new_taxrate != null) {
      cartDispatch({ type: 'SET_TAX_RATE', new_taxrate });
    }
  };

  useEffect(() => {
    socket.on('admin/sales_tax_rate', handleNewTaxRate);
    return () => {
      socket.off('admin/sales_tax_rate', handleNewTaxRate);
    };
  }, []);

  const handleUpdatedMenu = ({ menu, itemid, optionid }) => {
    menuDispatch({ type: 'SET_MENU', menu: menu });
    if (itemid != null) {
      menuDispatch({ type: 'ITEMS_UPDATING_ACTIVE_REMOVE_ID', itemid });
      //menuDispatch({ type: 'ITEMS_UPDATING_SOLDOUT_REMOVE_ID', itemid });
    }
    if (optionid != null) {
      menuDispatch({ type: 'OPTIONS_UPDATING_ACTIVE_REMOVE_ID', optionid });
      menuDispatch({ type: 'OPTIONS_UPDATING_SOLDOUT_REMOVE_ID', optionid });
    }
  };

  useEffect(() => {
    socket.on('menu/updated_menu_data', handleUpdatedMenu);
    return () => {
      socket.off('menu/updated_menu_data', handleUpdatedMenu);
    };
  }, []);

  async function attemptAutoAuth() {
    authDispatch({ type: 'AUTH_REQUESTED' });
    try {
      const authResponse = await checkAuth();
      authDispatch({ type: 'AUTH_COMPLETED', user: authResponse.data });
    } catch (error) {
      authDispatch({ type: 'AUTH_FAILED', errorcode: 'UNKNOWN', wasAutoAuthAttempt: true });
    }
  }

  useEffect(async () => {
    if (
      isKitchenSubdomain &&
      authState.attemptAutoAuthenticate &&
      !authState.isAuthenticated &&
      !authState.isAuthenticating
    ) {
      await attemptAutoAuth();
    }
  }, []);

  if (isKitchenSubdomain && !authState.isAuthenticated) {
    if (authState.isAuthenticating) {
      return null;
    } else {
      return <KitchenLoginPage />;
    }
  }

  return authState.isAuthenticated ? <PrivateLayout /> : <PublicLayout />;
}
